// Source https://codesandbox.io/s/z64on3ypm?file=/src/use-event-listener.js

import { useEffect } from "react";

export function useEventListener(eventName:string, handler: any, element: any){
      
    useEffect(
      () => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;
        
        // Add event listener
        element.addEventListener(eventName, handler);
        
        // Remove event listener on cleanup
        return () => {
          element.removeEventListener(eventName, handler);
        };
      }, [eventName, element, handler]);
  };