import { Action } from "redux";
import { DeficitType } from "../../components/ResultsMoneyDeficitModal";
import { ResultsContentType } from "../../models";

export const SPHERE_OBJECTS_MODAL_SHOW = 'SPHERE_OBJECTS_MODAL_SHOW';
export const OBJECT_MODAL_SHOW = 'OBJECT_MODAL_SHOW';
export const OBJECT_DONE_MODAL_SHOW = 'OBJECT_DONE_MODAL_SHOW';
export const OBJECT_DONE_UPGRADE_MODAL_SHOW = 'OBJECT_DONE_UPGRADE_MODAL_SHOW';
export const OBJECT_DONE_UPGRADE_LEVELS_MODAL_SHOW = 'OBJECT_DONE_UPGRADE_LEVELS_MODAL_SHOW';
export const MODAL_HIDE = 'MODAL_HIDE';
export const MODAL2_HIDE = 'MODAL2_HIDE';
export const NOTIFICATION_MODAL_SHOW = 'NOTIFICATION_MODAL_SHOW';
export const SHOWPLACES_MODAL_SHOW = 'SHOWPLACES_MODAL_SHOW';
export const RESULT_MODAL_SHOW = 'RESULT_MODAL_SHOW';
export const OBJECT_DECONSTRUCT_MODAL_SHOW = 'OBJECT_DECONSTRUCT_MODAL_SHOW';
export const GAMEOVER_MODAL_SHOW = 'GAMEOVER_MODAL_SHOW';
export const GAMEOVER_GOV_SCALE_MODAL_SHOW = 'GAMEOVER_GOV_SCALE_MODAL_SHOW';
export const MONEY_DEFICIT_MODAL_SHOW = 'MONEY_DEFICIT_MODAL_SHOW';
export const GRANT_FOR_BUSINESS_MODAL_SHOW = 'GRANT_FOR_BUSINESS_MODAL_SHOW'
export const ON_DECONSTRUCT_MODAL_SHOW = 'ON_DECONSTRUCT_MODAL_SHOW';
export const ON_CONSTRUCT_MODAL_SHOW = 'ON_CONSTRUCT_MODAL_SHOW';
export const ON_UPGRADE_MODAL_SHOW = 'ON_UPGRADE_MODAL_SHOW';
export const KVARTAL_MODAL_SHOW = 'KVARTAL_MODAL_SHOW';
export const INTERVIEW_MODAL_SHOW = 'INTERVIEW_MODAL_SHOW';

export interface IShowplaceModalShowAction extends Action {
    id: number;
}
export interface ISphereObjectsModalShowAction extends Action {
    sphereId: number;
    contextElementId: string;
    placeId: number;
}

export interface IKvartalIncomeModalShowAction extends Action {
    kvartalIncome: number;
}

export interface IObjectModalShowAction extends Action {
    objectId: string;
    placeId: number;
    contextMenuElementId: string;
    sphereId: number;
}

export interface IObjectDoneModalShowAction extends Action {
    objectId: string;
    sphereId: number;
    placeId: number;
}

export interface IObjectDeconstructModalShowAction extends Action {
    objectId: string;
    sphereId: number;
    placeId: number;
}

export interface IObjectDoneUpgradeModalShowAction extends IObjectDoneModalShowAction {
    level: number;
}

export interface INotificationModalShowAction extends Action {
    notificationId: number;
    eventResult?: any
}

export interface IResultnModalShowAction extends Action {
    resultType: ResultsContentType;
    objectId: string;
    sphereId: number;
}

export interface IMoneyDeficitModalShowAction extends Action {
    deficitType: DeficitType;
}

export interface IHideModalAction extends Action {
    id?: number;
}

export const showplacesModalShow = (id: number) => ({ type: SHOWPLACES_MODAL_SHOW, id } as IShowplaceModalShowAction);

export function notificationModalShow(notificationId: number, eventResult?: any) {
    return { type: NOTIFICATION_MODAL_SHOW, notificationId, eventResult } as INotificationModalShowAction;
}

export function sphereObjectsModalShow(sphereId: number, contextElementId: string, placeId: number) {
    return { type: SPHERE_OBJECTS_MODAL_SHOW, sphereId, contextElementId, placeId } as ISphereObjectsModalShowAction;
}

export function objectModalShow(objectId: string, placeId: number, contextMenuElementId: string, sphereId: number): IObjectModalShowAction {
    return { type: OBJECT_MODAL_SHOW, objectId, placeId, contextMenuElementId, sphereId };
}

export function objectDoneModalShow(objectId: string, placeId: number | null, sphereId: number) {
    return { type: OBJECT_DONE_MODAL_SHOW, objectId, placeId, sphereId } as IObjectDoneModalShowAction;
}

export function objectDoneUpgradeLevelsModalShow(objectId: string, placeId: number | null, sphereId: number) {
    return { type: OBJECT_DONE_UPGRADE_LEVELS_MODAL_SHOW, objectId, placeId, sphereId } as IObjectDoneModalShowAction;
}

export function objectDeconstrunctModalShow(objectId: string, placeId: number | null, sphereId: number) {
    return { type: OBJECT_DECONSTRUCT_MODAL_SHOW, objectId, placeId, sphereId } as IObjectDeconstructModalShowAction;
}

export function objectDoneUpgradeModalShow(objectId: string, placeId: number, sphereId: number, level: number) {
    return { type: OBJECT_DONE_UPGRADE_MODAL_SHOW, objectId, placeId, sphereId, level } as IObjectDoneUpgradeModalShowAction;
}

export function resultModalShow(resultType: ResultsContentType) {
    return { type: RESULT_MODAL_SHOW, resultType } as IResultnModalShowAction;
}

export function onConstructModalShow(objectId?: string, sphereId?: number) {
    return { type: ON_CONSTRUCT_MODAL_SHOW, objectId, sphereId } as IResultnModalShowAction;
}
export function onDeconstructModalShow(objectId: string, sphereId: number) {
    return { type: ON_DECONSTRUCT_MODAL_SHOW, objectId, sphereId } as IResultnModalShowAction;
}
export function onUpgradeModalShow(objectId: string, sphereId: number) {
    return { type: ON_UPGRADE_MODAL_SHOW, objectId, sphereId } as IResultnModalShowAction;
}

export function gameOverModalShow() {
    return { type: GAMEOVER_MODAL_SHOW };
}

export function kvartalModalShow(kvartalIncome: number) {
    return { type: KVARTAL_MODAL_SHOW, kvartalIncome } as IKvartalIncomeModalShowAction;
}

export function gameOverGovScaleModalShow() {
    return { type: GAMEOVER_GOV_SCALE_MODAL_SHOW };
}

export function grantForBusinessModalShow() {
    return { type: GRANT_FOR_BUSINESS_MODAL_SHOW };
}

export function InverviewModalShow() {
    return { type: INTERVIEW_MODAL_SHOW };
}

export function moneyDeficitModalShow(moneyDeficit: DeficitType) {
    return { type: MONEY_DEFICIT_MODAL_SHOW, moneyDeficit };
}

export function modalHide(id?: number): IHideModalAction {
    return { type: MODAL_HIDE, id };
}

export function modal2Hide() {
    return { type: MODAL2_HIDE };
}