import { Action } from 'redux';
import { IRandomEventAction } from '../../../models';

export const GAME_SET_RANDOM_EVENT_RESULT = 'GAME_SET_RANDOM_EVENT_RESULT';

export interface ISetRandomEventResultAction extends Action {
    randomEventResult: IRandomEventAction;
}

export function setRandomEventResult(randomEventResult: IRandomEventAction) {
    return { type: GAME_SET_RANDOM_EVENT_RESULT, randomEventResult } as ISetRandomEventResultAction;
}
