import { useDispatch, useSelector } from 'react-redux';
import { randomGen7_13, termDaysFormat } from '../../helpers';
import { formatPrice } from '../../helpers/formatPrice';
import { useLevel } from '../../hooks/useLevel';
import { calcBuildingInfluence } from '../../hooks/useMainGameLoop';
import { getPhotoObject } from '../../images/buildingObjects';
import initialData from '../../initialData';
import { ICityObjectProgress } from '../../models/game';
import { gameBuildObject, modalHide, sphereObjectsModalShow } from '../../redux/actions';
import { getTimerCurDate } from '../../redux/selectors';
import BuildingPhoto from '../BuildingPhoto';
import ModalComponent from '../ModalComponent';
import { Button } from '../UiKit';
import RowNameValue from './RowNameValue';
import style from './style.module.scss';

interface IBuildingDescriptionModalProps {
    objectId: string;
    contextMenuElementId: string;
    sphereId: number;
    placeId: number;
    previousModalId: number;
}

const BuildingDescriptionModal: React.FunctionComponent<IBuildingDescriptionModalProps> = (props) => {
    const { objectId, sphereId, contextMenuElementId, placeId, previousModalId } = props;

    const dispatch = useDispatch();

    const sphere = initialData.citySpheresInfo.find(_ => _.sphereId === sphereId);
    const cityObj = sphere?.objects.find(_ => _.id === objectId);
    const curDate = useSelector(getTimerCurDate);

    const handleOnBuild = (e: any) => {
        if (!!cityObj) {
            const rndCoef = [randomGen7_13(), randomGen7_13(), randomGen7_13(), randomGen7_13(), randomGen7_13(), randomGen7_13()];
            dispatch(gameBuildObject(objectId, curDate, placeId, sphereId, cityObj.params.costXnYn, rndCoef));
            dispatch(modalHide(previousModalId));
        }
        e.preventDefault();
        e.stopPropagation();
    }
    const curLevelBusAndGov = useLevel();

    const isCurrentGovAndBusinessLevelOkForBuild = curLevelBusAndGov > 2;

    const isObjectOfThirdSphere = cityObj?.id!.endsWith("3");
    if (isObjectOfThirdSphere && !isCurrentGovAndBusinessLevelOkForBuild) {
        return LevelNotReachForBuildingModal(props);
    }

    if (!!cityObj) {
        const { name, params: { safetyXnYn, comfortXnYn, environmentXnYn, identityXnYn, modernityXnYn, controlXnYn, costXnYn, timecostXnYn } } = cityObj;
        const so: ICityObjectProgress = {
            sphereId,
            placeId,
            objectId,
            startBuildTime: 0,
            upgrades: [],
        };
        const isUseLocationScenario = calcBuildingInfluence(so, curDate, true);
        const { img } = getPhotoObject(objectId);
        const closeAction = () => dispatch(modalHide(previousModalId));
        return <ModalComponent title={name} closeAction={closeAction} backAction={() => {
            dispatch(sphereObjectsModalShow(sphereId, contextMenuElementId, placeId));
            closeAction && closeAction();
        }} height='40rem' >

            <div className={style.flex}>
                <div className={style.left}>
                    <div className={style.title}>Влияние на:</div>
                    <div className={style.info}>
                        <RowNameValue name={"Безопасность"} value={safetyXnYn > 0 && isUseLocationScenario ? 0 : safetyXnYn} />
                        <RowNameValue name={"Комфортность"} value={comfortXnYn > 0 && isUseLocationScenario ? 0 : comfortXnYn} />
                        <RowNameValue name={"Экологичность и здоровье"} value={environmentXnYn > 0 && isUseLocationScenario ? 0 : environmentXnYn} />
                        <RowNameValue name={"Идентичность и разнообразие"} value={identityXnYn > 0 && isUseLocationScenario ? 0 : identityXnYn} />
                        <RowNameValue name={"Современность и актуальность"} value={modernityXnYn > 0 && isUseLocationScenario ? 0 : modernityXnYn} />
                        <RowNameValue name={"Эффективность управления"} value={controlXnYn > 0 && isUseLocationScenario ? 0 : controlXnYn} />
                    </div>
                </div>
                <div className={style.photo}>
                    <BuildingPhoto photo={img} />
                </div>

                <div className={style.left}>
                    <div className={style.row}>
                        <div className={style.column_name}>
                            <div>Стоимость:</div>
                            <div>Срок строительства:</div>
                        </div>

                        <div className={style.column_value}>
                            <div>{formatPrice(costXnYn)} тыс &#8381;</div>
                            <div>{termDaysFormat(timecostXnYn)}</div>
                        </div>
                    </div>
                    <div className={style.buttons}>
                        <Button isBlue={true} onClick={handleOnBuild}>Начать строительство</Button>
                        <Button isBlue={false} onClick={closeAction}>Закрыть</Button>
                    </div>
                </div>
            </div>

        </ModalComponent >;
    }
    return null;
}

export default BuildingDescriptionModal;

const LevelNotReachForBuildingModal: React.FunctionComponent<IBuildingDescriptionModalProps> = ({ previousModalId, sphereId, contextMenuElementId, placeId }) => {
    const dispatch = useDispatch();

    return (<ModalComponent
        closeAction={() => dispatch(modalHide(previousModalId))}
        backAction={() => { dispatch(sphereObjectsModalShow(sphereId, contextMenuElementId, placeId)); dispatch(modalHide(previousModalId)); }}
        height='10rem'>
        <div className={style.flex}>
            <div className={style.title}>
                К сожалению, сейчас вы не можете построить данное здание,
                так как не достигли среднего значения в 50 пунктов по шкалам "Индекс инвестиционной привлекательности" и "Одобрение государством".
            </div>
        </div>
    </ModalComponent>);
}
