import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';

interface IButtonProps {
    onClick?: (event: any) => void;
    isBlue?: boolean;
    isGrey?: boolean;
    isStretched?: boolean;
    widthPercents?: number;
    disabled?: boolean;
}

const Button: React.FunctionComponent<IButtonProps> = (props) => {

    const { onClick, children, isBlue = true, isGrey = false, isStretched = false, widthPercents, disabled = false } = props
    const stretchedStyle = {
        width: `${widthPercents ?? 95}%`,
        display: 'flex',
        justifyContent: 'center',
    }
    return <button style={isStretched ? stretchedStyle : {}} className={cn(isBlue ? style.blue : isGrey ? style.grey : style.white, disabled ? style.disabled : '' )}
        onMouseDown={disabled ? () => {} : onClick}>
        {children}
    </button>
}

export default Button;