import { Action } from 'redux';
import { ISphereManager } from '../../../models';


export const HIRE_MANAGER = 'HIRE_MANAGER';
export const FIRE_MANAGER = 'FIRE_MANAGER';

export interface IHireManagerAction extends Action {
    objectInfo: IObjectKeyInfo;
    manager: ISphereManager;
    hireRndCoef: number[];
}

export interface IFireManagerAction extends Action {
    objectInfo: IObjectKeyInfo;
    managerId: number;
}
export interface IObjectKeyInfo {
    objectId: string;
    placeId: number;
}
export function hireManager(objectInfo: IObjectKeyInfo, manager: ISphereManager, hireRndCoef: number[]) {
    return { type: HIRE_MANAGER, objectInfo, manager, hireRndCoef };
}

export function fireManager(objectInfo: IObjectKeyInfo, managerId: number) {
    return { type: FIRE_MANAGER, objectInfo, managerId };
}