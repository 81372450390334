import React from "react";
import MainPage from "../UiKit/MainPage";
import StartPageContent from "./StartPageContent";
import { useBeginLtiAuth } from "../../hooks/useBeginLtiAuth";

interface IStartPageProps {}

const StartPage: React.FunctionComponent<IStartPageProps> = (props) => {
  useBeginLtiAuth();

  return (
    <MainPage isContentCentered={true}>
      <StartPageContent />
    </MainPage>
  );
};

export default StartPage;
