import { Action } from "redux";
import { IFailAction } from "../../../models/common";
import { IErrorResponse } from "../../../api/common";

export const ADD_GAME_STATS_INIT = 'ADD_GAME_STATS_INIT';
export const ADD_GAME_STATS_DONE = 'ADD_GAME_STATS_DONE';
export const ADD_GAME_STATS_FAIL = 'ADD_GAME_STATS_FAIL';

export interface IAddGameStatsInitAction extends Action {
    gameId: any;
    curGameStats: any;
}

export interface IAddGameStatsDoneAction extends Action { }

export function addGameStatsInit(gameId: any, curGameStats: any) {
    return { type: ADD_GAME_STATS_INIT, gameId, curGameStats } as IAddGameStatsInitAction;
}

export function addGameStatsDone() {
    return { type: ADD_GAME_STATS_DONE } as IAddGameStatsDoneAction;
}

export function addGameStatsFail(error: IErrorResponse) {
    return { type: ADD_GAME_STATS_FAIL, error } as IFailAction;
}