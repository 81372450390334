import { Action } from 'redux';
import { IEstimatedIndicators } from '../../../models';

export const GAME_SET_ESTIMATED_INDICATORS = 'GAME_SET_ESTIMATED_INDICATORS';
export const SET_MINUS_MONEY_AMOUNT = 'SET_MINUS_MONEY_AMOUNT';

export interface ISetEstimatedIndicatorsAction extends Action {
    estimatedIndicators: IEstimatedIndicators;
}
export interface ISetMinusMoneyAmount extends Action {
    amount: number;
}

export function setEstimatedIndicators(estimatedIndicators: IEstimatedIndicators) {
    return { type: GAME_SET_ESTIMATED_INDICATORS, estimatedIndicators } as ISetEstimatedIndicatorsAction;
}

export function setMinusMoneyAmount(amount: number) {
    return { type: SET_MINUS_MONEY_AMOUNT, amount } as ISetMinusMoneyAmount;
}