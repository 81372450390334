import { formatPrice } from "./formatPrice";

export const paintDigits = (value: number) => {
    let color = "";
    let valueSignedStr = ""
    if (value > 0) {
        color = " #0095FF";/* Blue.Small */
        valueSignedStr = `+${formatPrice(value)}`;
    }
    else if (value < 0) {
        color = "#FF3300";//Red Text
        valueSignedStr = `${formatPrice(value)}`;
    }
    else if (value === 0) {
        valueSignedStr = `${formatPrice(value)}`;

        color = "black";
    }
    return <span style={{ color: color }}>{valueSignedStr}</span>;
}