import { useDispatch, useSelector } from 'react-redux';
import { randomGen7_13 } from '../../../helpers';
import { formatPrice } from '../../../helpers/formatPrice';
import { useCalcObjectParams } from '../../../hooks';
import { ICityObject } from '../../../models';
import { gameDestroyObject } from '../../../redux/actions';
import { getTimerCurDate } from '../../../redux/selectors';
import NameValueComponent from '../../BuildingInfo/NameValueComponent';
import { Button } from '../../UiKit';
import style from './style.module.scss';

interface IDemolitionContentProps {
    cityObject: ICityObject;
    closeModal?: () => void;
    placeId: number;
}

const DemolitionContent: React.FunctionComponent<IDemolitionContentProps> =
    ({
        cityObject: { params, id: objectId, name },
        closeModal,
        placeId
    }) => {

        const { safetyXnYn, comfortXnYn, environmentXnYn, modernityXnYn, identityXnYn, controlXnYn } = useCalcObjectParams(placeId, params);
        const { undocostXnYn, undotimecostXnYn, dailyrevenueXnYnbase, dailycostXnYnbase } = params;

        const dispatch = useDispatch();
        const curDate = useSelector(getTimerCurDate)
        return <div className={style.demolitionContent}  >
            <div className={style.areYouSure}>{`Вы уверены, что хотите снести ${name}? Снос объекта повлияет на следующие показатели:`}</div>
            <div className={style.buildingInfluenceLabel}>Влияние на качество городской среды:</div>
            <div className={style.details}>
                <div className={style.row}>
                    <div className={style.incomeInfo}>
                        <NameValueComponent name={"Безопасность"} value={safetyXnYn} />
                        <NameValueComponent name={"Экологичность и здоровье"} value={environmentXnYn} />
                        <NameValueComponent name={"Современность и актуальность"} value={modernityXnYn} />
                        <NameValueComponent name={"Выручка в месяц"} value={dailyrevenueXnYnbase * 30} isValCapitalized={true} />
                    </div>
                    <div className={style.outcomeInfo}>
                        <NameValueComponent name={"Комфортность"} value={comfortXnYn} />
                        <NameValueComponent name={"Идентичность и разнообразие"} value={identityXnYn} />
                        <NameValueComponent name={"Эффективность управления"} value={controlXnYn} />
                        <NameValueComponent name={"Издержки в месяц:"} value={dailycostXnYnbase * 30} isValCapitalized={true} />
                    </div>
                </div>
            </div>
            <div className={style.info}>
                <div className={style.undoCost}>
                    <div className={style.name}>Стоимость сноса:</div>
                    <div className={style.value}>{formatPrice(undocostXnYn)} тыс ₽ </div>
                </div>
                <div className={style.undoTime}>
                    <div className={style.name}>Срок сноса:</div>
                    <div className={style.value}>{undotimecostXnYn}  дней </div>
                </div>
            </div>
            <div className={style.btns}>
                <Button isStretched={true}
                    onClick={() => {
                        const arr = [randomGen7_13(), randomGen7_13(), randomGen7_13(), randomGen7_13(), randomGen7_13(), randomGen7_13()];
                        dispatch(gameDestroyObject(objectId, placeId, curDate, undocostXnYn, arr));
                        closeModal && closeModal();
                    }}
                >Снести</Button>
                <Button isStretched={true} isBlue={false} onClick={closeModal}> Отменить</Button>
            </div>
        </div >
    }

export default DemolitionContent;