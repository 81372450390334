import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FireIcon, NavArrowRightBlueIcon } from '../../icons';
import { notificaionMock } from '../../mocks';
import { readNotification, setRandomEventResult, timerSpeed } from '../../redux/actions';
import { getLearningCompleted, getNotifications } from '../../redux/selectors';
import RandomEventResultContent from '../RandomEventResultContent';
import style from './style.module.scss';

interface IRandomEvenContentProps {
    eventId: number;
    isShowResult?: boolean;
    result?: any;
}

const RandomEventContent: React.FunctionComponent<IRandomEvenContentProps> = (props) => {
    const { eventId, isShowResult, result = -1 } = props;
    const dispatch = useDispatch();

    const notifications = useSelector(getNotifications);

    let event = notifications.filter(_ => _.id === eventId)[0];
    const isLearningCompleted = useSelector(getLearningCompleted);

    if (!isLearningCompleted) {
        event = notificaionMock;
    }

    useEffect(() => { dispatch(timerSpeed(0)) }, [dispatch]);

    const randomEventDiv = <div className={style.randomEvenContent} data-tut="Notifications" >
        <div className={style.notificationCardNews}>
            <FireIcon />
            <div className={style.news}>
                <div className={style.source}>
                    <div className={style.sourceName}>{"Город.ОК"}</div>
                    <div className={style.sourceNameTlg}>{'@GorodOK.News'}</div>
                </div>
                <div className={style.content}>{event?.content.event}</div>
            </div>
        </div>
        <h1 className={style.todoHeading}>Что предпринять?</h1>
        <div className={style.actionsContainer}>
            {event?.content.actions.map((a, i) =>
                <div key={i} className={style.action} onClick={() => goToAction(i)}>
                    <div className={style.name}>{a.eventAction}</div>
                    <NavArrowRightBlueIcon />
                </div>
            )}
        </div>
    </div>;

    const resultDiv = (i: number) => <RandomEventResultContent eventName={event.content.event}
        action={event.content.actions[i]} />;

    const resultEvent = event?.content.actions.filter(e => e.eventResult === result)[0];
    const resultIndex = event?.content.actions.indexOf(resultEvent);
    let [content, setContent] = useState(isShowResult ? resultDiv(resultIndex) : randomEventDiv);

    const goToAction = (i: number) => {
        dispatch(setRandomEventResult(event?.content.actions[i]));
        dispatch(readNotification(eventId));
        setContent(resultDiv(i));
    }
    return <>{content ?? null}</>
}
export default RandomEventContent;