import initialData from "../../initialData";
import { DialogType, ICityObject } from "../../models";
import ModalComponent from "../ModalComponent";
import { Button } from "../UiKit";
import style from './style.module.scss';

interface IEventResultModalProps {
    modalType: DialogType;
    objectId: string;
    sphereId: number;
    onCloseHandle(): void;
}

const formatName = (object?: ICityObject) => {
    if (!object) {
        return '';
    }
    if (object.name.endsWith('"')) {
        return '"' + object.name
    }
    return '"' + object.name + '"';
}

const EventResultModal: React.FunctionComponent<IEventResultModalProps> = (props) => {
    const { modalType, objectId, sphereId, onCloseHandle } = props;

    const sphere = initialData.citySpheresInfo.find(_ => _.sphereId === sphereId);
    const object = sphere?.objects.find(_ => _.id === objectId);

    let title = `Объект ${formatName(object)} улучшен`;
    let text = `Объект ${formatName(object)} успешно улучшен. Теперь вы можете оценить эффект от выбранного улучшения.`;
    if (modalType === 'OnUpgradeDoneModal') {
    } else if (modalType === 'OnDeconstructModal') {
        title = `Объект ${formatName(object)} снесен`;
        text = `Объект ${formatName(object)} успешно снесен. Теперь вы можете построить здесь что-то новое.`;
    } else if (modalType === 'OnConstructModal') {
        title = `Объект ${formatName(object)} построен`;
        text = `Объект ${formatName(object)} успешно построен. Теперь вы можете посмотреть его влияние на все показатели.`;
    }

    return <ModalComponent showBackIcon={false} title={title} closeAction={onCloseHandle} height={'10rem'}>
        <div className={style.upgradeModal}>{text}</div>
        <div className={style.btn}>
            <Button isStretched={true} isBlue={true} onClick={onCloseHandle}>ок</Button>
        </div>
    </ModalComponent>;
}

export default EventResultModal;