import { useSelector } from "react-redux";
import { IMenuLevelProps, MenuLevelEmum } from "..";
import initialData, { getPlaceById, getSphereById } from '../../../initialData';
import CustomScrollbar from "../../UiKit/Scrollbar";
import BackMenuHeader from "../MenuHeader/backHeader";
import style from '../style.module.scss';
import BuildingPlace from "./BuildingPlace";
import { getSelectedObjects, getTimer } from '../../../redux/selectors';
import { getPhotoObject } from "../../../images/buildingObjects";
import { ICityObjectProgress } from "../../../models";
import dayjs from "dayjs";

const places = initialData.cityBuildingPointsInfo;

export default function MenuLevel1Buildings(props: IMenuLevelProps) {

    const { menuLevelSet } = props;

    const selectedObjects = useSelector(getSelectedObjects);
    const timer = useSelector(getTimer);

    const renderObject = (o: ICityObjectProgress, key: number) => {
        const sphere = o.sphereId ? getSphereById(o.sphereId) : null;
        const object = sphere ? sphere.objects.filter(_ => _.id === o.objectId)[0] : null;
        const place = o.placeId ? getPlaceById(o.placeId) : null;
        const photoObject = getPhotoObject(o.objectId);

        let buildingProgress = 0;
        if (object) {
            object.img = photoObject.img;
            object.imgHover = photoObject.imgHover;

            buildingProgress = dayjs(timer.curDate).diff(o.startBuildTime, 'days') / object.params.timecostXnYn;
        }

        return <BuildingPlace key={key} building={object} sphereName={sphere ? sphere.sphereName : ''} place={place} buildingProgress={buildingProgress} sphereId={o.sphereId} />
    }

    const getObjects = () => {
        let key = -1;


        let objects1 = [
            renderObject(selectedObjects[0], ++key),
            renderObject(selectedObjects[1], ++key)
        ];

        const objects2 = places.map(p => {

            const obj = selectedObjects.find(_ => _.placeId === p.id);

            if (obj) {
                return renderObject(obj, ++key);
            }

            return <BuildingPlace key={++key} building={null} sphereName={''} place={p} buildingProgress={0} sphereId={-1} />;

        });

        return [...objects1, ...objects2];
    }

    return <>
        <span data-tut="Buildings"  >
            <BackMenuHeader title={'Инфраструктура'} onClick={(e) => {menuLevelSet(MenuLevelEmum.Level0); e.stopPropagation();}} />


            <CustomScrollbar styleCustom={{ height: '95%', width: 460 }}>
                <div className={style.menuItemsBuildings}>
                    {getObjects()}
                </div>
            </CustomScrollbar>
        </span>
    </>;
}