import style from './style.module.scss';

interface IMenuHeaderProps {

}


const MenuHeader: React.FunctionComponent<IMenuHeaderProps> = (props) => {

    return <div className={style.headerContainer}>
        {props.children}
    </div>
}

export default MenuHeader;