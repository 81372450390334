import { combineReducers } from 'redux';
import { IGame, IModalDialog } from '../../models';
import * as auth from './auth';
import * as game from './game';
import * as modalDialog from './modalDialog';
import * as modalDialog2 from './modalDialog2';
import * as gameAPI from './gameApi/getGames';
import * as addGameStats from './gameApi/addGameStats';

import * as saveGame from './gameApi/saveGame';
import * as menu from './menu';

export interface IState {
    auth: auth.IState;
    game: IGame;
    modalDialog?: IModalDialog[];
    modalDialog2?: IModalDialog;
    games: gameAPI.IState;
    saveGame: saveGame.IState,
    menu: menu.IMenuState;
    addGameStats: addGameStats.IState,
}

export const initial: IState = {
    game: game.initial,
    auth: auth.initial,
    games: gameAPI.initial,
    saveGame: saveGame.initial,
    menu: menu.initial,
    addGameStats: addGameStats.initial,
}

export const appReducer = combineReducers({
    auth: auth.reducer,
    game: game.reducer,
    modalDialog: modalDialog.reducer,
    modalDialog2: modalDialog2.reducer,
    games: gameAPI.reducer,
    saveGame: saveGame.reducer,
    menu: menu.reducer,
    addGameStats: addGameStats.reducer,
});