import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationModalShow } from '../../redux/actions';
import { getNotifications } from '../../redux/selectors';

const PushNotificationsComponent: React.FunctionComponent = () => {
    const notifications = useSelector(getNotifications);
    const dispatch = useDispatch();

    useEffect(() => {
        if (notifications && notifications.length > 0) {// setNotifState(notifications.filter(_ => !_.isPushClose).slice(0,3));
            const news = notifications[0];
            if (!news.isRead)
                news?.id && dispatch(notificationModalShow(news.id));
        }

    }, [dispatch, notifications]);

    return null;
}

export default PushNotificationsComponent;