import { ICreateGame, IGameStatistic } from '../api/games';
import { ICityObjectProgress } from '../models';
import { CITY_INFO_SET, FIRE_MANAGER, GAME_BUILD_OBJECT, GAME_CLOSE_PUSH_NOTIFICATION, GAME_DESTROY_OBJECT, GAME_FINISHED, GAME_INIT, GAME_NEW_NOTIFICATION, GAME_READ_NOTIFICATION, GAME_SET_ESTIMATED_INDICATORS, GAME_SET_RANDOM_EVENT_RESULT, GAME_STAGE, GAME_UPGRADE_OBJECT, GO_TO_PLACE_DONE, GO_TO_PLACE_INIT, HIDE_CONTEXT_MENU, HIRE_MANAGER, LEARNING_COMPLEATED, LEARNING_PROGRESS_INIT, LEARNING_PROGRESS_NEXT, LEARNING_PROGRESS_PAUSE, LEARNING_PROGRESS_START, MAYOR_TERM_CHANGE, REMOVE_BUILDING_FROM_MAP, SHOW_CONTEXT_MENU, TIMER_RETURN_PREVIOUS_SPEED, TIMER_SPEED, TIMER_START, TIMER_STOP, TIMER_UPDATE, YEAR_RESULT_SET } from '../redux/actions';
import { addGameStatsInit, ADD_GAME_STATS_DONE, ADD_GAME_STATS_FAIL, ADD_GAME_STATS_INIT, saveGameInit, SAVE_GAME_DONE, SAVE_GAME_FAIL, SAVE_GAME_INIT } from '../redux/actions/gameApi';
import { getSaveStatsProgress } from '../redux/selectors';
import { store } from '../store/configureStore';

export * from './moneyToString';
export * from './paintDigits';
export * from './randomGen';
export * from './termDaysFormat';
export * from './shuffle';

const gameActions: any[] = [CITY_INFO_SET, MAYOR_TERM_CHANGE, GAME_SET_ESTIMATED_INDICATORS,
    GAME_INIT, GAME_FINISHED, GAME_STAGE,
    GO_TO_PLACE_INIT, GO_TO_PLACE_DONE,
    LEARNING_COMPLEATED,
    LEARNING_PROGRESS_INIT, LEARNING_PROGRESS_START, LEARNING_PROGRESS_PAUSE,
    LEARNING_PROGRESS_NEXT, SHOW_CONTEXT_MENU, HIDE_CONTEXT_MENU,

    GAME_NEW_NOTIFICATION, GAME_READ_NOTIFICATION, GAME_CLOSE_PUSH_NOTIFICATION,
    GAME_SET_RANDOM_EVENT_RESULT,
    GAME_BUILD_OBJECT, GAME_UPGRADE_OBJECT, GAME_DESTROY_OBJECT, REMOVE_BUILDING_FROM_MAP,
    HIRE_MANAGER, FIRE_MANAGER,
    YEAR_RESULT_SET,

    TIMER_START, TIMER_STOP, TIMER_UPDATE, TIMER_SPEED, TIMER_RETURN_PREVIOUS_SPEED
]

const DROP_TICKS = 5;
let i = 0;

export function saveStats() {
    return (next: any) => (action: any) => {
        const game = store.getState().game
        const inProgress = getSaveStatsProgress(store.getState());
        if (!inProgress && game.id > 0
            && action.type !== SAVE_GAME_DONE && action.type !== SAVE_GAME_INIT && action.type !== SAVE_GAME_FAIL
            && action.type !== ADD_GAME_STATS_DONE && action.type !== ADD_GAME_STATS_INIT && action.type !== ADD_GAME_STATS_FAIL
            && gameActions.some(e => e === action.type)
        ) {

            // Call the next dispatch method in the middleware chain.
            const returnValue = next(action)
            if (action.type === TIMER_UPDATE) {
                if (i < DROP_TICKS) {
                    i++;
                    return returnValue;
                }
                else {
                    i = 0;
                }
            }

            const curGameStats: IGameStatistic = {
                action: action.type,
                state_before: '',
                state_after: JSON.stringify(game),
            }

            store.dispatch(addGameStatsInit(game.id, curGameStats));

            //SAVE
            const saveGame = store.getState().saveGame;
            const timer = store.getState().game.timer;

            if (!saveGame.inProgress && game && game.id > 0 && timer.started) {
                const updatedGame: ICreateGame = { state: JSON.stringify(game) }
                store.dispatch(saveGameInit(game.id, updatedGame))
            }

            // This will likely be the action itself, unless
            // a middleware further in chain changed it.
            return returnValue;
        } else
            return next(action);
    }
}

export const getUpgradeLevel = (objectSelected: ICityObjectProgress | undefined) => {
    let buildinUpgradeLevel = 0;
    if (objectSelected && objectSelected.upgrades.length / 3 >= 1) {
        buildinUpgradeLevel = 1;
    }
    if (objectSelected && objectSelected.upgrades.length / 6 >= 1) {
        buildinUpgradeLevel = 2;
    }

    if (objectSelected && objectSelected.upgrades.length / 9 >= 1) {
        buildinUpgradeLevel = 3;
    }
    if (objectSelected && objectSelected.upgrades.length / 12 >= 1) {
        buildinUpgradeLevel = 4;
    }
    return buildinUpgradeLevel
}