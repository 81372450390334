import React from 'react';
import { EmptyStarIcon, RatingStarIcon } from '../../../icons';
import style from './style.module.scss';

interface IRatingProps {
    value: number;
}

const Rating: React.FunctionComponent<IRatingProps> = ({ value }) => {
    const starArr = Array.from({ length: value > 0 ? value : 0 }, (_) => 0);
    const empStarArr = Array.from({ length: value > 3 ? 0 : 4 - value }, (_) => 0);

    return <div className={style.container}>
        {starArr.map((_, i) => <RatingStarIcon key={i} />)}
        {empStarArr.map((_, i) => <EmptyStarIcon key={i} />)}
    </div>
}

export default Rating;