import {  call, put, takeLatest } from 'redux-saga/effects'
import { Action } from "redux";
import { IErrorResponse } from '../../../api/common';
import { score } from '../../../api/games';
import { IScoreGameInitAction, scoreGameDone, scoreGameFail, SCORE_GAME_INIT } from '../../actions/gameApi/scoreGame';

export function* scoreGameHandler(action: Action) {
    try {
        const { id, value} = action as IScoreGameInitAction;
        yield call(score, id, value);
        yield put(scoreGameDone());
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(scoreGameFail(c))
    }
}

export function* scoreGameSaga() {
    yield takeLatest(SCORE_GAME_INIT, scoreGameHandler);
}
