import { useDispatch } from "react-redux";
import initialData from "../../initialData";
import { objectDoneModalShow } from "../../redux/actions";
import ModalComponent from "../ModalComponent";
import DemolitionContent from "./DemolitionContent";

interface IDemolitionModalProps {
    objectId: string;
    sphereId: number;
    placeId: number;
    closeModal(): void;
}
const DemolitionModal: React.FunctionComponent<IDemolitionModalProps> = (props) => {
    const { objectId, sphereId, placeId, closeModal } = props;

    const citySphere = initialData.citySpheresInfo.filter(e => e.sphereId === sphereId)[0];

    const objects = citySphere.objects;
    const cityObject = objects.filter(e => e.id === objectId)[0];
    const dispatch = useDispatch();

    return <ModalComponent title={`Снос ${cityObject.name}`} closeAction={closeModal} height='30rem'
        backAction={() => dispatch(objectDoneModalShow(objectId, placeId, sphereId))}
    >
        <DemolitionContent cityObject={cityObject} closeModal={closeModal} placeId={placeId} />
    </ModalComponent>;
}

export default DemolitionModal;