import React from 'react';
import Footer from './Footer';
import Header from './Header';
import style from './style.module.scss';

interface IStartPageProps { isContentCentered?: boolean; }

const MainPage: React.FunctionComponent<IStartPageProps> = (props) => {
    const { isContentCentered } = props;

    return <div className={style.mainPage}>
        <div className={style.rightMenu}>
            <Header />
            <div className={style.content} style={isContentCentered ? { display: 'flex' } : {}}>
                {props.children}
            </div>
            <Footer />
        </div>
    </div>
}

export default MainPage;