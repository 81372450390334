import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { IState } from "../../redux/reducers";
import { getTimer, getYearResult } from "../../redux/selectors";
import { ProgressBar, ProgressBarColor, ProgressBarSize, ProgressBarTitleStyle } from "../UiKit";
import style from './style.module.scss';

export default function SatisfactionResultsView() {

    const timer = useSelector(getTimer);
    const years = Math.floor(dayjs(timer.curDate).diff(timer.startDate, 'years'));

    const results = useSelector(s => getYearResult(s as IState, years));

    let resArr, topmostThreeSphereResults: any[] = [];
    if (results) {
        resArr = [{ value: results.satisfactionhousing, name: "satisfactionhousing" },
        { value: results.satisfactionparks, name: "satisfactionparks" },
        { value: results.satisfactionoffice, name: "satisfactionoffice" },
        { value: results.satisfactionsocial, name: "satisfactionsocial" },
        { value: results.satisfactioncity, name: "satisfactioncity" },
        { value: results.satisfactionroads, name: "satisfactionroads" }];

        topmostThreeSphereResults = [...resArr].map(e => { return e }).slice().sort(function (a, b) {
            return a.value - b.value;
        });

        //показываем только три отстающие сферы
        topmostThreeSphereResults.length = 3;
    }
    // console.log('Номер года', years, results)

    return !results || years < 1 ? null : <div>
        <div className={style.results}>
            {topmostThreeSphereResults.filter(e => e.name === 'satisfactionhousing')[0] && <ProgressBar title={'Жилье и прилегающее пространство'}
                completedValuePercent={Math.round(results.satisfactionhousing)} titleStyle={ProgressBarTitleStyle.secondary} size={ProgressBarSize.big} color={ProgressBarColor.red}
                completedValueFact={Math.round(results.satisfactionhousing)} />}
            <div className={style.space} />
            {topmostThreeSphereResults.filter(e => e.name === 'satisfactionparks')[0] && <ProgressBar title={'Озелененное пространство'}
                completedValueFact={Math.round(results.satisfactionparks)} completedValuePercent={Math.round(results.satisfactionparks)} titleStyle={ProgressBarTitleStyle.secondary}
                size={ProgressBarSize.big} color={ProgressBarColor.green} />}
            <div className={style.space} />
            {topmostThreeSphereResults.filter(e => e.name === 'satisfactionoffice')[0] && <ProgressBar title={'Общественно-деловая инфраструктура'}
                completedValuePercent={Math.round(results.satisfactionoffice)} completedValueFact={Math.round(results.satisfactionoffice)} titleStyle={ProgressBarTitleStyle.secondary} size={ProgressBarSize.big} color={ProgressBarColor.yellow} />}
            <div className={style.space} />
            {topmostThreeSphereResults.filter(e => e.name === 'satisfactionsocial')[0] && <ProgressBar title={'Социально-досуговая инфраструктура'}
                completedValuePercent={Math.round(results.satisfactionsocial)} completedValueFact={Math.round(results.satisfactionsocial)} titleStyle={ProgressBarTitleStyle.secondary} size={ProgressBarSize.big} color={ProgressBarColor.blue} />}
            <div className={style.space} />
            {topmostThreeSphereResults.filter(e => e.name === 'satisfactioncity')[0] && <ProgressBar title={'Общегородское пространство'}
                completedValuePercent={Math.round(results.satisfactioncity)} completedValueFact={Math.round(results.satisfactioncity)} titleStyle={ProgressBarTitleStyle.secondary} size={ProgressBarSize.big} color={ProgressBarColor.aquamarine} />}
            <div className={style.space} />
            {topmostThreeSphereResults.filter(e => e.name === 'satisfactionroads')[0] && <ProgressBar title={'Улично-дорожная сеть'}
                completedValuePercent={Math.round(results.satisfactionroads)} completedValueFact={Math.round(results.satisfactionroads)} titleStyle={ProgressBarTitleStyle.secondary} size={ProgressBarSize.big} color={ProgressBarColor.orange} />}
        </div>
        <div className={style.info}>На основе опроса жителей, эти три сферы являются самыми отстающими по итогам прошлого года. Возможно, в этом году стоит обратить на них внимание.</div>
    </div>
}