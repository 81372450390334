import { StatusPanelItem } from "./Common";
import MoneyItem from "./MoneyItem";
import SpeedItem from "./SpeedItem";
import TimeItem from "./TimeItem";
import style from './style.module.scss';

const GameStatusPanel = () => {

    return <div className={style.gameStatusPanel} data-tut="IndicatorTime">
        <StatusPanelItem>
            <MoneyItem />
        </StatusPanelItem>

        <StatusPanelItem>
            <TimeItem />
            <SpeedItem />
        </StatusPanelItem>
    </div>

}

export default GameStatusPanel;