import { MenuNavItemArrowRightIcon } from '../../../icons';
import MenuItem from '../MenuItem';
import style from './style.module.scss';

interface IMenuNavItemProps {
    icon: React.ReactNode;
    title: string;
    onClick?: () => void
}

const MenuNavItem: React.FunctionComponent<IMenuNavItemProps> = (props) => {

    const  { icon, title, onClick } = props;

    return <MenuItem onClick={onClick}>
        <div className={style.menuNavItemContainer}>
            <div className={style.title}>
                {icon}
                <div className={style.titleName}>{title}</div>
            </div>

            <MenuNavItemArrowRightIcon />
        </div>
    </MenuItem>;
}

export default MenuNavItem;