import church from "../images/showplace_church.png";
import statue from "../images/showplace_statue.png";
import steamer from "../images/showplace_steamer.png";
import { ICoord } from "../models";

export interface IShowPlace {
    id: number;
    coord: ICoord;
    title: string;
    desc: string;
    photoUrl: string;
}

const showplaces: IShowPlace[] = [
    {
        id: 1,
        coord: { x: 1224, y: 147 },
        title: "Церковь Николая Чудотворца",
        desc: "Церковь Николая Чудотворца была заложена 12 августа1912 года в день рождения цесаревича Алексия. В декабре 1913 года храм был освящен. За всю историю храма он был использован в качестве тюрьмы-пересылки в начале 30х годов, а также в качестве казармы во время войны. В 1947 году храм был возвращен верующим. После ремонта в 1948 году он стал действующим.",
        photoUrl: church
    },
    {
        id: 2,
        coord: { x: 7250, y: 592 },
        title: "Памятник Л. Гайдаю",
        desc: "Памятник Леониду Гайдаю в Свободном был открыт в 2006 году и долгое время являлся единственным памятником великому режиссёру в нашей стране.",
        photoUrl: statue
    },
    {
        id: 3,
        coord: { x: 2558, y: 540 },
        title: "Памятник пароходу Мудрец",
        desc: "Памятник морякам парохода «Мудрец», установленнный в 1957 г. в память о погибших на пароходе во время сибирской экспедиции японских войск (1918-1922).",
        photoUrl: steamer
    }
];

export default showplaces;