import { useHistory } from 'react-router';
import { PeopleIcon, StarIcon } from '../../../icons';
import { IGame, ISavedGame } from '../../../models';
import { moneyToString } from '../../../helpers';
import { Button, ProgressBar, ProgressBarColor, ProgressBarSize, ProgressBarTitleStyle } from '../../UiKit';
import style from './style.module.scss';
import dayjs from 'dayjs';

interface IGameElemProps {
    game: IGame;
    savedGame: ISavedGame;
}

const GameElem = (props: IGameElemProps) => {
    const history = useHistory();
    const { game, savedGame } = props;

    const end = dayjs(game.timer.startDate).add(game.cityInfo?.mayorterm ?? 0, 'years');
    const now = dayjs(game.timer.curDate);

    const diffYears = end.diff(now, 'years');
    const diffMonths = end.diff(now.add(diffYears, 'years'), 'months');
    const diffDays = end.diff(now.add(diffYears, 'years').add(diffMonths, 'months'), 'days');
    
    return (<div className={style.game}>
        <div className={style.heading}>
            <h1 className={style.gameTitle}>Игра {game.id}</h1>
            <div className={style.created}>Создано {dayjs(savedGame.created_time).format('DD.MM.YYYY')}</div>
        </div>
        <div className={style.description}>
            <div className={style.col}>
                <ProgressBar icon={<StarIcon />} color={ProgressBarColor.orange} size={ProgressBarSize.big}
                    completedValuePercent={Math.round(game.estimatedIndicators.urbanquality / 360 * 100)} completedValueFact={Math.round(game.estimatedIndicators.urbanquality)} title={'Качество городской среды'} />
                <div className={style.timeOfGame}>
                    <div className={style.name}>Время в игре:</div>
                    <div className={style.value}>{`Года: ${diffYears} Месяца: ${diffMonths} Дни: ${diffDays}`}</div>
                </div>
            </div>
            <div className={style.col}>
                <ProgressBar icon={<PeopleIcon />} color={ProgressBarColor.blue} size={ProgressBarSize.small}
                    titleStyle={ProgressBarTitleStyle.secondary} completedValuePercent={Math.round(game.estimatedIndicators.satisfaction)} completedValueFact={Math.round(game.estimatedIndicators.satisfaction)} title={'Удовлетворенность жителей'} />
                <div className={style.money}>
                    <div className={style.name}>Деньги:</div>
                    <div className={style.value}>{moneyToString(game.estimatedIndicators.money)}</div>
                </div>
            </div>
        </div>
        <div className={style.btn}>
            <Button isBlue={false} disabled={props.game.finished} onClick={() => history.push(`game/${props.game.id}`)} >{props.game.finished ? 'Игра завершена' : 'Продолжить игру'}</Button>
        </div>

    </div>);
}

export default GameElem;