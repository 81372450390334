import { createBrowserHistory as createHistory, History } from 'history';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, /*compose,*/ createStore, Store } from "redux";
import createSagaMiddleware from 'redux-saga';
import { saveStats as saveStatsMiddleware } from '../helpers';
import * as root from "../redux/reducers";
import { configureSaga } from "../redux/sagas";
import { loadState, saveState } from "./managedStore";

// declare global {
//     interface Window {
//         __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
//     }
// }
const sagaMiddleware = createSagaMiddleware();
export const history = createHistory();

function configureStore(history: History) {

    const routersMiddleware = routerMiddleware(history)

    const initialState = loadState();

    const store = createStore(
        root.appReducer,
        initialState,
        applyMiddleware(sagaMiddleware, routersMiddleware, /*loggerMiddleware,*/ saveStatsMiddleware)
        // compose(
        //     applyMiddleware(sagaMiddleware, routersMiddleware, /*loggerMiddleware,*/
        //         saveStatsMiddleware,
        //     ), (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
        // )

    );

    sagaMiddleware.run(configureSaga);

    store.subscribe(() => saveState(store));

    return store;
};

export interface IStore extends Store<root.IState> { }

export const store = configureStore(history);