export const randomGen7_13 = () => {
    const minVal = 7;
    const maxVal = 14;
  
    return randomGen(minVal, maxVal);
}

/**
    Максимум не включается, минимум включается
*/
function randomGen(minVal:number, maxVal: number) {
    const min = Math.ceil(minVal);
    const max = Math.floor(maxVal);
    return (Math.floor(Math.random() * (max - min)) + min) * .1;
}