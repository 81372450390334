import { ArrowDownRedIcon, ArrowUpBlueIcon } from '../../../icons';
import style from './style.module.scss';

interface IRowNameDeltaValueProps {
    name: string;
    value: number;
    delta: number;
}

const RowNameDeltaValue = (props: IRowNameDeltaValueProps) => {
    const { name, value, delta } = props;

    const paintDeltaDigits = (value: number) => {
        let color = "";
        let icon = <ArrowUpBlueIcon />;
        if (value > 0) {
            color = "#0095FF";/* Blue.Small */
        }
        else if (value < 0) {
            color = "#FF3300";//Red Text
            icon = <ArrowDownRedIcon />;
            value *= -1;
        }
        else if (value === 0) {
            icon = <></>;
            color = "black";
        }
        return <div className={style.deltaContent} style={{ color: color }}>{icon}{value}</div>;
    }
    return <div className={style.row}>
        <div className={style.name}>{name}</div>
        <div className={style.delta}>{paintDeltaDigits(delta)}</div>
        <div className={style.value}>{value}</div>

    </div>;
}

export default RowNameDeltaValue;