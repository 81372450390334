import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { PeopleIcon } from '../../icons';
import { ResultsContentType } from '../../models';
import { IState } from '../../redux/reducers';
import { getEstimatedIndicators, getTimer, getYearResult } from '../../redux/selectors';
import ModalComponent from '../ModalComponent';
import { Button, ProgressBar, ProgressBarColor, ProgressBarSize, ProgressBarTitleStyle } from '../UiKit';
import style from './style.module.scss';

const YearResults = 'Результаты года';

interface IResultModalProps {
    contentType: ResultsContentType;
    closeAction: () => void;
}

const ResultsYearModal: React.FunctionComponent<IResultModalProps> = (props) => {
    const { closeAction } = props;
    const estIndicator = useSelector(getEstimatedIndicators);

    const timer = useSelector(getTimer);
    const years = Math.floor(dayjs(timer.curDate).diff(timer.startDate, 'years'));

    const results = useSelector(s => getYearResult(s as IState, years));

    let resArr, topmostThreeSphereResults: any[] = [];
    if (results) {
        resArr = [{ value: results.satisfactionhousing, name: "satisfactionhousing" },
        { value: results.satisfactionparks, name: "satisfactionparks" },
        { value: results.satisfactionoffice, name: "satisfactionoffice" },
        { value: results.satisfactionsocial, name: "satisfactionsocial" },
        { value: results.satisfactioncity, name: "satisfactioncity" },
        { value: results.satisfactionroads, name: "satisfactionroads" }];

        topmostThreeSphereResults = [...resArr].map(e => { return e }).slice().sort(function (a, b) {
            return a.value - b.value;
        });

        //показываем только три отстающие сферы
        topmostThreeSphereResults.length = 3;
    }
    // console.log('Номер года', years, results)

    return <ModalComponent title={YearResults} showBackIcon={false} closeAction={closeAction} height={'25rem'} >

        <div className={style.resultContent}>

            <div className={style.happinesLevel}>
                <ProgressBar icon={<PeopleIcon />} color={ProgressBarColor.blue} size={ProgressBarSize.small}
                    titleStyle={ProgressBarTitleStyle.secondary}
                    completedValuePercent={estIndicator.satisfaction}
                    completedValueFact={Math.round(estIndicator.satisfaction)} title={'Удовлетворенность жителей'} />
            </div>
            <div className={style.warnLabel}>
                Основные проблемы
            </div>
            <div className={style.results}>
                {topmostThreeSphereResults.filter(e => e.name === 'satisfactionhousing')[0] && <ProgressBar title={'Жилье и прилегающее пространство'}
                    completedValuePercent={Math.round(results.satisfactionhousing)} titleStyle={ProgressBarTitleStyle.secondary} size={ProgressBarSize.small} color={ProgressBarColor.red}
                    completedValueFact={Math.round(results.satisfactionhousing)} />}
                <div className={style.space} />
                {topmostThreeSphereResults.filter(e => e.name === 'satisfactionparks')[0] && <ProgressBar title={'Озелененное пространство'}
                    completedValueFact={Math.round(results.satisfactionparks)} completedValuePercent={Math.round(results.satisfactionparks)} titleStyle={ProgressBarTitleStyle.secondary}
                    size={ProgressBarSize.small} color={ProgressBarColor.green} />}
                <div className={style.space} />
                {topmostThreeSphereResults.filter(e => e.name === 'satisfactionoffice')[0] && <ProgressBar title={'Общественно-деловая инфраструктура'}
                    completedValuePercent={Math.round(results.satisfactionoffice)} completedValueFact={Math.round(results.satisfactionoffice)} titleStyle={ProgressBarTitleStyle.secondary} size={ProgressBarSize.small} color={ProgressBarColor.yellow} />}
                <div className={style.space} />
                {topmostThreeSphereResults.filter(e => e.name === 'satisfactionsocial')[0] && <ProgressBar title={'Социально-досуговая инфраструктура'}
                    completedValuePercent={Math.round(results.satisfactionsocial)} completedValueFact={Math.round(results.satisfactionsocial)} titleStyle={ProgressBarTitleStyle.secondary} size={ProgressBarSize.small} color={ProgressBarColor.blue} />}
                <div className={style.space} />
                {topmostThreeSphereResults.filter(e => e.name === 'satisfactioncity')[0] && <ProgressBar title={'Общегородское пространство'}
                    completedValuePercent={Math.round(results.satisfactioncity)} completedValueFact={Math.round(results.satisfactioncity)} titleStyle={ProgressBarTitleStyle.secondary} size={ProgressBarSize.small} color={ProgressBarColor.aquamarine} />}
                <div className={style.space} />
                {topmostThreeSphereResults.filter(e => e.name === 'satisfactionroads')[0] && <ProgressBar title={'Улично-дорожная сеть'}
                    completedValuePercent={Math.round(results.satisfactionroads)} completedValueFact={Math.round(results.satisfactionroads)} titleStyle={ProgressBarTitleStyle.secondary} size={ProgressBarSize.small} color={ProgressBarColor.orange} />}
            </div>


            <div className={style.btns}>
                <Button isStretched={true} isBlue={false} onClick={closeAction}>Закрыть</Button>
            </div>
        </div>
    </ModalComponent >
}
export default ResultsYearModal;