import style from './style.module.scss';
import { Switch, Route } from 'react-router-dom';
import StartPage from '../components/StartPage';
import ProfilePage from '../components/Profile';
import GamePage from '../components/Game';


export function App() {
  return (
    <div className={style.app}>
      <Switch>
        <Route exact={true} path={['/', '/demo/login']} component={StartPage} />
        <Route exact={true} path={'/profile'} component={ProfilePage} />
        <Route path={'/game/:id'} component={GamePage} />
      </Switch>
    </div>
  );
}

export default App;
