import { Action } from "redux";
import { ICityInfo } from "../../../models";
import { CITY_INFO_SET, ICityInfoSet, IMayorTermChange, MAYOR_TERM_CHANGE } from "../../actions/game/cityInfo";

export const initial: ICityInfo = {
    mayorterm: 0
}

export const reducer = (state: ICityInfo = initial, action: Action): ICityInfo => {
  
    switch (action.type) {
        case CITY_INFO_SET:
            const { cityInfo } = action as ICityInfoSet;
            return {        
                ...state,       
                ...cityInfo
            }; 

        case MAYOR_TERM_CHANGE:
            const { delta } = action as IMayorTermChange;
            return {        
                ...state,       
                mayorterm: state.mayorterm + delta
            }; 
        
        default:
            return initial;
    }
};