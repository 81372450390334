import { formatPrice } from '../../../helpers/formatPrice';
import { DollarIcon } from '../../../icons';
import style from './style.module.scss';

interface IProps {
    money: number;
    title: string;
    thousandShow?: boolean;
}

const MoneyDisplay: React.FunctionComponent<IProps> = (props) => {

    const { money: averageIncome, title, thousandShow = false } = props;

    return <div className={style.averageIncomeContainer}>
        <div className={style.title}>
            <DollarIcon />
            <div className={style.titleName}>{title}</div>
        </div>

        <div className={style.titleValue}>{formatPrice(averageIncome)} {thousandShow && 'тыс. '}&#8381;</div>

    </div>
}

export default MoneyDisplay;