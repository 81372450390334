import { call, put, select } from "redux-saga/effects";
import { IState } from '../redux/reducers';
import { logout } from "../redux/actions";

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export interface IResponseStatus {
    status: number;
    statusText: string;
}

export interface IErrorResponse extends IResponseStatus {
    code: number;
    type: string;
    message: string;
}

export interface IValidationError {
    field: string;
    msg: string;
}

export interface IBaseQuery {
    page: number;
    pageSize: number;
}

export interface IBaseRead {
    created?: Date;
    last_changed?: Date;
}

export interface IPageResult<T> {
    items: T[];
    page: number;
    pageSize: number;
    totalCount: number;
}


export interface IValidationErrorResponse extends IErrorResponse {
    details: IValidationError[];
}

export interface IHttpExecuteOptions {
    authorize?: boolean;
    json?: boolean;
    multipart?: boolean;
}

interface IMessage {
    method: HttpMethod,
    headers: Headers,
    body: FormData | string | null
}

interface IFetchAndParseResult<T> {
    error: any,
    result: T
}

function* fetchAndParse<T>(url: string, init: RequestInit) {
    const response: Response = yield call(fetch, url, init);
    const isJsonResponse = (response.headers.get('Content-Type') || '').includes('application/json');

    if (response.ok) {
        if (response.status === 204) {
            return;
        }

        if (isJsonResponse) {
            const json: IFetchAndParseResult<T> = yield call({
                context: response,
                fn: response.json
            });

            if(json.error) {
                const error: IErrorResponse = {
                    status: response.status,
                    statusText: response.statusText,
                    code: json.error.code,
                    type: json.error.type,
                    message: json.error.message
                }
                throw error;
            }
            
            return json;
        }
    }

    if (!isJsonResponse) {
        if (response.status === 400) {
            const error: IValidationErrorResponse = {
                status: response.status,
                statusText: response.statusText,
                code: response.status,
                type: 'E_BAD_REQUEST',
                message: 'Некорректные параметры запроса\nСервер не предоставил подробностей ошибки.',
                details: []
            }
            throw error;
        } else if (response.status === 401) {
            const error: IValidationErrorResponse = {
                status: response.status,
                statusText: response.statusText,
                code: response.status,
                type: 'E_UNAUTHORIZED_REQUEST',
                message: 'Неправильное имя пользователя или пароль',
                details: []
            }
            throw error;
        } else {
            const error: IErrorResponse = {
                status: response.status,
                statusText: response.statusText,
                code: response.status,
                type: 'E_HTTP_ERROR',
                message: 'Ошибка исполнения запроса\nСервер не предоставил подробностей ошибки.'
            }
            throw error;
        }
    }

    if (response.status === 400) {
        const error: IValidationErrorResponse = yield call({
            context: response,
            fn: response.json
        });
        error.status = response.status;
        error.statusText = response.statusText;
        throw error;
    } else {
        const error: IErrorResponse = yield call({
            context: response,
            fn: response.json
        });
        error.status = response.status;
        error.statusText = response.statusText;
        throw error;
    }
}

export const printErrorResponse = (err: IErrorResponse): string => {
    const e = err as any;

    let msg: string;
    if (err.type) {
        msg = `${err.message}`;
    }
    else if (e.message) {
        msg = e.message;
    } else {
        msg = 'Неизвестная ошибка';
    }

    const validatorError = err as IValidationErrorResponse;
    if (validatorError && validatorError.details) {
        msg += '\n';
        msg += validatorError.details.map((r)=>`${r.field}: ${r.msg}`).join('\n');
    }

    return msg;
}

export function* httpExecute<T = any>(
    httpMethod: HttpMethod,
    url: string,
    body?: any,
    options: IHttpExecuteOptions = {}) {

    const init: IMessage = {
        method: httpMethod,
        headers: new Headers(),
        body: null
    }

    const { authorize = true, json = true, multipart } = options;

    if (json && body) {
        init.headers.set('Content-Type', 'application/json');
        init.body = JSON.stringify(body);
    }

    if (multipart && body) {
        const form = new FormData();
        form.append('file', body);
        init.body = form;
    }

    if (authorize) {

        const selector = (state: IState) => {
            return {
                isAuthorized: state.auth.isAuthorized,
                accessToken: state.auth.accessToken,
                authenticationScheme: state.auth.authenticationScheme
            };
        };
        while (true) {
            const { isAuthorized, accessToken, authenticationScheme } = yield select(selector);
            if (!isAuthorized) {
                yield put(logout());
                break;
            }

            init.headers.set('Authorization', `${authenticationScheme} ${accessToken}`);

            try {
                const response:T = yield call(fetchAndParse, url, init);
                return response as T;
            } catch (err) {
                const error = err as IResponseStatus;
                if (error.status === 401 || error.status === 403) {
                    yield put(logout());
                    break;
                }

                throw err;
            }
        }

    } else {
        const response:T = yield call(fetchAndParse, url, init);
        return response as T;
    }
}

export function* httpGet<T = any>(url: string, options?: IHttpExecuteOptions) {
    const result: T = yield httpExecute<T>('GET', url, null, options);
    return result;
}

export function* httpPost<T = any>(url: string, body: any, options?: IHttpExecuteOptions) {
    const result: T = yield httpExecute<T>('POST', url, body, options);
    return result;
}

export function* httpPut<T = any>(url: string, body: any, options?: IHttpExecuteOptions) {
    const result: T = yield httpExecute<T>('PUT', url, body, options);
    return result;
}

export function* httpPatch<T = any>(url: string, body: any, options?: IHttpExecuteOptions) {
    const result: T = yield httpExecute<T>('PATCH', url, body, options);
    return result;
}
