import { useDispatch } from 'react-redux';
import { HouseIcon, MenuNavItemArrowRightIcon, PlaygroundIcon, SkyscrapesIcon, TreesIcon } from '../../../icons';
import initialData from '../../../initialData';
import { sphereObjectsModalShow } from '../../../redux/actions';
import style from './style.module.scss';

const citySpheresInfo = initialData.citySpheresInfo;
const name1 = citySpheresInfo[0].sphereName;
const name2 = citySpheresInfo[1].sphereName;
const name3 = citySpheresInfo[2].sphereName;
const name4 = citySpheresInfo[3].sphereName;

interface IBuildingSphereContextMenuProps {
    contextElementId: string;
    placeId: number;
    isLearningMode?: boolean;
}

const BuildingSphereContextMenu: React.FunctionComponent<IBuildingSphereContextMenuProps> = (props) => {

    const { contextElementId, placeId, isLearningMode } = props;

    const dispatch = useDispatch();

    return <div data-tut="Menu" className={style.buildingSphereCtxMen}>
        <div className={style.header}>Выберете сферу строительства</div>

        <div className={style.content}>
            <div className={style.row} onMouseDown={isLearningMode ? () => { } : (e: any) => { dispatch(sphereObjectsModalShow(citySpheresInfo[0].sphereId, contextElementId, placeId)); }}>
                <HouseIcon />
                <div className={style.name}>{name1}</div>
                <MenuNavItemArrowRightIcon />
            </div>

            <div className={style.row} onMouseDown={isLearningMode ? () => { } : (e: any) => { dispatch(sphereObjectsModalShow(citySpheresInfo[1].sphereId, contextElementId, placeId)); }}>
                <TreesIcon />
                <div className={style.name}>{name2}</div>
                <MenuNavItemArrowRightIcon />
            </div>

            <div className={style.row} onMouseDown={isLearningMode ? () => { } : (e: any) => { dispatch(sphereObjectsModalShow(citySpheresInfo[2].sphereId, contextElementId, placeId)); }}>
                <SkyscrapesIcon />
                <div className={style.name}>{name3}</div>
                <MenuNavItemArrowRightIcon />
            </div>

            <div className={style.row} onMouseDown={isLearningMode ? () => { } : (e: any) => { dispatch(sphereObjectsModalShow(citySpheresInfo[3].sphereId, contextElementId, placeId)); }}>
                <PlaygroundIcon />
                <div className={style.name}> {name4}</div>
                <MenuNavItemArrowRightIcon />
            </div>
        </div>
    </div >;
};
export default BuildingSphereContextMenu;