import { IState } from "../reducers";

export const getGame = (state: IState) => state.game;
export const getSelectedObjects = (state: IState) => state.game.selectedObjects
export const getSelectedObject = (state: IState, placeId: number, objectId: string) => state.game.selectedObjects.find(o => o.objectId === objectId && o.placeId === placeId);
export const getHiredManagers = (state: IState) => state.game.hiredManagers;
export const getNotifications = (state: IState) => state.game.notifications;
export const getRandomEventsResults = (state: IState) => state.game.randomEventsResults;
export const getGameStage = (state: IState) => state.game.stage;
export const getGameFinished = (state: IState) => state.game.finished;
export const getGameId = (state: IState) => state.game.id;
export const getMenuLevel = (state: IState) => state.menu;
export const getGameUserGames = (state: IState) => state.games.games;
export const getEstimatedIndicators = (state: IState) => state.game.estimatedIndicators;

export const getLearningCompleted = (state: IState) => state.game.learningCompleted;
export const getLearningProgress = (state: IState) => state.game.learningProgress;
export const getIsContextMenuShow = (state: IState) => state.game.showContextMenuLearning;
export const getlearningNotifications = (state: IState) => state.game.learningNotifications;

export const getTimer = (state: IState) => state.game.timer;
export const getTimerCurDate = (state: IState) => state.game.timer.curDate;
export const getMoney = (state: IState) => state.game.estimatedIndicators.money;
export const getMaoyrTerm = (state: IState) => state.game.cityInfo?.mayorterm ?? 0;
export const getGoToPoint = (state: IState) => state.game.currentCityPlacePoint;
export const getYearResult = (state: IState, yearId: number) => state.game.yearsResults.filter(_ => _.yearId === yearId)[0];
export const getYearResults = (state: IState) => state.game.yearsResults;

export const getSaveStatsProgress = (state: IState) => state.addGameStats.inProgress;
