import { useSelector } from "react-redux";
import { getEstimatedIndicators } from "../redux/selectors";

export const useLevel = () => {
  const { govscale, businessscale } = useSelector(getEstimatedIndicators);
  if (govscale >= 75 && businessscale >= 75) {
    return 4;
  } else if (govscale >= 50 && businessscale >= 50) {
    return 3;
  }
  if (govscale >= 25 && businessscale >= 25) {
    return 2;
  } else
    return 1;
}

