import { Action } from "redux";
import { LEARNING_PROGRESS_INIT, ILearningProcessAction, LEARNING_PROGRESS_START, LEARNING_PROGRESS_PAUSE, LEARNING_PROGRESS_NEXT } from "../../actions/game/learningProgress";

export interface ILearningProgress {
    step: number,
    inProgress: boolean
}

export const initial: ILearningProgress = { step: 0, inProgress: false };

export const reducer = (state: ILearningProgress = initial, action: Action): ILearningProgress => {
    switch (action.type) {
        case LEARNING_PROGRESS_INIT: {
            const { step } = action as ILearningProcessAction
            return {
                ...state, 
                step
            }
        }
        case LEARNING_PROGRESS_NEXT: {
            return {
                ...state, 
                step: state.step + 1
            }
        }
        case LEARNING_PROGRESS_START: {
            return {
                ...state, inProgress: true
            }
        }
        case LEARNING_PROGRESS_PAUSE: {
            return {
                ...state, inProgress: false
            }
        }

        default:
            return state;
    }
}