import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { moneyToString } from '../../helpers';
import { DollarIcon } from '../../icons';
import { getEstimatedIndicators } from '../../redux/selectors';
import ModalComponent from '../ModalComponent';
import { Button } from '../UiKit';
import style from './style.module.scss';

export type DeficitType = 'deficit2' | 'deficit3' | 'gameover';
interface IResultsGameOverModalProps {
    closeAction(): void;
    deficitType: DeficitType;
}

const ResultsMoneyDeficitModal: React.FunctionComponent<IResultsGameOverModalProps> = (props) => {

    const { closeAction, deficitType } = props;
    const history = useHistory();

    const indicators = useSelector(getEstimatedIndicators);
    const header = () => {
        if (deficitType === 'deficit2') {
            return 'Средняя опасность дефолта';
        }
        if (deficitType === 'deficit3') {
            return 'Высокая опасность дефолта';
        }

        return 'Игра закончена';
    }

    const reaction = () => {
        if (deficitType === 'deficit2') {
            return <Button isStretched={true} onClick={closeAction}>Понятно</Button>;
        }
        if (deficitType === 'deficit3') {
            return <Button isStretched={true} onClick={closeAction}>Понятно</Button>;
        }

        return <Button isStretched={true} onClick={() => history.push('/profile')}>Начать заново</Button>;
    }

    const content = () => {
        if (deficitType === 'deficit2') {
            return <>
                <div className={style.warnLabel}>
                    {'Вы достигли среднего уровня опасности дефолта. Процентная ставка на дефицит бюджета — 11% годовых.'}
                </div>

                <div className={style.budget}>
                    {`Если ваш бюджет достигнет ${indicators.deficitroof2} ₽, процентная ставка увеличится`}
                </div>
            </>;
        }
        if (deficitType === 'deficit3') {
            return <>
                <div className={style.warnLabel}>
                    {'Вы достигли высокого уровня опасности дефолта. Процентная ставка на дефицит бюджета — 15% годовых.'}
                </div>

                <div className={style.budget}>
                    {`Если ваш бюджет достигнет ${indicators.deficitroof3} ₽, ваш город столкнется с дефолтом, и вы будете отправлены в отставку.`}
                </div>
            </>;
        }

        return <>
            <div className={style.warnLabel}>
                {'Вы исчерпали все ресурсы города и допустили дефолт. Вас отправили в отставку.'}
            </div>

            <div className={style.budget}>
                <DollarIcon />
                <div className={style.name}>Бюджет:</div>
                <div className={style.value}>{moneyToString(indicators.money)}</div>
            </div>
        </>;
    }


    return <ModalComponent isHeader={false} closeAction={closeAction} height='22rem' isShowCloseIcon={false}>
        <div className={style.resultContent}>
            <h1 className={style.resultContentHeading}>{header()}</h1>

            {content()}

            <div className={style.btns}>
                {reaction()}
            </div>
        </div>
    </ModalComponent >
}
export default ResultsMoneyDeficitModal;