import { Action } from "redux";
import { IRandomEventAction } from "../../../models";
import { GAME_SET_RANDOM_EVENT_RESULT, ISetRandomEventResultAction, } from "../../actions";

export const initial: IRandomEventAction[] = [];

export const reducer = (state: IRandomEventAction[], action: Action) => {
    switch (action.type) {
        case GAME_SET_RANDOM_EVENT_RESULT:
            const { randomEventResult } = action as ISetRandomEventResultAction;
            return [...state, randomEventResult];

        default:
            return state;
    }
}