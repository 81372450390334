import { initial as initialState, IState } from "../redux/reducers";
import { IStore } from "./configureStore";

const APP_STATE = 'TCG';
export interface IPersistedState {
    auth: {
        accessToken: string | null;
        authenticationScheme: string | null;
        isAuthorized: boolean;
    }
}

function select(state: any) {
    const storedState: IPersistedState = {
        auth: {
            accessToken: state.auth.accessToken,
            authenticationScheme: state.auth.authenticationScheme,
            isAuthorized: !!state.auth.accessToken
        }
    };
    return storedState
}

let currentValue: IPersistedState;

export const saveState = (store: IStore) => {
    let previousValue = currentValue
    currentValue = select(store.getState());

    if (JSON.stringify(previousValue) !== JSON.stringify(currentValue)) {
        localStorage.setItem(APP_STATE, JSON.stringify(currentValue));
    }
};

export const loadState = (): IState => {
    const stateJson = localStorage.getItem(APP_STATE);
    if (!stateJson) {
        return initialState;
    }

    try {
        const state = JSON.parse(stateJson) as IPersistedState;
        return {
            game: initialState.game,
            auth: {
                accessToken: state.auth.accessToken,
                authenticationScheme: state.auth.authenticationScheme,
                error: null,
                inProgress: false,
                isAuthorized: !!state.auth.accessToken
            },
            modalDialog: [],
            modalDialog2: {},
            games: initialState.games,
            saveGame: initialState.saveGame,
            menu: initialState.menu,
            addGameStats: initialState.addGameStats
        };
    } catch {
        return initialState;
    }
}
