import cn from 'classnames';
import React from 'react';
import { BackIcon, CloseIcon } from '../../icons';
import CustomScrollbar from '../UiKit/Scrollbar';
import style from './style.module.scss';

interface IModalProps {
    title?: string;
    showBackIcon?: boolean;
    closeAction?: () => void;
    backAction?: () => void;
    isHeader?: boolean;
    isHeaderBottomBorder?: boolean;
    height?: string;
    isBackside?: boolean;
    isShowCloseIcon?: boolean;
}

const ModalComponent: React.FunctionComponent<IModalProps> = (props) => {

    const { isHeader = true, title = "", showBackIcon = true, closeAction, backAction,
        isHeaderBottomBorder, height = '93%', isBackside, isShowCloseIcon = true } = props;
    const [modalIsOpen, setIsOpen] = React.useState(true);

    const closeModal = (e: any) => {
        closeAction && closeAction();
        setIsOpen(false);
        e.preventDefault();
        e.stopPropagation();
    }

    const back = (e: any) => {
        if (backAction) {
            backAction();
            setIsOpen(false);
        }
        e.preventDefault();
        e.stopPropagation();
    }

    return !modalIsOpen ? null :
        <div style={isBackside ? { zIndex: 499 } : { zIndex: 888 }} className={style.modalContainer}>
            <div className={style.modal}>
                {isHeader ? <div className={cn(style.header, isHeaderBottomBorder && style.headerBottomBorder)}>
                    <div className={style.headerContainer} onMouseDown={back}>
                        {showBackIcon && <div className={style.headerBackIcon}>
                            <BackIcon />
                        </div>}
                        <div className={style.headerName}>{title}</div>
                    </div>
                    {isShowCloseIcon && <CloseIcon onMouseDown={closeModal} className={style.closeBtn} />}
                </div> :
                    <div className={style.onlyCloseBtnAsHeader} >
                    {isShowCloseIcon && <CloseIcon onMouseDown={closeModal} className={style.closeBtn} />}
                    </div>}
                <div className={style.content} style={{ height: height }}>
                    <CustomScrollbar styleCustom={{ height: height, width: '820px' }}>
                        {props.children}


                    </CustomScrollbar>
                </div>
            </div>
        </div>;
}

export default ModalComponent;