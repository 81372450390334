import React from 'react';
import Scrollbars from 'react-scrollbars-custom';
import style from './style.module.scss';

interface IProgressBarProps {
    styleCustom: any;
}

const CustomScrollbar: React.FunctionComponent<IProgressBarProps> = (props) => {

    const { styleCustom } = props;

    return <Scrollbars style={styleCustom}

        // renderer={props => {
        //     const { elementRef, ...restProps } = props;
        //     return <span {...restProps} ref={elementRef} className="MyAwesomeScrollbarsHolder" />;
        // }}
        // wrapperProps={{
        //     renderer: props => {
        //         const { elementRef, ...restProps } = props;
        //         return <span {...restProps} ref={elementRef} className="MyAwesomeScrollbarsWrapper" />;
        //     }
        // }}
        // scrollerProps={{
        //     renderer: props => {
        //         const { elementRef, ...restProps } = props;
        //         return <span {...restProps} ref={elementRef} className="MyAwesomeScrollbarsScroller" />;
        //     }
        // }}
        // contentProps={{
        //     renderer: props => {
        //         const { elementRef, ...restProps } = props;
        //         return <span {...restProps} ref={elementRef} className="Content" />;
        //     }
        // }}
        trackXProps={{
            renderer: props => {
                const { elementRef, ...restProps } = props;
                return <span {...restProps} ref={elementRef} className={style.trackX}/>;
            }
        }}
        trackYProps={{
            renderer: props => {
                const { elementRef, ...restProps } = props;
                return <span {...restProps} ref={elementRef} className={style.trackY} />;
            }
        }}
    // thumbXProps={{
    //     renderer: props => {
    //         const { elementRef, ...restProps } = props;
    //         return <span {...restProps} ref={elementRef} className={style.thUmBX} />;
    //     }
    // }}
    // thumbYProps={{
    //     renderer: props => {
    //         const { elementRef, ...restProps } = props;
    //         return <span {...restProps} ref={elementRef} className={style.tHuMbY} />;
    //     }
    // }}
    >
        <div>
            {props.children}
        </div>
    </Scrollbars>


}

export default CustomScrollbar;