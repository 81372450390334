import { useHistory } from 'react-router';
import ModalComponent from '../ModalComponent';
import { Button } from '../UiKit';
import style from './style.module.scss';

const GameOverGovScaleModal: React.FunctionComponent = () => {
    const history = useHistory();
    const warnLabel = 'К сожалению, за время руководства городом, вы не смогли добиться желаемого уровня одобрения государством и вас сместил более подходящий кандидат.';

    return <ModalComponent isHeader={false} height='20rem' isShowCloseIcon={false}>
        <div className={style.resultContent}>
            <h1 className={style.resultContentHeading}>{'Игра закончена'}</h1>

            < div className={style.warnLabel}>
                {warnLabel}
            </div>

            <div className={style.btns}>
                <Button isStretched={true} onClick={() => history.push('/profile')}>Начать заново</Button>
            </div>
        </div>
    </ModalComponent >
}

export default GameOverGovScaleModal;