import { Action } from "redux";
import { IYearResults } from "../../../models";
import { IYearResultsSet, YEAR_RESULT_SET } from "../../actions";

export const initial: IYearResults[] = [];

export const reducer = (state: IYearResults[] = initial, action: Action): IYearResults[] => {

    switch (action.type) {
        case YEAR_RESULT_SET:
            const { yearResult } = action as IYearResultsSet;
            if (state.find(_ => _.yearId === yearResult.yearId)) {
                return state.map(yr => {
                    if (yr.yearId === yearResult.yearId) {
                        return {
                            yearId: yr.yearId,
                            monthId: yearResult.monthId,
                            dayId: yearResult.dayId,
                            moneysum: yr.moneysum + yearResult.moneysum,
                            moneyhousing: yr.moneyhousing + yearResult.moneyhousing,
                            moneyparks: yr.moneyparks + yearResult.moneyparks,
                            moneyoffice: yr.moneyoffice + yearResult.moneyoffice,
                            moneysocial: yr.moneysocial + yearResult.moneysocial,
                            moneycity: yr.moneycity + yearResult.moneycity,
                            moneyroads: yr.moneyroads + yearResult.moneyroads,
                            satisfactionhousing: yr.satisfactionhousing,
                            satisfactionparks: yr.satisfactionparks,
                            satisfactionoffice: yr.satisfactionoffice,
                            satisfactionsocial: yr.satisfactionsocial,
                            satisfactioncity: yr.satisfactioncity,
                            satisfactionroads: yr.satisfactionroads,
                        };
                    }
                    return yr;
                });
            }

            return [...state, { ...yearResult }];

        default:
            return initial;
    }
};