import { ICityInfo } from "../../../models";
import { Action } from "redux";

export const CITY_INFO_SET = 'CITY_INFO_SET';
export const MAYOR_TERM_CHANGE = 'MAYOR_TERM_CHANGE';


export interface ICityInfoSet extends Action {
    cityInfo: ICityInfo;
}

export interface IMayorTermChange extends Action {
    delta: number;
}

export function cityInfoSet(cityInfo: ICityInfo) {
    return { type: CITY_INFO_SET, cityInfo};
}

export function changeMayorTerm(delta:  number) {
    return { type: MAYOR_TERM_CHANGE, delta};
}
