import { Action } from "redux";
import { IModalDialog } from "../../models";
import {
  INotificationModalShowAction, MODAL2_HIDE, NOTIFICATION_MODAL_SHOW
} from "../actions/dialogs";

export const initial: IModalDialog = {};
export const actionWithoutTypeProp = ({ type, ...rest }: any) => rest;

export const reducer = (state: any = initial, action: Action): IModalDialog => {
  switch (action.type) {
    case NOTIFICATION_MODAL_SHOW:
      const { notificationId, eventResult } = action as INotificationModalShowAction;
      if (eventResult) {
        return {
          ...state,
          dialogType: "NotificationModal",
          notificationId,
          eventResult
        };
      }

      return {
        ...state,
        dialogType: "NotificationModal",
        notificationId,
      };

    case MODAL2_HIDE:
      return initial;

    default:
      return state;
  }
};
