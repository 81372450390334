import { Action } from "redux";
import { ICityBildingPlacePoint } from "../../../models";


export const GO_TO_PLACE_INIT = 'GO_TO_PLACE_INIT';
export const GO_TO_PLACE_DONE = 'GO_TO_PLACE_DONE';

export interface ICityPlacePointInitAction extends Action {
    point: ICityBildingPlacePoint;
}

export function goToCityPlacePointInit(point: ICityBildingPlacePoint) {
    return { type: GO_TO_PLACE_INIT, point } as ICityPlacePointInitAction;
}

export function goToCityPlacePointDone() {
    return { type: GO_TO_PLACE_DONE };
}