import { Action } from "redux";
import { MenuLevelEmum } from "../../components/GameMenu";

export const SHOW_MENU = 'SHOW_MENU';
export const CLEAR_MENU_STATE_ON_HIRE_MANAGER = 'CLEAR_MENU_STATE_ON_HIRE_MANAGER';

export interface IShowMenuAction extends Action {
    menu: MenuLevelEmum;
    sphereId?: number;
    objectId?: string;
}
export interface IClearMenuAction extends Action {
    menu: MenuLevelEmum;

}

export function showMenuAction(menu: MenuLevelEmum, sphereId?: number, objectId?: string) {
    return { type: SHOW_MENU, menu, sphereId, objectId } as IShowMenuAction;
}
export function clearMenuStateOnHireManager(menu: MenuLevelEmum,) {
    return { type: CLEAR_MENU_STATE_ON_HIRE_MANAGER, menu } as IClearMenuAction;
}
