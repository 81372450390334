import { PinIcon } from "../../../../icons";
import style from './style.module.scss';

interface IBuildingPlacePin {
    placeId: number;
    onClick: () => void;
}

const BuildingPlacePin: React.FunctionComponent<IBuildingPlacePin> = (props) => {

    const { onClick } = props;

    return <div className={style.pinContainer} onMouseDown={onClick}>
        <PinIcon />
    </div>
}

export default BuildingPlacePin;