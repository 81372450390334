import { useSelector } from "react-redux";
import { moneyToString } from "../../../helpers";
import { getMoney } from "../../../redux/selectors";
import { StatusValue } from "../Common";

const MoneyItem = () => {

    const money = useSelector(getMoney);
    return <StatusValue statusName={'Деньги:'} value={moneyToString(money)} />;
}

export default MoneyItem;