import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEventListener } from '../../hooks';
import { getIsContextMenuShow } from '../../redux/selectors';
import BuildingPlaceContextMenu from './BuildingPlaceContextMenu';

const CONTEXT_ID = 'context';
const useContextMenu = (elementId?: string,) => {
    const [xPos, setXPos] = useState(0);
    const [yPos, setYPos] = useState("0px");
    const [showMenu, setShowMenu] = useState(false);


    const handleClick = (e: any) => {
        const elem = document.getElementById(elementId ?? "");

        if (elem?.matches(':hover')) {
            var rect = elem?.getBoundingClientRect();
            if (rect) {
                setXPos(e.pageX - rect.x);
                setYPos(`${e.pageY}px`);

                // Если элемент внизу экрана
                const y = e.pageY;
                const shiftY = 250;
                const windowInnerHeight = window.innerHeight;
                if (y && windowInnerHeight - y < shiftY) {
                    setYPos(`${e.pageY - shiftY}px`);
                }

                setShowMenu(true);
            }
        }
        else
            setShowMenu(false);

        e.stopPropagation();
    };

    useEventListener("mousedown", handleClick, document);
    useEventListener(`showcontextagain${elementId}`, () => setShowMenu(true), document);
    const isLearning = useSelector(getIsContextMenuShow);

    return { xPos, yPos, showMenu, isLearning };
};

const ContextMenu = ({ elementId, placeId, xPosDelta }: any) => {

    const { xPos, yPos, showMenu, isLearning } = useContextMenu(elementId);
    if (isLearning) {
        // console.log(isLearning)
        return <div id={CONTEXT_ID}
            style={{
                top: 500,
                left: 5700,
                 opacity: 1,
                position: 'absolute',
                zIndex: 3
            }}
        >
            {<BuildingPlaceContextMenu contextElementId={"10"} placeId={10} isLearningMode={true} />}
        </div>
    }
    return showMenu ? (
        <div id={CONTEXT_ID}
            style={{
                top: yPos,
                left: xPosDelta + xPos + 20,
                opacity: showMenu ? 1 : 0,
                position: 'absolute',
                zIndex: 3
            }}
        >
            {<BuildingPlaceContextMenu contextElementId={elementId} placeId={placeId} />}
        </div>
    ) : <></>;
}

export default ContextMenu;