import { push } from "react-router-redux";
import { Action } from "redux";
import { call, put, takeLatest } from 'redux-saga/effects';
import { authorize, IAuthResult } from "../../api/auth";
import { IErrorResponse } from '../../api/common';
import { ILoginInitAction, loginDone, loginFail, LOGIN_INIT } from '../actions';

export function* authHandler(action: Action) {
    try {
        const { username, password } = action as ILoginInitAction;
        const result: IAuthResult = yield call(authorize, username, password);
        yield put(loginDone(result));
        yield put(push('/profile'));
    } catch (e) {
        const c = e as IErrorResponse;
        yield put(loginFail(c))
    }
}


export function* authSaga() {
    yield takeLatest(LOGIN_INIT, authHandler);
}
