import { StatusValue } from "../Common";
import dayjs from 'dayjs';
import { useSelector } from "react-redux";
import { getMaoyrTerm, getTimer } from "../../../redux/selectors";
import { useGameTimer } from "./useGameTimer";
import { useMainGameLoop } from "../../../hooks/useMainGameLoop";

const TimeItem = () => {

    const timer = useSelector(getTimer);
    const mayorterm = useSelector(getMaoyrTerm);

    useGameTimer(timer);
    useMainGameLoop();

    const end = dayjs(timer.startDate).add(mayorterm, 'years');
    const now = dayjs(timer.curDate);

    const diffYears = end.diff(now, 'years');
    const diffMonths = end.diff(now.add(diffYears, 'years'), 'months');
    const diffDays = end.diff(now.add(diffYears, 'years').add(diffMonths, 'months'), 'days');

    return <>
        <StatusValue statusName={'Года:'} value={diffYears} widthValue={20} />
        <StatusValue statusName={'Месяца:'} value={diffMonths} widthValue={20} />
        <StatusValue statusName={'Дни:'} value={diffDays} widthValue={20} />
    </>;
}

export default TimeItem;