import { useQuery } from ".";

export function useBeginLtiAuth() {
  const query = useQuery();

  try {
    const scope = query.get("scope");
    const client_id = query.get("client_id");
    const response_type = query.get("response_type");
    const redirect_uri = query.get("redirect_uri");
    const login_hint = query.get("login_hint");
    const response_mode = query.get("response_mode");
    const nonce = query.get("nonce");
    const state = query.get("state");
    const prompt = query.get("prompt");
    const lti_message_hint = query.get("lti_message_hint");
    const lti_url = query.get("lti_url");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    var urlencoded = new URLSearchParams();
    urlencoded.append("scope", scope!);
    urlencoded.append("response_type", response_type!);
    urlencoded.append("response_mode", response_mode!);
    urlencoded.append("nonce", nonce!);
    urlencoded.append("prompt", prompt!);
    urlencoded.append("redirect_uri", redirect_uri!);
    urlencoded.append("client_id", client_id!);
    urlencoded.append("login_hint", login_hint!);
    urlencoded.append("lti_message_hint", lti_message_hint!);
    urlencoded.append("state", state!);
    urlencoded.append("repost", "1");

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(lti_url!, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  } catch (ex) {
    console.log(ex);
  }
}
