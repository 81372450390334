import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  gameStage,
  learningProgressInit,
  learningProgressStart,
} from "../../redux/actions";
import { showMenuAction } from "../../redux/actions/menu";
import { MenuLevelEmum } from "../GameMenu";
import ModalComponent from "../ModalComponent";
import { Button } from "../UiKit";
import style from "./style.module.scss";

const step1Intro =
  "Приветствуем! Мы рады, что вы присоединились к нам. Вас ждут события, требующие смелости и оригинальности! Наберитесь сил и терпения и сделайте этот город лучше!";
const step2Intro = <>
  <p >
    Город, которым вы будете управлять, называется Свободный. Он расположен в Амурской области и является административным центром Свободненского района.
    Типичный ландшафт этой местности - смешанные леса. Территория довольно хорошо озеленена и здесь есть множество субъектов хозяйственной деятельности. Жители любят свой город.
    Благодаря им здесь часто проводятся субботники и мероприятия по благоустройству отдельных зон.
  </p>
  <br />
  <p>
    По данным Всероссийской переписи 2020 года по численности населения (48 517) город находился на 325-м месте из 1117 городов России. Третий по числу жителей город в Амурской области после Благовещенска (241 437) и Белогорска (61 440).
    Население города росло вплоть до начала 90-х, насчитывая на пике более 80 тысяч человек, но с тех пор практически каждый год снижается.
  </p>
  <br />
  <p  >
    Город расположен на правом берегу реки Зея (левый приток Амура) в 125 км к северо-северо-востоку от Благовещенска. В 45 км севернее Свободного находится город Циолковский, центр нового российского космодрома «Восточный»,
    строительство которого начато в 2012 году.
  </p>
  <br />
  <p>
    В июне 2017 года Постановлением Правительства РФ на территориях муниципальных образований Свободненский район, город Свободный и город Сковородино создана одна из предусмотренных в России территорий опережающего развития — ТОР «Свободный».
    В ТОР «Свободненская» планируется построить два крупных предприятия, которые станут компонентами строящегося магистрального газопровода «Сила Сибири». Открытие состоялось 2 декабря 2019 года.
  </p>
  <br />
  <p>
    Предполагается, что Амурский ГПЗ станет вторым по объёму переработки природного газа(42 млрд м³ в год) и крупнейшим в мире по производству гелия(до 60 млн м³ в год).
  </p>
</>
const GameIntro = () => {
  const dispatch = useDispatch();

  const [step, setStep] = useState<number>(1);
  const closeHandle = () => {
    dispatch(learningProgressInit(1));
    dispatch(learningProgressStart());
    dispatch(gameStage("learning"));
    dispatch(showMenuAction(MenuLevelEmum.Details));
  };
  return (
    <ModalComponent
      title={"Об игре"}
      showBackIcon={false}
      closeAction={closeHandle}
      height={step === 1 ? "16rem" : "58rem"}
    >
      <div className={style.gameIntro}>
        {step === 1 ? step1Intro : step2Intro}

        <div className={style.gameIntroNavContainer}>
          <Button
            isBlue={true}
            onClick={() => {
              if (step === 2) {
                closeHandle();
              } else if (step === 1) {
                setStep(2);
              }
            }}
          >
            Продолжить
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default GameIntro;
