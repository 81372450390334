import { useSelector } from "react-redux";
import { IMenuLevelProps, MenuLevelEmum } from ".";
import { BuildingIcon, ManagerIcon, PeopleIcon, StarIcon } from "../../icons";
import { getEstimatedIndicators } from "../../redux/selectors";
import { ProgressBar, ProgressBarColor, ProgressBarSize, ProgressBarTitleStyle } from "../UiKit";
import MoneyDisplay from "./AverageIncome";
import DetailsButton from "./DetailsButton";
import MainMenuHeader from "./MenuHeader/mainHeader";
import MenuItem from "./MenuItem";
import MenuItemDivider from "./MenuItemDivider";
import MenuNavItem from "./MenuNavItem";
import style from './style.module.scss';


export function MenuLevel0(props: IMenuLevelProps) {

    const { menuLevelSet } = props;

    const estimatedIndicators = useSelector(getEstimatedIndicators);

    return <>
        <MainMenuHeader menuLevelSet={menuLevelSet} />

        <div className={style.menuItems}>

            <MenuItem>
                <ProgressBar icon={<StarIcon />} color={ProgressBarColor.orange} size={ProgressBarSize.big} completedValuePercent={Math.round(estimatedIndicators.urbanquality / 360 * 100)} completedValueFact={Math.round(estimatedIndicators.urbanquality)} title={'Качество городской среды'} />
            </MenuItem>

            <MenuItem>
                <ProgressBar icon={<PeopleIcon />} color={ProgressBarColor.blue} size={ProgressBarSize.small} titleStyle={ProgressBarTitleStyle.secondary} completedValuePercent={Math.round(estimatedIndicators.satisfaction)} completedValueFact={Math.round(estimatedIndicators.satisfaction)} title={'Удовлетворенность жителей'} />
            </MenuItem>

            <MenuItem>
                <div className={style.populationInfo}>
                    <div className={style.popTitle}>Индекс инвестиционной привлекательности</div>
                    <div className={style.popValue}>{estimatedIndicators.businessscale}</div>
                </div>
            </MenuItem>

            <MenuItem>
                <div className={style.populationInfo}>
                    <div className={style.popTitle}>Одобрение государством</div>
                    <div className={style.popValue}>{estimatedIndicators.govscale}</div>
                </div>
            </MenuItem>

            <MenuItem>
                <MoneyDisplay title={'Среднедушевой доход'} money={Math.round(estimatedIndicators.percitizen) * 1000} />
            </MenuItem>

            <MenuItem>
                <MoneyDisplay title={'Доходы в день'} money={estimatedIndicators.moneyDplus} thousandShow={true} />
            </MenuItem>

            <MenuItem>
                <MoneyDisplay title={'Расходы в день'} money={estimatedIndicators.moneyDminus} thousandShow={true} />
            </MenuItem>

            <MenuItem>
                <div className={style.populationInfo}>
                    <div className={style.popTitle}>Население</div>
                    <div className={style.popValue}>{Math.round(estimatedIndicators.svobodniy)}</div>
                </div>
            </MenuItem>

            <MenuItem>
                <DetailsButton onClick={() => menuLevelSet(MenuLevelEmum.Details)} />
            </MenuItem>

            <MenuItem />

            <MenuItemDivider />

            <MenuNavItem icon={<BuildingIcon />} title={'Инфраструктура'} onClick={() => menuLevelSet(MenuLevelEmum.Level1_Buildings)} />

            <MenuItemDivider />

            <MenuNavItem icon={<ManagerIcon />} title={'Руководители'} onClick={() => menuLevelSet(MenuLevelEmum.Managers)} />

            <MenuItemDivider />

            <MenuNavItem icon={<BuildingIcon />} title={'Сферы управления'} onClick={() => menuLevelSet(MenuLevelEmum.Spheres)} />

            <MenuItemDivider />
        </div>
    </>;
}