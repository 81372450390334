import { ReactourStep } from "reactour";
import StepTour from "../components/Game/StepTour";

export const getSteps = (onNext: () => void, onClose: () => void): ReactourStep[] => {
  const steps: ReactourStep[] = [
    {
      selector: '[data-tut="None"]',
      content: <StepTour onNext={onNext} onFinish={onClose} text={""} />,
      stepInteraction: true,
      position: "center"
    },
    {
      selector: '[data-tut="Indicators"]',
      content: <StepTour onNext={onNext} onFinish={onClose} text={"В игре есть несколько показателей, которые влияют на развитие города. Каждое улучшение и событие имеет определенный набор показателей. Улучшения могут влиять сразу на несколько параметров."} />,
      stepInteraction: true,
      position: "center"
    },
    {
      selector: '[data-tut="Indicators"]',
      content: <StepTour onNext={onNext} onFinish={onClose} text={"Дополнительно вы можете получать разовые ежегодные выплаты (гранты), если достигнете более 60 баллов по шкале \"Индекс инвестиционной привлекательности\"."} />,
      stepInteraction: true,
      position: "center",
    },
    {
      selector: '[data-tut="Indicators"]',
      content: <StepTour onNext={onNext} onFinish={onClose} text={"Обратите внимание, что если вы не достигнете 40 значения по каждому из показателей «Одобрение государством» и «Индекс инвестиционной привлекательности» за 5 лет, то ваш срок управления городом не будет продлен"} />,
      stepInteraction: true,
      position: "center",
    },
    {
      selector: '[data-tut="Buildings"]',
      content: <StepTour onNext={onNext} onFinish={onClose} text={"Город состоит из нескольких сфер. Каждая создана для того, чтобы удовлетворять потребности жителей. Со временем потребности жителей будут расти и вам необходимо будет время от времени совершенствовать какие-то сферы."} />,
      stepInteraction: true,
      position: "center"
    },
    {
      selector: '[data-tut="Menu"]',
      content: <StepTour onNext={onNext} onFinish={onClose} text={"У игрока есть возможность строить и улучшать объекты в городе. Но если они не понравятся вам или жителям - их всегда можно снести."} />,
      stepInteraction: true,
      position: "center"
    },
    {
      selector: '[data-tut="Notifications"]',
      content: <StepTour onNext={onNext} onFinish={onClose} text={"Случайные события будут двигать сюжет игры вперед и не дадут вам заскучать. Они могут быть как положительными, так и отрицательными. Каждый вариант реагирования на событие имеет свою ценность, а также влияет на показатели в игре."} />,
      stepInteraction: true,
      position: "top"
    },
    {
      selector: '[data-tut="Indicator1"]',
      content: <StepTour onNext={onNext} onFinish={onClose} text={"Игроку важно удерживать баланс показателей и следить за временем на посту. Для того, чтобы жители всегда оставались довольными необходимо стараться максимизировать все показатели. Также не стоит допускать сильный дефицит бюджета, иначе произойдет дефолт и игра для вас закончится."} />,
      stepInteraction: true,
      position: "center"
    },
    {
      selector: '[data-tut="Indicators"]',
      content: <StepTour onNext={onNext} onFinish={onClose} isContentComponent={true}
        text={() => <div>Игра может досрочно завершиться по следующим обстоятельствам:
          <br />
          <br />
          1. Закончился ваш срок полномочий и его не продлили из-за низкого уровня инвестиционной привлекательности города.
          <br />
          2. Уровень удовлетворенности жителей существенно упал и вас отстранили от занимаемой должности.
          <br />
          3. Вы не достигли необходимого уровня одобрения государством.</div>} />,
      stepInteraction: true,
      position: "center",
    },
    {
      selector: '[data-tut="IndicatorTime"]',
      content: <StepTour isLastStep={true} onFinish={onClose} text={"Игроку важно удерживать баланс показателей и следить за временем на посту. Для того, чтобы жители всегда оставались довольными необходимо стараться максимизировать все показатели. Также не стоит допускать сильный дефицит бюджета, иначе произойдет дефолт и игра для вас закончится."} />,
      stepInteraction: true,
      position: "center"
    },


  ]

  return steps;
}