import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuLevelEmum } from '..';
import { HouseIcon, MenuNavItemArrowRightIcon, PlaygroundIcon, SkyscrapesIcon, TreesIcon } from '../../../icons';
import initialData from '../../../initialData';
import { clearMenuStateOnHireManager } from '../../../redux/actions/menu';
import { getHiredManagers, getMenuLevel, getModalDialog } from '../../../redux/selectors';
import ManagerCard from '../../ManagerCard';
import CustomScrollbar from '../../UiKit/Scrollbar';
import BackMenuHeader from '../MenuHeader/backHeader';
import style from './style.module.scss';
import { IModalDialog } from '../../../models';

const MenuLevel1Managers: React.FunctionComponent<{ menuLevelSet: (level: MenuLevelEmum) => void }> = ({ menuLevelSet }) => {
    const objectModalShow = useSelector(getModalDialog)
    const dispatch = useDispatch();
    useEffect(() => {
        if (objectModalShow) {
            const objectDoneModal = objectModalShow.filter((e: IModalDialog) => e.dialogType === "ObjectDoneModal")[0];
            if (!objectDoneModal)
                dispatch(clearMenuStateOnHireManager(MenuLevelEmum.Managers));
        }
    }, [objectModalShow, dispatch]);

    const setMenu = useSelector(getMenuLevel);
    useEffect(() => {
        if (setMenu.sphereId) {
            setSphereId(setMenu.sphereId);
            if (setMenu.objectId) {
                setFromObjectModal(true);
            }
            setManagersListView(true);
        }
    }, [setMenu]);

    const [isFromObjectModal, setFromObjectModal] = useState<boolean>(false);
    const [selectedSphereId, setSphereId] = useState(0);
    const [isManagersListView, setManagersListView] = useState(false);

    const hiredManagers = useSelector(getHiredManagers);
    const citySpheresInfo = initialData.citySpheresInfo;

    const renderManagers = (key: number) => {
        const cityShp = citySpheresInfo[key - 1];

        return <>
            <h1 className={style.spheareName}>{cityShp.sphereName}</h1>
            <CustomScrollbar styleCustom={{ height: '95%' }}>
                <div className={style.managers}>
                    {cityShp.managers.map((m, i) => <ManagerCard
                        key={i} isHired={hiredManagers.some(e => e.managerId === m.id)}
                        isFromSphere={cityShp.sphereId === setMenu.sphereId}
                        manager={m} objectId={isFromObjectModal ? setMenu.objectId ?? '' : ''} />)}
                </div>
            </CustomScrollbar>
        </>;
    }

    const renderSpheres = () => <>
        <h1 className={style.spheareNameHeading}>Сферы управления</h1>

        <div className={style.content}>
            {[<HouseIcon />, <TreesIcon />, <SkyscrapesIcon />, <PlaygroundIcon />]
                .map((icon, index: number) => <div
                    key={index}
                    className={style.row}
                    onClick={() => handleClickSphere(citySpheresInfo[index].sphereId)}>
                    {icon}
                    <div className={style.name}>{citySpheresInfo[index].sphereName}</div>
                    <MenuNavItemArrowRightIcon className={style.nav} />
                </div>
                )
            }
        </div>
    </>

    const handleClickSphere = (index: number) => {
        setSphereId(index);
        setManagersListView(true);
    }

    return (<div className={style.managersMenu}>
        <BackMenuHeader title={'Руководители'} onClick={() => {
            // Навигация - к меню сфер в Руководители
            if (isManagersListView)
                setManagersListView(false);
            else // обратно в меню игры
                menuLevelSet(MenuLevelEmum.Level0);
        }} />

        {isManagersListView ? renderManagers(selectedSphereId)
            : renderSpheres()}
    </div>);
}

export default MenuLevel1Managers;
