import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { moneyToString } from "../../helpers";
import { DollarIcon, PeopleIcon, StarIcon } from "../../icons";
import intialData from "../../initialData";
import { scoreGameInit } from "../../redux/actions/gameApi/scoreGame";
import { getEstimatedIndicators, getGameId } from "../../redux/selectors";
import ModalComponent from "../ModalComponent";
import {
  Button,
  ProgressBar,
  ProgressBarColor,
  ProgressBarSize,
  ProgressBarTitleStyle,
  RowNameDeltaValue,
} from "../UiKit";
import style from "./style.module.scss";

interface IResultsGameOverModalProps {
  closeAction: () => void;
}

const warnHeaderTitle = "Игра закончена";
const warnLabel =
  "Уважаемый мэр, ваш срок управления городом закончился, теперь пришло время подвести итоги игры и выяснить как ваши действия повлияли на жителей города.";

const ResultsGameOverModal: React.FunctionComponent<IResultsGameOverModalProps> = (
  props
) => {
  const history = useHistory();
  const gameId = useSelector(getGameId);
  const indirectIndicators = useSelector(getEstimatedIndicators);
  const dispatch = useDispatch();

  const finish = () => {
    const gameScore = Math.round((indirectIndicators.urbanquality / 360) * 100);
    dispatch(scoreGameInit(gameId, gameScore));

    history.push("/profile");
  };

  return (
    <ModalComponent isHeader={false} height="40rem" isShowCloseIcon={false}>
      <div className={style.resultContent}>
        <h1 className={style.resultContentHeading}>{warnHeaderTitle}</h1>

        <div className={style.warnLabel}>{warnLabel}</div>
        <h1 className={style.resultsHeading}>Ваши результаты</h1>
        <div className={style.results}>
          <ProgressBar
            icon={<StarIcon />}
            color={ProgressBarColor.orange}
            size={ProgressBarSize.big}
            completedValuePercent={Math.round(
              (indirectIndicators.urbanquality / 360) * 100
            )}
            completedValueFact={Math.round(indirectIndicators.urbanquality)}
            title={"Качество городской среды"}
          />

          <ProgressBar
            icon={<PeopleIcon />}
            color={ProgressBarColor.blue}
            size={ProgressBarSize.small}
            titleStyle={ProgressBarTitleStyle.secondary}
            completedValueFact={Math.round(indirectIndicators.satisfaction)}
            completedValuePercent={Math.round(indirectIndicators.satisfaction)}
            title={"Удовлетворенность жителей"}
          />
        </div>
        <h2 className={style.indirectHeading}>Косвенные показатели</h2>
        <div className={style.indirectIndicators}>
          <div className={style.row}>
            <RowNameDeltaValue
              name={"Безопасность"}
              value={Math.round(indirectIndicators.safety)}
              delta={Math.round(
                indirectIndicators.safety - intialData.cityConstInfo.safetybase
              )}
            />
            <RowNameDeltaValue
              name={"Комфортность"}
              value={Math.round(indirectIndicators.comfort)}
              delta={Math.round(
                indirectIndicators.comfort -
                intialData.cityConstInfo.comfortbase
              )}
            />
            <RowNameDeltaValue
              name={"Экологичность и здоровье"}
              value={Math.round(indirectIndicators.environment)}
              delta={Math.round(
                indirectIndicators.environment -
                intialData.cityConstInfo.environmentbase
              )}
            />
            <RowNameDeltaValue
              name={"Одобрение государством"}
              value={Math.round(indirectIndicators.govscale)}
              delta={Math.round(
                indirectIndicators.govscale - intialData.cityConstInfo.govscale
              )}
            />
          </div>
          <div className={style.row}>
            <RowNameDeltaValue
              name={"Идентичность и разнообразие"}
              value={Math.round(indirectIndicators.identity)}
              delta={Math.round(
                indirectIndicators.identity -
                intialData.cityConstInfo.identitybase
              )}
            />
            <RowNameDeltaValue
              name={"Современность и актуальность"}
              value={Math.round(indirectIndicators.modernity)}
              delta={Math.round(
                indirectIndicators.modernity -
                intialData.cityConstInfo.modernitybase
              )}
            />
            <RowNameDeltaValue
              name={"Эффективность управления"}
              value={Math.round(indirectIndicators.control)}
              delta={Math.round(
                indirectIndicators.control -
                intialData.cityConstInfo.controlbase
              )}
            />
            <RowNameDeltaValue
              name={"Индекс инвестиционной привлекательности"}
              value={Math.round(indirectIndicators.businessscale)}
              delta={Math.round(
                indirectIndicators.businessscale -
                intialData.cityConstInfo.businessscale
              )}
            />
          </div>
        </div>

        <div className={style.budget}>
          <DollarIcon />
          <div className={style.name}>Бюджет:</div>
          <div className={style.value}>
            {moneyToString(indirectIndicators.money)}
          </div>
        </div>

        <div className={style.btns}>
          <Button isStretched={true} onClick={() => finish()}>
            Начать заново
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};
export default ResultsGameOverModal;
