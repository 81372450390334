import ModalComponent from '../ModalComponent';
import { Button } from '../UiKit';
import style from './style.module.scss';

const BusinessGrantModal: React.FunctionComponent<{ closeAction: () => void }> = ({ closeAction }) => <ModalComponent
    title={"Субсидия 500.000р"} showBackIcon={false} closeAction={closeAction} height={'15rem'} >
    <div className={style.resultContent}>
        <div className={style.warnLabel}>
            Поздравляем! Благодаря высокому значению уровня "Индекс инвестиционной привлекательности" вы получаете субсидию в бюджет города.
        </div>
        <div className={style.btns}>
            <Button isStretched={true} isBlue={false} onClick={closeAction}>Ок</Button>
        </div>
    </div>
</ModalComponent >

export default BusinessGrantModal;