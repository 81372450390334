import intialData from "../initialData";
import { INotification } from "../models";
const date1 = (new Date('08-13-2021')).getTime();
const event1 = intialData.randomEventsInfo[0];
export const notificaionMock: INotification = {
    id: 1,
    isRead: false,
    isPushClose: false,
    content: event1,
    created: date1
}