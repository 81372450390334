import { Action } from "redux";
import { Speed } from "../../../models";

export const TIMER_START = 'TIMER_START';
export const TIMER_STOP = 'TIMER_STOP';
export const TIMER_UPDATE = 'TIMER_UPDATE';
export const TIMER_SPEED = 'TIMER_SPEED';
export const TIMER_RETURN_PREVIOUS_SPEED = 'TIMER_RETURN_PREVIOUS_SPEED';

export interface ITimerSpeed extends Action {
    speed: Speed;
    previousSpeed: Speed;
}

export function timerStart() {
    return { type: TIMER_START };
}

export function timerStop() {
    return { type: TIMER_STOP };
}

export function timerUpdate() {
    return { type: TIMER_UPDATE };
}

export function timerSpeed(speed: Speed) {
    return { type: TIMER_SPEED, speed };
}

export function setPreviousTime() {
    return { type: TIMER_RETURN_PREVIOUS_SPEED };
}