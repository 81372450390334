import { useEffect, useState, useCallback } from "react";

export function useWindowSize(callback: () => void) {
    function getSize() {
      return {
        width: window.innerWidth,
        height:  window.innerHeight
      };
    }

    const handleResize = useCallback(() =>  {
      setWindowSize(getSize());
      callback();
    }, [callback])
  
    const [windowSize, setWindowSize] = useState(getSize);
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => { window.removeEventListener('resize', handleResize) };
    }, [handleResize]); 
  
    return windowSize;
  }