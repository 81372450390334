import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { ICityObjectParams, ISphereUpgrade } from "../models";
import { getSelectedObjects, getTimerCurDate } from "../redux/selectors";
import { isBuildPred, calcBuildingInfluence } from "./useMainGameLoop";

export function useCalcObjectParams(placeId: number, params: ICityObjectParams) {
    const { safetyXnYn, comfortXnYn, environmentXnYn, modernityXnYn, identityXnYn, controlXnYn } = params;

    const selectedObjects = useSelector(getSelectedObjects);
    const curDate = useSelector(getTimerCurDate);

    const buildObjects = selectedObjects.filter(so => isBuildPred(so, curDate));

    const so = buildObjects
        .filter(e => e.placeId === placeId)[0];

    if (!so) {
        return {
            safetyXnYn: 0,
            comfortXnYn: 0,
            environmentXnYn: 0,
            identityXnYn: 0,
            modernityXnYn: 0,
            controlXnYn: 0
        };
    }

    const isBuildingNeedToZeroInfluenceParams = calcBuildingInfluence(so, curDate);

    const upgrades = so.upgrades.filter(e => isUpgradeDone(e, curDate))
        .reduce((e, a) => ({
            safetyXnYnZn: e.safetyXnYnZn + a.safetyXnYnZn,
            comfortXnYnZn: e.comfortXnYnZn + a.comfortXnYnZn,
            environmentXnYnZn: e.environmentXnYnZn + a.environmentXnYnZn,
            identityXnYnZn: e.identityXnYnZn + a.identityXnYnZn,
            modernityXnYnZn: e.modernityXnYnZn + a.modernityXnYnZn,
            controlXnYnZn: e.controlXnYnZn + a.controlXnYnZn,
        } as ISphereUpgrade), createEmptySphereUpgrade());

    return {
        safetyXnYn: (safetyXnYn > 0 && isBuildingNeedToZeroInfluenceParams ? 0 : safetyXnYn) + upgrades.safetyXnYnZn,
        comfortXnYn: (comfortXnYn > 0 && isBuildingNeedToZeroInfluenceParams ? 0 : comfortXnYn) + upgrades.comfortXnYnZn,
        environmentXnYn: (environmentXnYn > 0 && isBuildingNeedToZeroInfluenceParams ? 0 : environmentXnYn) + upgrades.environmentXnYnZn,
        identityXnYn: (identityXnYn > 0 && isBuildingNeedToZeroInfluenceParams ? 0 : identityXnYn) + upgrades.identityXnYnZn,
        modernityXnYn: (modernityXnYn > 0 && isBuildingNeedToZeroInfluenceParams ? 0 : modernityXnYn) + upgrades.modernityXnYnZn,
        controlXnYn: (controlXnYn > 0 && isBuildingNeedToZeroInfluenceParams ? 0 : controlXnYn) + upgrades.controlXnYnZn
    };
}


export const createEmptySphereUpgrade = () => ({
    safetyXnYnZn: 0,
    comfortXnYnZn: 0,
    environmentXnYnZn: 0,
    identityXnYnZn: 0,
    modernityXnYnZn: 0,
    controlXnYnZn: 0,
}) as ISphereUpgrade;

export const createEmptyCityObjectParams = () => ({
    safetyXnYn: 0,
    comfortXnYn: 0,
    environmentXnYn: 0,
    identityXnYn: 0,
    modernityXnYn: 0,
    controlXnYn: 0
}) as ICityObjectParams;

export const isUpgradeDone = (up: ISphereUpgrade, curDate: number) => up.isDone && (dayjs(curDate).diff(up.startUpgradeTime, 'days') / up.timecostXnYnZn >= 1);
