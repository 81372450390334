import { IYearResults } from "../../../models";
import { Action } from "redux";

export const YEAR_RESULT_SET = 'YEAR_RESULT_SET';

export interface IYearResultsSet extends Action {
    yearResult: IYearResults;
}

export function yearResultSet(yearResult: IYearResults) {
    return { type: YEAR_RESULT_SET, yearResult};
}
