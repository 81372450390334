import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuLevelEmum } from '..';
import { createEmptyCityObjectParams, createEmptySphereUpgrade, isUpgradeDone } from '../../../hooks';
import { calcBuildingInfluence, isBuildPred } from '../../../hooks/useMainGameLoop';
import { HouseIcon, MenuNavItemArrowRightIcon, PlaygroundIcon, SkyscrapesIcon, TreesIcon } from '../../../icons';
import initialData from '../../../initialData';
import { ICityObjectParams, ISphereUpgrade } from '../../../models';
import { objectDoneModalShow } from '../../../redux/actions';
import { getSelectedObjects, getTimerCurDate } from '../../../redux/selectors';
import NameValueComponent from '../../BuildingInfo/NameValueComponent';
import CustomScrollbar from '../../UiKit/Scrollbar';
import BackMenuHeader from '../MenuHeader/backHeader';
import style from './style.module.scss';

const MenuLevel1Shperes: React.FunctionComponent<{ menuLevelSet: (level: MenuLevelEmum) => void }> = ({ menuLevelSet }) => {
    const dispatch = useDispatch();

    const [selectedSphereId, setSphereId] = useState(0);
    const [isManagersListView, setManagersListView] = useState(false);

    const { citySpheresInfo } = initialData;

    const selectedObjects = useSelector(getSelectedObjects);
    const curDate = useSelector(getTimerCurDate);

    const buildObjects = selectedObjects.filter(so => isBuildPred(so, curDate));

    const renderSpheresInfo = (key: number) => {
        const cityShp = citySpheresInfo[key - 1];
        const buildObjectsOfCurrentSphere = buildObjects.filter(e => e.sphereId === cityShp.sphereId);

        const upsDone = buildObjectsOfCurrentSphere
            .map((e) => e.upgrades.filter(e => isUpgradeDone(e, curDate)).length)
            .reduce((e, acc) => e + acc, 0);

        const updatesOfBuildObjects = buildObjectsOfCurrentSphere
            .map(e => e.upgrades)
            .flat()
            .filter(e => isUpgradeDone(e, curDate))
            .reduce((e, a) => ({
                safetyXnYnZn: e.safetyXnYnZn + a.safetyXnYnZn,
                comfortXnYnZn: e.comfortXnYnZn + a.comfortXnYnZn,
                environmentXnYnZn: e.environmentXnYnZn + a.environmentXnYnZn,
                identityXnYnZn: e.identityXnYnZn + a.identityXnYnZn,
                modernityXnYnZn: e.modernityXnYnZn + a.modernityXnYnZn,
                controlXnYnZn: e.controlXnYnZn + a.controlXnYnZn,
            } as ISphereUpgrade), createEmptySphereUpgrade());

        const buildObjectsParams = buildObjectsOfCurrentSphere
            .filter(so => !calcBuildingInfluence(so, curDate))
            .map(e => cityShp.objects.filter(_ => _.id === e.objectId))
            .flat()
            .map(e => e.params)
            .reduce((e, a) => ({
                safetyXnYn: e.safetyXnYn + a.safetyXnYn,
                comfortXnYn: e.comfortXnYn + a.comfortXnYn,
                environmentXnYn: e.environmentXnYn + a.environmentXnYn,
                identityXnYn: e.identityXnYn + a.identityXnYn,
                modernityXnYn: e.modernityXnYn + a.modernityXnYn,
                controlXnYn: e.controlXnYn + a.controlXnYn,
            } as ICityObjectParams), createEmptyCityObjectParams());

        const buildObjectsParamsWithScenarioLocation = buildObjectsOfCurrentSphere
            .filter(so => calcBuildingInfluence(so, curDate))
            .map(e => cityShp.objects.filter(_ => _.id === e.objectId))
            .flat()
            .map(e => e.params)
            .reduce((e, a) => ({
                safetyXnYn: (e.safetyXnYn + a.safetyXnYn > 0 ? 0 : e.safetyXnYn + a.safetyXnYn),
                comfortXnYn: (e.comfortXnYn + a.comfortXnYn > 0 ? 0 : e.comfortXnYn + a.comfortXnYn),
                environmentXnYn: (e.environmentXnYn + a.environmentXnYn > 0 ? 0 : e.environmentXnYn + a.environmentXnYn),
                identityXnYn: (e.identityXnYn + a.identityXnYn > 0 ? 0 : e.identityXnYn + a.identityXnYn),
                modernityXnYn: (e.modernityXnYn + a.modernityXnYn > 0 ? 0 : e.modernityXnYn + a.modernityXnYn),
                controlXnYn: (e.controlXnYn + a.controlXnYn > 0 ? 0 : e.controlXnYn + a.controlXnYn),
            } as ICityObjectParams), createEmptyCityObjectParams());

        const sumSphereParams = {
            safetyXnYn: buildObjectsParams.safetyXnYn + updatesOfBuildObjects.safetyXnYnZn + buildObjectsParamsWithScenarioLocation.safetyXnYn,
            comfortXnYn: buildObjectsParams.comfortXnYn + updatesOfBuildObjects.comfortXnYnZn + buildObjectsParamsWithScenarioLocation.comfortXnYn,
            environmentXnYn: buildObjectsParams.environmentXnYn + updatesOfBuildObjects.environmentXnYnZn + buildObjectsParamsWithScenarioLocation.environmentXnYn,
            identityXnYn: buildObjectsParams.identityXnYn + updatesOfBuildObjects.identityXnYnZn + buildObjectsParamsWithScenarioLocation.identityXnYn,
            modernityXnYn: buildObjectsParams.modernityXnYn + updatesOfBuildObjects.modernityXnYnZn + buildObjectsParamsWithScenarioLocation.modernityXnYn,
            controlXnYn: buildObjectsParams.controlXnYn + updatesOfBuildObjects.controlXnYnZn + buildObjectsParamsWithScenarioLocation.controlXnYn,
        };

        return <>
            <h1 className={style.spheareNameHeading}>{cityShp.sphereName}</h1>
            <CustomScrollbar styleCustom={{ height: '95%' }}>
                <div className={style.section}>
                    <h2 className={style.name}> Общее состояние сферы</h2>
                    <div className={style.sec_row}>
                        <div  >Построенно:</div>
                        <div  >{buildObjectsOfCurrentSphere.length}</div>
                    </div>
                    <div className={style.sec_row}>
                        <div  >Сделано улучшений:</div>
                        <div  >{upsDone}</div>
                    </div>
                </div>
                <div className={style.section}>
                    <h2 className={style.name}> Влияние сферы на общие показатели</h2>
                    <div >
                        <NameValueComponent name={"Безопасность"} value={sumSphereParams.safetyXnYn} />
                        <NameValueComponent name={"Комфортность"} value={sumSphereParams.comfortXnYn} />
                        <NameValueComponent name={"Экологичность и здоровье"} value={sumSphereParams.environmentXnYn} />
                        <NameValueComponent name={"Идентичность и разнообразие"} value={sumSphereParams.identityXnYn} />
                        <NameValueComponent name={"Современность и актуальность"} value={sumSphereParams.modernityXnYn} />
                        <NameValueComponent name={"Эффективность управления"} value={sumSphereParams.controlXnYn} />
                    </div>
                </div>
                <div className={style.section}>
                    <h2 className={style.name}>Построенные объекты</h2>
                    {buildObjectsOfCurrentSphere.map((e, index: number) => <div key={index}
                        className={style.sec_name} onClick={() => dispatch(objectDoneModalShow(e.objectId, e.placeId, cityShp.sphereId))}>
                        {initialData.citySpheresInfo
                            .find(_ => _.sphereId === cityShp.sphereId)
                            ?.objects
                            .find(_ => _.id === e.objectId)?.desc}
                    </div>)}
                </div>
            </CustomScrollbar>
        </>;
    }

    const renderSpheres = () => <>
        <div className={style.content}>
            {[<HouseIcon />, <TreesIcon />, <SkyscrapesIcon />, <PlaygroundIcon />]
                .map((icon, index: number) => <div key={index} className={style.row} onClick={() => handleClickSphere(citySpheresInfo[index].sphereId)}>
                    {icon}
                    <div className={style.name}>{citySpheresInfo[index].sphereName}</div>
                    <MenuNavItemArrowRightIcon className={style.nav} />
                </div>)
            }
        </div>
    </>

    const handleClickSphere = (index: number) => {
        setSphereId(index);
        setManagersListView(true);
    }

    return (<div className={style.managersMenu}>
        <BackMenuHeader title={'Сферы управления'} onClick={() => {
            // Навигация - к меню сфер в Руководители
            if (isManagersListView)
                setManagersListView(false);
            else // обратно в меню игры
                menuLevelSet(MenuLevelEmum.Level0);
        }} />

        {isManagersListView ? renderSpheresInfo(selectedSphereId)
            : renderSpheres()}
    </div>);
}

export default MenuLevel1Shperes;
