import { useSelector } from 'react-redux';
import { PeopleIcon } from '../../icons';
import { ResultsContentType } from '../../models';
import { getEstimatedIndicators } from '../../redux/selectors';
import ModalComponent from '../ModalComponent';
import { Button, ProgressBar, ProgressBarColor, ProgressBarSize, ProgressBarTitleStyle } from '../UiKit';
import style from './style.module.scss';


const warnHeaderTitle = 'Предупреждение';
const warnLabel = 'Уважаемый мэр, уровень удовлетворенности жителей слишком мал и если продолжится его снижение, то ваше правление закончится.';
const termProlongHeaderTitle = 'Срок управления городом продлен';
const termProlongHLabel = 'Срок управления городом был увеличен на 1 год, так как был достигнут высокий уровень удовлетворенности жителей';

interface IResultModalProps {
    contentType: ResultsContentType;
    closeAction: () => void;
}

const ResultModal: React.FunctionComponent<IResultModalProps> = (props) => {
    const { contentType, closeAction } = props;
    const estIndicator = useSelector(getEstimatedIndicators);

    return <ModalComponent title={contentType === 'ResultWarning' ? warnHeaderTitle : termProlongHeaderTitle} showBackIcon={false} closeAction={closeAction} height={'25rem'} >

        <div className={style.resultContent}>
            <div className={style.warnLabel}>
                {contentType === 'ResultWarning' ? warnLabel : termProlongHLabel}
            </div>
            <div className={style.happinesLevel}>
                <ProgressBar icon={<PeopleIcon />} color={ProgressBarColor.blue} size={ProgressBarSize.small}
                    titleStyle={ProgressBarTitleStyle.secondary}
                    completedValuePercent={estIndicator.satisfaction}
                    completedValueFact={Math.round(estIndicator.satisfaction)} title={'Удовлетворенность жителей'} />
            </div>
            <div className={style.btns}>
                <Button isStretched={true} isBlue={false} onClick={closeAction}>Закрыть</Button>
            </div>
        </div>
    </ModalComponent >
}
export default ResultModal;