import { LockIcon, NavArrowRightBlueIcon } from '../../icons';
import style from './style.module.scss';

export enum UpgradeLevelCardState { Active, Blocked };

interface IUpgradeLevelCardProps {
    cardState: UpgradeLevelCardState;
    level: number;
    description: string;
    onClick: (e: any) => void;
}

const UpgradeLevelCard: React.FunctionComponent<IUpgradeLevelCardProps> = (props) => {
    const { cardState, level, description, onClick } = props;

    return <div className={cardState === UpgradeLevelCardState.Active ? style.improvementCard : style.blockCard} onMouseDown={cardState === UpgradeLevelCardState.Active ? onClick : () => { }}>
        <div className={style.info}>
            <div className={style.level}> уровень {level}</div>
            <div className={cardState === UpgradeLevelCardState.Active ? style.description : style.blockedDescription}>{description}</div>
        </div>
        {cardState === UpgradeLevelCardState.Active ? <NavArrowRightBlueIcon /> : <LockIcon />}
    </div>
}
export default UpgradeLevelCard;