import React from 'react';
import { QuestionIcon } from '../../../icons';
import ToolTip from '../ToolTip';
import style from './style.module.scss';

interface IRowNameTooltipValueProps {
    name: string;
    value: number | string;
    tooltipInfo: string;
    nameStyle?: any;
    id: string;
    isMoney?: boolean
}

const RowNameTooltipValue = (props: IRowNameTooltipValueProps) => {
    const { name, value, tooltipInfo, nameStyle = null, id, isMoney } = props;

    return <div className={style.row}>
        {nameStyle !== null ? <div style={nameStyle} className={style.name}>{name}</div> : <div className={style.name}>{name}</div>}
        <div className={style.tooltip}>
            <ToolTip text={tooltipInfo} id={id}>
                <QuestionIcon />
            </ToolTip>

        </div>
        <div className={style.value}>{value}{isMoney && ` тыс. ₽`}</div>
    </div>;
}

export default RowNameTooltipValue;