import { Action } from "redux";
import { printErrorResponse } from "../../../api/common";
import { ISavedGame } from "../../../models";
import { IFailAction } from "../../../models/common";
import { GET_GAMES_DONE, GET_GAMES_FAIL, GET_GAMES_INIT, IGetGamesDoneAction } from "../../actions/gameApi/getGames";

export interface IState {
    games: ISavedGame[] | null;
    inProgress: boolean;
    error: string | null;
}

export const initial: IState = {
    games: null,
    inProgress: false,
    error: null,
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case GET_GAMES_INIT:
            return {
                ...state,
                games: null,
                inProgress: true,
                error: null
            };

        case GET_GAMES_DONE:

            const { games } = action as IGetGamesDoneAction;
            return {
                ...state,
                games,
                inProgress: false,
                error: null
            };

        case GET_GAMES_FAIL:
            const { error } = action as IFailAction;
            return {
                ...state,
                games: null,
                inProgress: true,
                error: printErrorResponse(error)
            };

        default:
            return state;
    }
}