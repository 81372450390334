import React from 'react';
import style from './style.module.scss';

interface IMenuItemProps {
    onClick?: (event: any) => void;
}

const MenuItem: React.FunctionComponent<IMenuItemProps> = (props)=> {

    const { onClick, children } = props;

    return <div className={style.menuItemContainer} onClick={onClick}>
        {children}
    </div>
}

export default MenuItem;