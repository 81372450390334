import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { termDaysFormat } from '../../helpers';
import { formatPrice } from '../../helpers/formatPrice';
import { IPhotoObject } from '../../images/buildingObjects';
import { modalHide, objectModalShow } from '../../redux/actions';
import { getSelectedObjects } from '../../redux/selectors';
import BuildingPhoto from '../BuildingPhoto';
import style from './style.module.scss';

interface IBuildingCardProps {
    placeId: number;
    objectId: string;
    photo: IPhotoObject;
    name: string;
    price: number;
    deadline: number;
    desc: string;
    contextMenuElementId: string;
    sphereId: number;
    previousModalId: number;
}

const BuildingCard: React.FunctionComponent<IBuildingCardProps> = (props) => {

    const dispatch = useDispatch();

    const selectedObjects = useSelector(getSelectedObjects);

    const [hover, setHover] = useState<boolean>(false);

    const { objectId, photo, name, price, deadline, placeId, desc, contextMenuElementId, sphereId, previousModalId } = props;

    const objectIsSelected = selectedObjects.findIndex(_ => _.objectId === objectId && _.placeId === placeId);

    const openObjectModal = (e: any) => {
        if (objectIsSelected === -1) {
            dispatch(objectModalShow(objectId, placeId, contextMenuElementId, sphereId));
            dispatch(modalHide(previousModalId))
        }
        e.stopPropagation();
    }

    return <div className={style.buildingCard} onMouseOver={() => { setHover(true) }} onMouseOut={() => setHover(false)} onMouseDown={openObjectModal}>
        <div className={style.photo}>
            {hover ? <BuildingPhoto photo={photo.imgHover} /> : <BuildingPhoto photo={photo.img} />}
        </div>

        <div className={style.BuildingName}>
            {name}
        </div>
        <div className={style.description}>
            {desc}
        </div>

        <div className={style.row}>
            <div className={style.column_name}>
                <div>Стоимость:</div>
                <div>Срок:</div>
            </div>

            <div className={style.column_value}>
                <div>{formatPrice(price)} тыс &#8381;</div>
                <div>{termDaysFormat(deadline)}</div>
            </div>
        </div>


    </div>
}
export default BuildingCard;