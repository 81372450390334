import { Action } from "redux";
import { notificaionMock } from "../../../mocks";
import { IGame, IHiredManager } from "../../../models";
import * as GAME_ACTIONS from "../../actions/game";
import { GAME_CLOSE_PUSH_NOTIFICATION, SET_MINUS_MONEY_AMOUNT, HIDE_CONTEXT_MENU, SHOW_CONTEXT_MENU } from "../../actions/game";
import * as cityInfo from './cityInfo';
import * as goToCityPlace from './goToCityPlace';
import * as learning from './learning';
import * as learningProgress from './learningProgress';
import * as notifications from './notifications';
import * as randomEventsResults from './randomEventsResults';
import * as selectObject from './selectObject';
import * as timer from './timer';
import * as yearResults from './yearResults';
export const initial: IGame = {
    id: 0,
    stage: 'intro',
    timer: timer.initial,
    learningCompleted: false,
    learningProgress: learningProgress.initial,
    showContextMenuLearning: false,
    finished: false,
    currentCityPlacePoint: goToCityPlace.initial,
    selectedObjects: selectObject.initial,
    yearsResults: yearResults.initial,
    estimatedIndicators: {
        money: 0,
        urbanquality: 0,
        safety: 0,
        comfort: 0,
        environment: 0,
        identity: 0,
        modernity: 0,
        control: 0,
        percitizen: 0,
        satisfaction: 0,
        score: 0,
        svobodniy: 0,
        sumMoneyDplusPerKvartal: 0,
        deficitroof2: 0,
        deficitroof3: 0,
        moneyDplus: 0,
        moneyDminus: 0,
        businessscale: 0,
        govscale: 0
    },
    randomEventsResults: [],
    hiredManagers: [],
    notifications: notifications.initial,
    learningNotifications: [notificaionMock]
}

export const reducer = (state: IGame = initial, action: Action): IGame => {
    switch (action.type) {
        case GAME_ACTIONS.MAYOR_TERM_CHANGE:
            return {
                ...state,
                cityInfo: cityInfo.reducer(state.cityInfo, action)
            };

        case GAME_ACTIONS.GAME_INIT:
            const { game } = action as GAME_ACTIONS.IGameInitAction;

            return {
                ...game
            };

        case GAME_ACTIONS.GAME_STAGE:
            const { stage } = action as GAME_ACTIONS.IGameStageAction;

            return {
                ...state,
                stage: stage
            };

        case GAME_ACTIONS.GAME_FINISHED:
            return {
                ...state,
                finished: true
            };

        case GAME_ACTIONS.GAME_SET_ESTIMATED_INDICATORS:

            const { estimatedIndicators } = action as GAME_ACTIONS.ISetEstimatedIndicatorsAction;
            return {
                ...state,
                estimatedIndicators: { ...estimatedIndicators }
            };

        case SET_MINUS_MONEY_AMOUNT:
            const { amount } = action as GAME_ACTIONS.ISetMinusMoneyAmount;
            return {
                ...state,
                estimatedIndicators: { ...state.estimatedIndicators, money: state.estimatedIndicators.money - amount }
            };

        case GAME_ACTIONS.TIMER_START:
        case GAME_ACTIONS.TIMER_STOP:
        case GAME_ACTIONS.TIMER_UPDATE:
        case GAME_ACTIONS.TIMER_RETURN_PREVIOUS_SPEED:
        case GAME_ACTIONS.TIMER_SPEED:

            if (state.finished)
                return { ...state };

            return {
                ...state,
                timer: timer.reducer(state.timer, action)
            };

        case GAME_ACTIONS.GO_TO_PLACE_INIT:
        case GAME_ACTIONS.GO_TO_PLACE_DONE:

            return {
                ...state,
                currentCityPlacePoint: goToCityPlace.reducer(state.currentCityPlacePoint, action)
            };

        case GAME_ACTIONS.GAME_SET_RANDOM_EVENT_RESULT:

            return {
                ...state,
                randomEventsResults: randomEventsResults.reducer(state.randomEventsResults, action)
            };

        case GAME_ACTIONS.GAME_NEW_NOTIFICATION:
        case GAME_ACTIONS.GAME_READ_NOTIFICATION:
        case GAME_CLOSE_PUSH_NOTIFICATION:
            return {
                ...state,
                notifications: notifications.reducer(state.notifications, action)
            };

        case GAME_ACTIONS.GAME_BUILD_OBJECT:
            const buildObject = action as GAME_ACTIONS.IGameBuildObjectAction;

            return {
                ...state,
                estimatedIndicators: {
                    ...state.estimatedIndicators,
                    money: state.estimatedIndicators.money - buildObject.buildCost // Учитывае стомость стройки
                },
                selectedObjects: selectObject.reducer(state.selectedObjects, action)
            };

        case GAME_ACTIONS.GAME_UPGRADE_OBJECT:
            const upgObject = action as GAME_ACTIONS.IGameUpgradeObjectAction;
            return {
                ...state,
                estimatedIndicators: {
                    ...state.estimatedIndicators,
                    money: state.estimatedIndicators.money - upgObject.upgrade.costXnYnZn // Учитывае стомость обновления
                },
                selectedObjects: selectObject.reducer(state.selectedObjects, action)
            };

        case GAME_ACTIONS.GAME_DESTROY_OBJECT:
            const deconstructObject = action as GAME_ACTIONS.IGameDestroyObjectAction;

            return {
                ...state,
                estimatedIndicators: {
                    ...state.estimatedIndicators,
                    money: state.estimatedIndicators.money - deconstructObject.deconstructCost // Учитывае стомость cноса
                },
                selectedObjects: selectObject.reducer(state.selectedObjects, action),

            };

        case GAME_ACTIONS.REMOVE_BUILDING_FROM_MAP:
            return {
                ...state,
                selectedObjects: selectObject.reducer(state.selectedObjects, action),

            };

        case GAME_ACTIONS.HIRE_MANAGER:

            const hiredManager = action as GAME_ACTIONS.IHireManagerAction;
            let alreadyHiredManager: IHiredManager = state.hiredManagers.filter(e => e.managerId === hiredManager.manager.id)[0];
            // 1. если руководитель сферы не нанят ни на одно здание
            if (!alreadyHiredManager) {
                alreadyHiredManager = {
                    objectIds: [hiredManager.objectInfo],
                    managerId: hiredManager.manager.id,
                    manager: { ...hiredManager.manager },
                    hireCoef: {
                        safetyCoef: hiredManager.hireRndCoef[0],
                        comfortCoef: hiredManager.hireRndCoef[1],
                        environmentCoef: hiredManager.hireRndCoef[2],
                        identityCoef: hiredManager.hireRndCoef[3],
                        modernityCoef: hiredManager.hireRndCoef[4],
                        controlCoef: hiredManager.hireRndCoef[5],
                    }
                };
                return {
                    ...state,
                    estimatedIndicators: {
                        ...state.estimatedIndicators,
                        money: state.estimatedIndicators.money - hiredManager.manager.managerhirecostXnYn // Учитывае стомость найма руководителя сферы
                    },
                    hiredManagers: [...state.hiredManagers, alreadyHiredManager]
                };

            } else {
                // 2. если руководитель сферы уже нанят, то добавить в его objectsids текущее здание
                // проверить что уже нанят на это здание
                const isHiredAlready = alreadyHiredManager.objectIds.filter(e => e.objectId === hiredManager.objectInfo.objectId
                    && e.placeId === hiredManager.objectInfo.placeId)[0];
                if (!isHiredAlready) {
                    alreadyHiredManager.objectIds.push(hiredManager.objectInfo);
                }
                return {
                    ...state,
                    estimatedIndicators: {
                        ...state.estimatedIndicators,
                        money: state.estimatedIndicators.money - hiredManager.manager.managerhirecostXnYn // Учитывае стомость найма руководителя сферы
                    },
                    hiredManagers: [...state.hiredManagers.map(e => {
                        if (e.managerId === alreadyHiredManager.managerId) {
                            return alreadyHiredManager;
                        }
                        return e;
                    }),]
                };
            }

        case GAME_ACTIONS.FIRE_MANAGER: {

            const firedManager = action as GAME_ACTIONS.IFireManagerAction;
            // найти руководителя сферы и удалить из списка зданий текущее objectId
            let alreadyHiredManager: IHiredManager = state.hiredManagers.filter(e => e.managerId === firedManager.managerId)[0];
            const index = alreadyHiredManager.objectIds.indexOf(
                alreadyHiredManager.objectIds.filter(e => e.objectId === firedManager.objectInfo.objectId &&
                    e.placeId === firedManager.objectInfo.placeId)[0]);
            if (index > -1) {
                alreadyHiredManager.objectIds.splice(index, 1);
            }

            return {
                ...state,
                hiredManagers: [...state.hiredManagers.filter(e => e.objectIds.length !== 0)]
            };
        }
        case GAME_ACTIONS.YEAR_RESULT_SET:

            return {
                ...state,
                yearsResults: yearResults.reducer(state.yearsResults, action)
            };

        case GAME_ACTIONS.LEARNING_COMPLEATED:
            return {
                ...state,
                learningCompleted: learning.reducer(state.learningCompleted, action)
            };

        case SHOW_CONTEXT_MENU:
            return { ...state, showContextMenuLearning: true }
        case HIDE_CONTEXT_MENU:
            return { ...state, showContextMenuLearning: false }

        case GAME_ACTIONS.LEARNING_PROGRESS_INIT:
        case GAME_ACTIONS.LEARNING_PROGRESS_NEXT:
        case GAME_ACTIONS.LEARNING_PROGRESS_START:
        case GAME_ACTIONS.LEARNING_PROGRESS_PAUSE:
            return {
                ...state,
                learningProgress: learningProgress.reducer(state.learningProgress, action)
            };

        default:
            return state;
    }
}