import { Action } from "redux";
import { IModalDialog } from "../../models";
import {
  ISphereObjectsModalShowAction,
  IObjectModalShowAction,
  MODAL_HIDE,
  SPHERE_OBJECTS_MODAL_SHOW,
  OBJECT_MODAL_SHOW,
  OBJECT_DONE_MODAL_SHOW,
  IObjectDoneModalShowAction,
  OBJECT_DONE_UPGRADE_MODAL_SHOW,
  OBJECT_DONE_UPGRADE_LEVELS_MODAL_SHOW,
  IObjectDoneUpgradeModalShowAction,
  SHOWPLACES_MODAL_SHOW,
  IShowplaceModalShowAction,
  OBJECT_DECONSTRUCT_MODAL_SHOW,
  RESULT_MODAL_SHOW,
  IResultnModalShowAction,
  GAMEOVER_MODAL_SHOW,
  GAMEOVER_GOV_SCALE_MODAL_SHOW,
  ON_UPGRADE_MODAL_SHOW,
  ON_DECONSTRUCT_MODAL_SHOW,
  ON_CONSTRUCT_MODAL_SHOW,
  MONEY_DEFICIT_MODAL_SHOW,
  GRANT_FOR_BUSINESS_MODAL_SHOW,
  KVARTAL_MODAL_SHOW,
  IKvartalIncomeModalShowAction,
  INTERVIEW_MODAL_SHOW,
  IHideModalAction,
} from "../actions/dialogs";

export const actionWithoutTypeProp = ({ type, ...rest }: any) => rest;

export const initial_FOR_ARRAY: IModalDialog[] = [];

const generateModalId = () => Date.now() + Math.random() * 10;

export const reducer = (state: any = initial_FOR_ARRAY, action: Action): IModalDialog[] => {
  switch (action.type) {
    case GRANT_FOR_BUSINESS_MODAL_SHOW: {
      return [
        ...state,
        {
          id: generateModalId(),
          dialogType: 'GrantForBusiness'
        }
      ];
    }

    case INTERVIEW_MODAL_SHOW: {
      return [
        ...state,
        {
          id: generateModalId(),
          dialogType: 'Interview'
        }];
    }

    case SPHERE_OBJECTS_MODAL_SHOW: {
      const {
        sphereId,
        contextElementId,
        placeId,
      } = action as ISphereObjectsModalShowAction;
      return [
        ...state,
        {
          id: generateModalId(),
          sphereId: sphereId,
          dialogType: "SphereObjectsModal",
          contextMenuElementId: contextElementId,
          placeId
        }
      ]; //OBJECT_MODAL_SHOW
    }

    case SHOWPLACES_MODAL_SHOW: {
      const { id } = action as IShowplaceModalShowAction;
      return [
        ...state, {
          id: generateModalId(),
          dialogType: "ShowpalceModal",
          showPlaceId: id,
        }];
    }

    case OBJECT_MODAL_SHOW: {
      const obj = action as IObjectModalShowAction;
      return [
        ...state,
        {
          id: generateModalId(),
          dialogType: "ObjectModal",
          objectId: obj.objectId,
          placeId: obj.placeId,
          contextMenuElementId: obj.contextMenuElementId,
          sphereId: obj.sphereId

        }
      ];
    }
    case KVARTAL_MODAL_SHOW: {
      const { kvartalIncome } = action as IKvartalIncomeModalShowAction;

      return [
        ...state,
        {
          id: generateModalId(),
          kvartalIncome,
          dialogType: "KvartalModal",
        }
      ]
    }

    case ON_UPGRADE_MODAL_SHOW: {
      const upgradeModal = action as IResultnModalShowAction;
      return [
        ...state, {
          id: generateModalId(),
          objectId: upgradeModal.objectId,
          sphereId: upgradeModal.sphereId,
          dialogType: 'OnUpgradeDoneModal',
        }];
    }
    case ON_DECONSTRUCT_MODAL_SHOW: {
      const deconstructModal = action as IResultnModalShowAction;
      return [
        ...state, {
          id: generateModalId(),
          objectId: deconstructModal.objectId,
          sphereId: deconstructModal.sphereId,
          dialogType: 'OnDeconstructModal',
        }];
    }

    case ON_CONSTRUCT_MODAL_SHOW: {
      const constructModal = action as IResultnModalShowAction;
      return [
        ...state, {
          id: generateModalId(),
          objectId: constructModal.objectId,
          sphereId: constructModal.sphereId,
          dialogType: 'OnConstructModal',
        }];
    }

    case OBJECT_DONE_MODAL_SHOW: {
      const objdone = action as IObjectDoneModalShowAction;
      return [
        ...state, {
          id: generateModalId(),
          dialogType: "ObjectDoneModal",
          objectId: objdone.objectId,
          placeId: objdone.placeId,
          sphereId: objdone.sphereId
        }];
    }

    case OBJECT_DECONSTRUCT_MODAL_SHOW:
      return [
        ...state, {
          id: generateModalId(),
          dialogType: "DeconstructObjectModal",
          ...actionWithoutTypeProp(action as IObjectDoneModalShowAction)
        }];

    case OBJECT_DONE_UPGRADE_LEVELS_MODAL_SHOW: {
      const objugrlevel = action as IObjectDoneUpgradeModalShowAction;

      return [
        ...state, {
          id: generateModalId(),
          dialogType: "ObjectDoneUpgradeLevelsModal",
          objectId: objugrlevel.objectId,
          placeId: objugrlevel.placeId,
          sphereId: objugrlevel.sphereId
        }];
    }

    case OBJECT_DONE_UPGRADE_MODAL_SHOW: {
      const objugr = action as IObjectDoneUpgradeModalShowAction;

      return [
        ...state, {
          id: generateModalId(),
          dialogType: "ObjectDoneUpgradeModal",
          objectId: objugr.objectId,
          placeId: objugr.placeId,
          sphereId: objugr.sphereId,
          upgradeLevel: objugr.level
        }];
    }

    case RESULT_MODAL_SHOW: {
      const { resultType } = action as IResultnModalShowAction;
      return [
        ...state,
        {
          id: generateModalId(),
          dialogType: resultType,
        }];
    }

    case GAMEOVER_MODAL_SHOW: {
      return [
        ...state, {
          id: generateModalId(),
          dialogType: 'GameOver'
        }];
    }

    case GAMEOVER_GOV_SCALE_MODAL_SHOW: {
      return [
        ...state,
        {
          id: generateModalId(),
          dialogType: 'GameOverGovScale'
        }
      ];
    }

    case MONEY_DEFICIT_MODAL_SHOW: {
      const moneyDeficit = action as any;
      return [
        ...state, {
          id: generateModalId(),
          dialogType: 'MoneyDeficit',
          deficitType: moneyDeficit.moneyDeficit
        }];
    }

    case MODAL_HIDE: {
      const { id } = action as IHideModalAction;
      if (id) {
        return state.filter((e: IModalDialog) => e.id !== id)
      }
      return state;
    }

    default:
      return state;
  }
};
