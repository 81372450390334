import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';

export enum ProgressBarColor {
    blue,
    orange,
    red,
    green,
    yellow,
    aquamarine
}

export enum ProgressBarSize {
    big,
    small
}

export enum ProgressBarTitleStyle {
    primary,
    secondary
}

interface IProgressBarProps {
    completedValuePercent: number;
    color: ProgressBarColor;
    size: ProgressBarSize;
    icon?: React.ReactNode;
    completedValueFact?: number;
    title?: string;
    titleStyle?: ProgressBarTitleStyle;
    tooltipEl?: React.ReactElement;
}

const ProgressBar: React.FunctionComponent<IProgressBarProps> = (props) => {

    const { tooltipEl, completedValuePercent, color, size, icon, completedValueFact, title, titleStyle = ProgressBarTitleStyle.primary } = props;

    let barStyle = '';
    let completedStyle = '';
    if (color === ProgressBarColor.orange) {
        completedStyle = style.completedOrangeSmall;
    }
    else if (color === ProgressBarColor.red) {
        completedStyle = style.completedRedSmall;
    }
    else if (color === ProgressBarColor.blue) {
        completedStyle = style.completedBlueSmall;
    }
    else if (color === ProgressBarColor.green) {
        completedStyle = style.completedGreenSmall;
    }
    else if (color === ProgressBarColor.yellow) {
        completedStyle = style.completedYellowSmall;
    }

    if (size === ProgressBarSize.big) {
        barStyle = style.barBig;
        completedStyle = cn(completedStyle, style.completedBig);
    }
    else {
        barStyle = style.barSmall;
        completedStyle = cn(completedStyle, style.completedSmall);
    }

    return <div className={style.container}>
        <div className={style.titleRow}>
            <div className={style.title}>
                {icon}
                <div className={cn(style.titleName, titleStyle === ProgressBarTitleStyle.secondary ? style.titleNameSecondary : '')}>{title}</div>
            </div>

            {tooltipEl !== null ? <div className={style.tooltip}>{tooltipEl}</div> : null}

            <div className={style.titleValue}>{completedValueFact}</div>
        </div>

        <div className={barStyle}>
            <span className={completedStyle} style={{ width: `${completedValuePercent}%` }} />
        </div>
    </div>
}

export default ProgressBar;