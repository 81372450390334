import { ButtonArrowRightIcon } from '../../../icons';
import { Button } from '../../UiKit';
import style from './style.module.scss';

interface IDetailsButton {
    onClick: () => void;
}
const DetailsButton = (props: IDetailsButton) => {
    const { onClick } = props;
    
    return <Button onClick={onClick} >
        <div className={style.detailsButtonContainer}>
            <div>ДЕТАЛИ</div>
            <ButtonArrowRightIcon />
        </div>
    </Button>;
}

export default DetailsButton;