import { useDispatch } from 'react-redux';
import initialData from '../../initialData';
import { setMinusMoneyAmount } from '../../redux/actions';
import ModalComponent from '../ModalComponent';
import { Button } from '../UiKit';
import style from './style.module.scss';

const InterviewModal: React.FunctionComponent<{ closeAction: () => void }> = ({ closeAction }) => {
    const disptatch = useDispatch();
    const { quizprice } = initialData.cityConstInfo;
    return <ModalComponent
        title={"Ежегодный опрос"} showBackIcon={false} closeAction={closeAction} height={'16rem'} >
        <div className={style.resultContent}>
            <div className={style.warnLabel}>
                Мэр, мы можем подготовить для вас очередной опрос мнения жителей о развитии сфер за прошедший год. Однако за это придется заплатить {quizprice} тыс. р.
            </div>
            <div className={style.btns}>
                <Button isStretched={true} isBlue={true}
                    onClick={() => {
                        disptatch(setMinusMoneyAmount(quizprice));
                        closeAction();
                    }}>Согласиться</Button>
                <Button isStretched={true} isBlue={false} onClick={closeAction}>Отказаться</Button>
            </div>
        </div>
    </ModalComponent >
}

export default InterviewModal;