
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { MenuLevel0 } from './menuLevel0';
import MenuLevel1Buildings from './MenuLevel1_Buildings';
import NotificationMenu from './NotificationMenu';
import MenuLevel1Managers from './MenuLevel1_Managers';
import DetailsMenu from './DetailsMenu';
import style from './style.module.scss';
import { useSelector } from 'react-redux';
import { getMenuLevel } from '../../redux/selectors';
import MenuLevel1Shperes from './MenuLevel1_Shperes';

export enum MenuLevelEmum {
    Level0,
    Level1_Buildings,
    Notifications,
    Details,
    Managers,
    Spheres
}

export interface IMenuLevelProps {
    menuLevelSet: (level: MenuLevelEmum) => void;
}

interface IGameMenuProps {
    dialog: React.ReactNode | null;
}

const GameMenu: React.FunctionComponent<IGameMenuProps> = ({ dialog }) => {

    const menuLevelState = useSelector(getMenuLevel);
    const [menuLevel, setMenuLevel] = useState<MenuLevelEmum>(menuLevelState.menu ?? MenuLevelEmum.Level0);

    useEffect(() => {
        setMenuLevel(menuLevelState.menu);
    }, [menuLevelState]);

    const selectMenu = () => {

        if (menuLevel === MenuLevelEmum.Level1_Buildings)
            return <MenuLevel1Buildings menuLevelSet={setMenuLevel} />;

        if (menuLevel === MenuLevelEmum.Notifications) {
            return (<NotificationMenu menuLevelSet={setMenuLevel} />);
        }
        if (menuLevel === MenuLevelEmum.Managers) {
            return (<MenuLevel1Managers menuLevelSet={setMenuLevel} />);
        }
        if (menuLevel === MenuLevelEmum.Spheres) {
            return (<MenuLevel1Shperes menuLevelSet={setMenuLevel} />);
        }
        if (menuLevel === MenuLevelEmum.Details) {
            return (<DetailsMenu menuLevelSet={setMenuLevel} />);
        }
        return <MenuLevel0 menuLevelSet={setMenuLevel} />;
    }
    return <div className={cn(style.menuDialogContainer, dialog === false ? style.menuDialogContainerFitContent : '')}>

        <div className={style.menuContainer}>
            {selectMenu()}
        </div>

        {dialog}

    </div>
}

export const NotificationDialog: React.FunctionComponent<IGameMenuProps> = (props) => {

    const { dialog } = props;


    return <div className={style.dialog}>
        {dialog}
    </div>
}

export default GameMenu;