import dayjs from 'dayjs';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameUpgradeObject, onUpgradeModalShow } from "../../redux/actions";
import { getSelectedObjects, getTimerCurDate } from "../../redux/selectors";

export default function UpgradeHandler() {
    const curDate = useSelector(getTimerCurDate);
    const selectedObjects = useSelector(getSelectedObjects); 
    const dispatch = useDispatch();

    useEffect(() => {
        const allUpgrades = selectedObjects
            .map(e => e.upgrades.map(u => {return {objectId: e.objectId, sphereId: e.sphereId, placeId: e.placeId, upgrade:{ ...u }}}))
            .flat().filter(e => e.upgrade.isDone === false && e.upgrade.startUpgradeTime);
        if (allUpgrades && allUpgrades.length > 0) {
            allUpgrades.forEach(e => {
                const isUpgradeDone = dayjs(curDate).diff(e.upgrade.startUpgradeTime, 'days') / e.upgrade.timecostXnYnZn >= 1;

                if (isUpgradeDone && e.placeId) {               
                    dispatch(gameUpgradeObject(e.objectId, e.placeId, {...e.upgrade, isDone: true}));
                    dispatch(onUpgradeModalShow(e.objectId, e.sphereId));
                }
            })
        }
    }, [curDate, selectedObjects, dispatch]);
    return <div />;
}