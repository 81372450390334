import style from "./style.module.scss";
import cn from "classnames";

interface IStatusValueProps {
  statusName: string;
  value: string | number | any;
  selectableValue?: boolean;
  onSelect?: () => void;
  widthValue?: number;
}
const StatusValue: React.FunctionComponent<IStatusValueProps> = (props) => {
  const {
    statusName,
    value,
    selectableValue = false,
    onSelect,
    widthValue,
  } = props;

  return (
    <div className={style.statusValueContainer}>
      <div className={style.statusName}>{statusName}</div>
      <div
        className={cn(
          style.statusValue,
          selectableValue && typeof value !== "object"
            ? style.selectableValue
            : ""
        )}
        style={{ width: widthValue }}
        onMouseDown={onSelect}
      >
        {value}
      </div>
    </div>
  );
};

export default StatusValue;
