import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTimerCurDate } from "../../redux/selectors";
import { store } from "../../store/configureStore";
import dayjs from 'dayjs';
import { newNotification } from "../../redux/actions";
import intialData from "../../initialData";
import { shuffle } from "../../helpers";
import { IRandomEvent, IRandomEventAction } from "../../models";

const randomeventP = 0.5;
const randomeventlag = 30;

const getEvent = (usedIDs: number[]): IRandomEvent | undefined => {
    const unused = shuffle(intialData.randomEventsInfo).filter(_ => usedIDs.indexOf(_.id) === -1);
    if (unused.length > 0) return unused[0];

    return undefined;
}

const getRepeatedEvent = (usedIDs: number[]): IRandomEvent | undefined => {
    const unused = shuffle(
        [...intialData.randomEventsInfo]

    )
        .filter(_ => usedIDs.indexOf(_.id) === -1);
    if (unused.length > 0) return unused[0];

    return undefined;
}

export default function RandomEventsGenerator() {

    const curDate = useSelector(getTimerCurDate);

    const dispatch = useDispatch();

    useEffect(() => {
        const { notifications, timer } = store.getState().game;

        let lastEvent = notifications.length > 0 ? notifications[0] : undefined;

        const { daysFromStart } = timer;
        const daysOfRandomevent = daysFromStart % randomeventlag;

        if (0 <= daysOfRandomevent && daysOfRandomevent <= 1
            && (!lastEvent || dayjs(curDate).diff(lastEvent.created, 'days') >= randomeventlag)) {

            const isFireRandomEvent = Math.random() >= randomeventP;

            console.log(`Random event conditions: each ${randomeventlag}, daysFromStart ${daysFromStart}, coeff: ${daysOfRandomevent}, isFireEvent ${isFireRandomEvent}`);

            if (isFireRandomEvent) {
                const firedRepeatableNotifications = notifications.filter(e => e.content.repeatable);
                if (firedRepeatableNotifications.length > 0) // для повторяющихся событий
                {
                    const repIDs = firedRepeatableNotifications.filter(e => dayjs(curDate).diff(e.created, 'days') <= (e.content.timeToRepeat ?? 365))
                        .map(_ => _.id)

                    const usedIDs = notifications.filter(e => !e.content.repeatable).map(_ => _.id);
                    const event = getRepeatedEvent([...repIDs, ...usedIDs]);

                    if (event) {
                        let evnt = event;
                        if (event.repeatable) {
                            // поменяли cтоимость выбора опции при повторении события (randomeventcost -> repeateRandomEventCost)

                            const replaceRepeatRandomEventCost = (e: IRandomEventAction): IRandomEventAction => ({ ...e, randomeventcost: (e.repeatRandomEventCost ?? e.randomeventcost) });
                            evnt = { ...event, actions: event.actions.map(replaceRepeatRandomEventCost) };
                        }
                        dispatch(newNotification({ id: event.id, isRead: false, isPushClose: false, content: evnt, created: curDate }));
                    }

                } else { // Если еще не выстреливали случайные ПОВТОРЯЮЩИЕСЯ события
                    const usedIDs = notifications.map(_ => _.id);
                    const event = getEvent(usedIDs);
                    if (event) {
                        dispatch(newNotification({ id: event.id, isRead: false, isPushClose: false, content: event, created: curDate }));
                    }
                }
            }

        }

    }, [curDate, dispatch]);

    return <div />;
}