import { ICityBildingPlacePoint, ICityObject } from '../../../../models';
import BuildingPlacePin from '../BuildingPlacePin';
import style from './style.module.scss';
import emptyPlace from '../../../../images/empty.png';
import { useDispatch, useSelector } from 'react-redux';
import { goToCityPlacePointInit, objectDoneModalShow } from '../../../../redux/actions';
import LockedKvadrant from '../../../LockedKvadrant';
import { getSelectedObject } from '../../../../redux/selectors';
import { IState } from '../../../../redux/reducers';
import { Rating } from '../../../UiKit';
import { getUpgradeLevel } from '../../../../helpers';

interface IBuildingPlaceProps {
    place: ICityBildingPlacePoint | null;
    building: ICityObject | null;
    sphereId: number;
    sphereName: string;
    buildingProgress: number;
}

const BuildingPlace: React.FunctionComponent<IBuildingPlaceProps> = (props) => {

    const dispatch = useDispatch();
    const { place, building, sphereName, sphereId, buildingProgress } = props;

    const getTitle = () => {
        if (place != null) {
            return <>
                <div className={style.mainTitle}>Квадрант {place.id}</div>
                <div className={style.subTitle}>Свободное пространство</div>
            </>;
        }
    }

    const showObjectInfo = (e: any) => {
        if (building && buildingProgress >= 1) {
            dispatch(objectDoneModalShow(building.id, place ? place.id : -1, sphereId));
            e.stopPropagation();
        }
    }
    const objectSelected = useSelector(s => getSelectedObject(s as IState, place?.id ?? -1, building?.id ?? ""));
    let buildinUpgradeLevel = getUpgradeLevel(objectSelected);

    const displayObject = () => {

        if (buildingProgress >= 1 && building && building.img) {
            return <div className={style.objInfo}>
                <div className={style.rating}>
                    <Rating value={buildinUpgradeLevel} />
                </div>
                <img alt="" src={building.img} onMouseDown={showObjectInfo} />

            </div>;
        }

        return <div className={style.lockedEmptySpace}>
            <div style={{ transform: 'scale(0.8)', position: 'absolute' }}>
                <LockedKvadrant percent={buildingProgress * 100} placeId={(place && (place?.id ?? -1)) ?? -1} objectId={objectSelected?.objectId} sphereId={objectSelected?.sphereId} />
            </div>

            <img alt="" src={emptyPlace} />
        </div>;
    }

    return <div>
        <div className={style.buildingPlaceContainer}>
            {building === null || building.img === null ? <img alt="" src={emptyPlace} /> : displayObject()}

            {place && <BuildingPlacePin onClick={() => dispatch(goToCityPlacePointInit(place))} placeId={place.id} />}
        </div>

        {building === null ?
            <div className={style.emptySpaceTitle}>
                {getTitle()}
            </div> :
            <div className={style.buildingTitle}>
                <div className={style.mainTitle}>{building.name}</div>
                {building.desc !== sphereName && <div className={style.subTitle}>{sphereName}</div>}
            </div>}

    </div>
}

export default BuildingPlace;