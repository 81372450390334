import { Action } from "redux";
import { MenuLevelEmum } from "../../components/GameMenu";
import { CLEAR_MENU_STATE_ON_HIRE_MANAGER, IClearMenuAction, IShowMenuAction, SHOW_MENU } from "../actions/menu";

export const initial: IMenuState = { menu: MenuLevelEmum.Level0 };

export interface IMenuState {
  menu: MenuLevelEmum;
  sphereId?: number;
  objectId?: string;
}

export const reducer = (state: IMenuState = initial, action: Action): IMenuState => {
  switch (action.type) {
    case SHOW_MENU:
      const { menu, sphereId, objectId } = action as IShowMenuAction;
      return {
        menu,
        sphereId,
        objectId
      };
    case CLEAR_MENU_STATE_ON_HIRE_MANAGER: {
      const { menu } = action as IClearMenuAction;

      return {
        menu,
        sphereId: undefined,
        objectId: undefined,
      }
    }

    default:
      return state;
  }
};
