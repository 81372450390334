import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Tour, { ReactourStep } from "reactour";
import { notificaionMock } from "../../mocks";
import {
  gameStage,
  hideContextMenu,
  learningCompleate,
  learningProgressInit,
  learningProgressNext,
  learningProgressPause,
  modal2Hide,
  modalHide,
  notificationModalShow,
  resultModalShow,
  setPreviousTime,
  showContextMenu,
  timerSpeed,
  timerStart,
} from "../../redux/actions";
import { getGameInit } from "../../redux/actions/gameApi";
import { showMenuAction } from "../../redux/actions/menu";
import {
  getGameFinished,
  getGameId,
  getGameStage,
  getLearningCompleted,
  getLearningProgress,
  getModalDialog,
  getModalDialog2,
  getTimer,
} from "../../redux/selectors";
import { getSteps } from "../../service/steps";
import BuildingDescriptionModal from "../BuildingDescriptionModal";
import BuildingInfo from "../BuildingInfo";
import DemolitionModal from "../DemolitionModal";
import EventResultModal from "../EventResultModal";
import GameIntro from "../GameIntro";
import GameMap from "../GameMap";
import GameMenu, { NotificationDialog, MenuLevelEmum } from "../GameMenu";
import GameStatusPanel from "../GameStatusPanel";
import PushNotificationsComponent from "../PushNotificationsComponent";
import ResultsGameOverModal from "../ResultsGameOverModal";
import ResultsMoneyDeficitModal from "../ResultsMoneyDeficitModal";
import ResultModal from "../ResultsModal";
import ResultsYearModal from "../ResultsYearModal";
import ShowplaceModal from "../Showplace";
import SphereObjectsModal from "../SphereObjectsModal";
import UpgradeLevesContainer from "../UpgradeLevesContainer";
import UpgradeModal from "../UpgradeModal";
import RandomEventsGenerator from "./randomEventsGenerator";
import UpgradeHandler from "./UpgradeHandler";
import BusinessGrantModal from "../BusinessGrantModal";
import GameOverGovScaleModal from "../GameOverGovScaleModal";
import KvartalModal from "../KvartalModal";
import InterviewModal from "../InterviewModal";
import { IModalDialog } from "../../models";
import ModalComponent from "../ModalComponent";
import RandomEventContent from "../RandomEventContent";
import style from "./style.module.scss";

const GamePage = () => {
  const dispatch = useDispatch();
  const { id }: any = useParams();

  const learningCompleted = useSelector(getLearningCompleted);
  const { step, inProgress } = useSelector(getLearningProgress);
  const handleCurrentClick = (step: number) => {
    dispatch(learningProgressInit(step));
  };

  const timer = useSelector(getTimer);
  const { speed } = timer;

  const handleCloseClick = () => {
    dispatch(learningCompleate());
    dispatch(learningProgressPause());
    dispatch(gameStage("progress"));
    dispatch(timerStart());
  };

  const handleNextClick = () => {
    console.log("next", step);
    if (step === 1) {
      dispatch(showMenuAction(MenuLevelEmum.Level1_Buildings));
    }
    if (step === 2) {
      dispatch(showContextMenu());
    }
    if (step === 3) {
      dispatch(hideContextMenu());
      dispatch(showMenuAction(MenuLevelEmum.Notifications));
      dispatch(notificationModalShow(notificaionMock.id));
    }
    if (step === 4) {
      dispatch(modalHide());
      dispatch(showMenuAction(MenuLevelEmum.Details));
    }

    dispatch(learningProgressNext());
  };

  const steps: ReactourStep[] = getSteps(
    () => handleNextClick(),
    () => handleCloseClick()
  );

  // const currentGame = useSelector(getGame);

  const modalDialog2 = useSelector(getModalDialog2);
  // console.log(id ,store.getState())

  // Получаем текущее этап игры
  const stage = useSelector(getGameStage);
  const gameId = useSelector(getGameId);
  const gameFinished = useSelector(getGameFinished);

  useEffect(() => {
    dispatch(getGameInit(id));
  }, [dispatch, id]);

  // console.log(stage)

  const setPrevTimeAndClose = (id?: number) => {
    dispatch(setPreviousTime());
    dispatch(modalHide(id));
  }

  const hideModal = (id?: number) => {
    dispatch(modalHide(id));
  }

  const selectNotifDialog = () => {
    if (
      modalDialog2 &&
      modalDialog2.dialogType === "NotificationModal" &&
      modalDialog2.notificationId
    ) {

      const closeModalHandle = () => {
        dispatch(setPreviousTime());
        dispatch(modal2Hide());
      };

      if (modalDialog2.eventResult) {

        return (
          <ModalComponent
            isBackside={modalDialog.length > 0}
            isHeader={false}
            closeAction={closeModalHandle}
            height="35rem"
          >
            <RandomEventContent
              eventId={modalDialog2.notificationId}
              isShowResult={true}
              result={modalDialog2.eventResult}
            />
          </ModalComponent>
        );
      }
      return (
        <ModalComponent
          isBackside={modalDialog.length > 0}
          isHeader={false}
          closeAction={closeModalHandle}
          height="35rem"
        >
          <RandomEventContent eventId={modalDialog2.notificationId} />
        </ModalComponent>
      );
    }
    return null;
  };

  const modalDialog = useSelector(getModalDialog) ?? [];

  const selectDialog = () => {
    if (stage === "intro") {
      return <GameIntro />;
    }

    if (gameFinished) {
      return <ResultsGameOverModal closeAction={hideModal} />;
    }
    if (modalDialog) {
      const modalsElArray = modalDialog.map((m: IModalDialog, index: number) => {
        switch (m.dialogType) {
          case 'SphereObjectsModal': {
            return (m.sphereId && m.placeId && m.contextMenuElementId && m.id &&
              <SphereObjectsModal
                previousModalId={m.id}
                closeAction={() => hideModal(m.id)}
                placeId={m.placeId}
                sphereId={m.sphereId}
                contextMenuElementId={m.contextMenuElementId}
              />
            );
          }
          case "DeconstructObjectModal": {
            return (m.objectId && m.sphereId && m.placeId && m.id &&
              <DemolitionModal
                closeModal={() => hideModal(m.id)}
                objectId={m.objectId}
                sphereId={m.sphereId}
                placeId={m.placeId}
              />
            );
          }
          case "ObjectDoneUpgradeModal": {
            return (m.objectId && m.upgradeLevel && m.sphereId && m.objectId && m.placeId && m.id &&
              <UpgradeModal
                closeAction={() => hideModal(m.id)}
                sphereId={m.sphereId}
                objectId={m.objectId}
                placeId={m.placeId}
                upgradeLevel={m.upgradeLevel}
              />);
          }
          case "OnDeconstructModal": {
            if (speed !== 0) dispatch(timerSpeed(0));
            return (m.objectId && m.sphereId &&
              <EventResultModal modalType={"OnDeconstructModal"} objectId={m.objectId}
                sphereId={m.sphereId} onCloseHandle={() => setPrevTimeAndClose(m.id)} />);
          }
          case "GameOverGovScale": { return <GameOverGovScaleModal />; }
          case "GameOver": { return <ResultsGameOverModal closeAction={hideModal} />; }

          case "ShowpalceModal": {
            return m.showPlaceId && <ShowplaceModal
              showplaceId={m.showPlaceId}
              handleClose={() => hideModal(m.id)}
            />;
          }
          case "ObjectDoneUpgradeLevelsModal": {
            return (m.objectId && m.sphereId && m.objectId && m.placeId && m.id &&
              <UpgradeLevesContainer
                sphereId={m.sphereId}
                objectId={m.objectId}
                placeId={m.placeId} previousModalId={m.id} />);
          }
          case "OnUpgradeDoneModal": {
            if (speed !== 0) dispatch(timerSpeed(0));
            return (m.objectId && m.sphereId &&
              <EventResultModal modalType={"OnUpgradeDoneModal"} onCloseHandle={() => setPrevTimeAndClose(m.id)}
                objectId={m.objectId} sphereId={m.sphereId} />
            );
          }
          case "KvartalModal": {
            if (speed !== 0) dispatch(timerSpeed(0));
            return m.kvartalIncome &&
              <KvartalModal kvartalIncome={m.kvartalIncome} closeAction={() => setPrevTimeAndClose(m.id)} />
          }
          case 'Interview': {
            if (speed !== 0) dispatch(timerSpeed(0));
            return <InterviewModal closeAction={() => setPrevTimeAndClose(m.id)} />
          }
          case "MoneyDeficit": {
            if (m.dialogType === "MoneyDeficit" && m.deficitType) dispatch(timerSpeed(0));
            return (m.deficitType ? <ResultsMoneyDeficitModal closeAction={() => setPrevTimeAndClose(m.id)} deficitType={m.deficitType} /> : null);
          }
          case "ObjectModal": {
            return (m.contextMenuElementId && m.sphereId && m.objectId && m.placeId && m.id
              && <BuildingDescriptionModal
                previousModalId={m.id}
                sphereId={m.sphereId}
                placeId={m.placeId}
                objectId={m.objectId}
                contextMenuElementId={m.contextMenuElementId}
              />
            );
          }
          case "ResultWarning":
          case "ResultTermProlongation": {
            return (
              <ResultModal contentType={m.dialogType}
                closeAction={() => {
                  hideModal(m.id);
                  dispatch(resultModalShow("ResultYear"));
                }} />);
          }
          case "OnConstructModal": {
            if (speed !== 0) dispatch(timerSpeed(0));
            return (m.objectId && m.sphereId && m.id &&
              <EventResultModal modalType={"OnConstructModal"} objectId={m.objectId} sphereId={m.sphereId}
                onCloseHandle={() => setPrevTimeAndClose(m.id)} />);
          }
          case "ObjectDoneModal": {
            return (m.sphereId && m.objectId && m.objectId && m.placeId && m.id &&
              < BuildingInfo sphereId={m.sphereId} objectId={m.objectId} placeId={m.placeId} previousModalId={m.id} />);
          }
          case "GrantForBusiness": {
            if (speed !== 0) dispatch(timerSpeed(0));
            return <BusinessGrantModal closeAction={() => setPrevTimeAndClose(m.id)} />;
          }
          case "ResultYear": { return (<ResultsYearModal contentType={m.dialogType} closeAction={() => hideModal(m.id)} />); }

          default: return false;
        }
      });

      return modalsElArray.length > 0 ? <>
        <div className={style.modals_content}>

          {modalsElArray.map((e: any, index: number) => {
            return index === 0 ? <div key={index} className={style.first_modal}  >{e}         </div> :
              <div key={index} className={style.second_modal} style={{
                marginTop: `${-200 + index * 50}px`,
                zIndex: (0 - index)
              }}  >{e}
              </div>
          })}
        </div>
      </> : false

    }
    return false;
  };


  if (id !== gameId) {
    // console.log('wait')
    return null;
  }
  return (
    <>
      <GameMenu dialog={selectDialog()} />
      <NotificationDialog dialog={selectNotifDialog()} />
      <GameStatusPanel />
      <GameMap />
      <PushNotificationsComponent />
      <RandomEventsGenerator />
      <UpgradeHandler />
      <Tour
        accentColor={"#00326b"}
        steps={steps}
        className={style.tour}
        isOpen={!learningCompleted && inProgress}
        rounded={0}
        goToStep={step}
        getCurrentStep={() => handleCurrentClick}
        nextStep={handleNextClick}
        prevStep={() => { }}
        showNumber={false}
        startAt={step}
        closeWithMask={false}
        showNavigation={false}
        showButtons={false}
        maskSpace={0}
        onRequestClose={handleCloseClick}
      ></Tour>
    </>
  );
};

export default GamePage;
