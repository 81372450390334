import React from 'react';
import { LogoIcon } from '../../../../icons';
import style from './style.module.scss';

const Header = () => (
    <div className={style.header}>
        <LogoIcon />
        <h1 className={style.title}>Urbanizator</h1>
    </div>);

export default Header;
