import { useDispatch } from "react-redux";
import { getSphereById } from "../../initialData";
import { objectDoneUpgradeLevelsModalShow } from "../../redux/actions";
import ModalComponent from "../ModalComponent";
import UpgradeCardsList from "./UpgradeCardsList";

interface IUpgradeModal {
    sphereId: number;
    placeId: number;
    upgradeLevel: number;
    objectId: string;
    closeAction?: () => void;
}
const UpgradeModal: React.FunctionComponent<IUpgradeModal> = (props) => {
    const dispatch = useDispatch();
    const { sphereId, objectId, placeId, upgradeLevel, closeAction } = props;

    const sphere = getSphereById(sphereId);
    const cityObject = sphere.objects.find(_ => _.id === objectId);

    const lastIndex = upgradeLevel * 6;
    const getUpgrades = sphere.upgrades.slice(lastIndex - 6, lastIndex - 1);

    return !cityObject ? <></> :

        <ModalComponent title={`Улучшения для ${cityObject.name}`} isHeaderBottomBorder={true}
            backAction={() => {
                dispatch(objectDoneUpgradeLevelsModalShow(objectId, placeId, sphereId));
                closeAction && closeAction();
            }}
            closeAction={closeAction} height={'45rem'}>
            <UpgradeCardsList upgrades={getUpgrades} placeId={placeId} objectId={objectId} upgradeLevel={upgradeLevel} />
        </ModalComponent>;
}

export default UpgradeModal;