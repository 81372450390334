import { Action } from "redux";
import { IErrorResponse } from "../../../api/common";
import { ISavedGame } from "../../../models";
import { IFailAction } from "../../../models/common";

export const GET_GAMES_INIT = 'GET_GAMES_INIT';
export const GET_GAMES_DONE = 'GET_GAMES_DONE';
export const GET_GAMES_FAIL = 'GET_GAMES_FAIL';

export interface IGetGamesInitAction extends Action { }

export interface IGetGamesDoneAction extends Action {
    games: ISavedGame[];
}

export function getGamesInit() {
    return { type: GET_GAMES_INIT } as IGetGamesInitAction;
}

export function getGamesDone(games: ISavedGame[]) {
    return { type: GET_GAMES_DONE, games } as IGetGamesDoneAction;
}

export function getGamesFail(error: IErrorResponse) {
    return { type: GET_GAMES_FAIL, error } as IFailAction;
}