import { Action } from "redux";

export const LEARNING_PROGRESS_INIT = 'LEARNING_PROGRESS_INIT';
export const LEARNING_PROGRESS_START = 'LEARNING_PROGRESS_START';
export const LEARNING_PROGRESS_PAUSE = 'LEARNING_PROGRESS_PAUSE';
export const LEARNING_PROGRESS_NEXT = 'LEARNING_PROGRESS_NEXT';
export const SHOW_CONTEXT_MENU = 'SHOW_CONTEXT_MENU';
export const HIDE_CONTEXT_MENU = 'HIDE_CONTEXT_MENU';

export interface ILearningProcessAction extends Action {
    step: number,
    inProgress: boolean
}

export function learningProgressInit(step: number) {
    return { type: LEARNING_PROGRESS_INIT, step } as ILearningProcessAction;
}

export function learningProgressNext() {
    return { type: LEARNING_PROGRESS_NEXT };
}

export function learningProgressStart() {
    return { type: LEARNING_PROGRESS_START };
}

export function learningProgressPause() {
    return { type: LEARNING_PROGRESS_PAUSE };
}
export function showContextMenu() {
    return { type: SHOW_CONTEXT_MENU };
}
export function hideContextMenu() {
    return { type: HIDE_CONTEXT_MENU };
}


