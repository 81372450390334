import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ICreateGame } from "../../api/games";
import { useLtiAuth } from '../../hooks';
import initialData from '../../initialData';
import { IGame, ISavedGame } from '../../models';
import { getGamesInit, modalHide } from '../../redux/actions';
import { createGameInit } from '../../redux/actions/gameApi';
import * as game from '../../redux/reducers/game';
import { getAuth, getGameUserGames } from '../../redux/selectors';
import { Button } from '../UiKit';
import MainPage from '../UiKit/MainPage';
import CustomScrollbar from './../UiKit/Scrollbar';
import GameElem from './GameElem';
import style from './style.module.scss';

interface IProfilePageProps {
}

const ProfilePage: React.FunctionComponent<IProfilePageProps> = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector(getAuth);
    const isLti = useLtiAuth();

    const games: ISavedGame[] = useSelector(getGameUserGames) ?? [];

    useEffect(() => {
        if (auth) {
            dispatch(getGamesInit());
            history.push('/profile');
        }
        else if (!isLti) {
            history.push('/');
        }
    }, [dispatch, auth, history, isLti]);

    const handleNewGame = () => {

        const gameInitial = {
            ...game.initial,
            cityInfo: {
                mayorterm: initialData.cityConstInfo.mayortermbase
            }
        };

        gameInitial.id = 1;
        const gameDefault: ICreateGame = {
            state: JSON.stringify(gameInitial)
        };
        dispatch(modalHide());
        dispatch(createGameInit(gameDefault));
    }

    return <MainPage>
        <CustomScrollbar styleCustom={{ height: '80vh', width: '100%' }}>
            <div style={{ marginTop: games?.length === 0 ? '22rem' : '0' }}
                className={style.newGameMenu}>
                <h1 className={style.descriptionTitle}>Описание</h1>
                <div className={style.description}>Игра-симулятор, позволяющая почувствовать себя в роли мэра города Свободный. В ходе игры вам предстоит строить и улучшать важные объекты, нанимать менеджеров и учитывать пожелания жителей города.</div>
                <div className={style.description}>Симулятор приближен к современным вызовам городских управленцев, что помогает в безопасной игровой среде начать знакомиться со сферой, влияющей на судьбы миллионов людей в реальности.</div>
                <Button widthPercents={100} isStretched={true} onClick={handleNewGame}>Новая игра</Button>
            </div>

            <div className={style.gamesList}>

                {games.map((e, i) => (
                    <GameElem game={JSON.parse(e.state) as IGame} savedGame={e} key={i} />
                ))}

            </div>
        </CustomScrollbar>
    </MainPage >
}

export default ProfilePage;