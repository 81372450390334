import { useSelector } from 'react-redux';
import { ISphereUpgrade } from '../../../models';
import { IState } from '../../../redux/reducers';
import { getSelectedObject } from '../../../redux/selectors';
import UpgradeCard from '../UpgradeCard';
import style from './style.module.scss';

interface IUpgradeCardsListProps {
    upgrades: ISphereUpgrade[];
    placeId: number;
    objectId: string;
    upgradeLevel: number;
}

const UpgradeCardsList: React.FunctionComponent<IUpgradeCardsListProps> = (props) => {

    const { upgrades, placeId, objectId, upgradeLevel } = props;
    const objectSelected = useSelector(s => getSelectedObject(s as IState, placeId, objectId));
    return <div className={style.upgradeCardsList}>
            {upgrades.map((u, i) => {

                let applyedUpgrade: ISphereUpgrade | undefined = undefined;
                if (objectSelected && objectSelected.upgrades.findIndex(upg => upg.id === u.id) >= 0) {
                    applyedUpgrade = objectSelected.upgrades.find(upg => upg.id === u.id);
                }
                let disable = false;
                if (objectSelected) {
                    if (upgradeLevel === 1)
                        disable = objectSelected.upgrades.length >= 3 && applyedUpgrade === undefined;
                    if (upgradeLevel === 2)
                        disable = objectSelected.upgrades.length >= 6 && applyedUpgrade === undefined;
                    if (upgradeLevel === 3)
                        disable = objectSelected.upgrades.length >= 9 && applyedUpgrade === undefined;
                    if (upgradeLevel === 4)
                        disable = objectSelected.upgrades.length >= 12 && applyedUpgrade === undefined;
                }

                return <UpgradeCard key={i} upgrade={u} placeId={placeId} objectId={objectId} applyedUpgrade={applyedUpgrade} disable={disable} />;
            })}

    </div >
}

export default UpgradeCardsList;