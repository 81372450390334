import manager1 from './manager_1.png';
import manager2 from './manager_2.png';
import manager3 from './manager_3.png';
import manager4 from './manager_4.png';
import manager5 from './manager_5.png';
import manager6 from './manager_6.png';
import manager7 from './manager_7.png';
import manager8 from './manager_8.png';
import manager9 from './manager_9.png';
import manager10 from './manager_10.png';
import manager11 from './manager_11.png';
import manager12 from './manager_12.png';
import manager13 from './manager_13.png';
import manager14 from './manager_14.png';
import manager15 from './manager_15.png';
import manager16 from './manager_16.png';
import manager17 from './manager_17.png';
import manager18 from './manager_18.png';

export function getPhotoManager(managerId: number) {
    switch(managerId) {
        case 1: return manager1;
        case 2: return manager2;
        case 3: return manager3;
        case 4: return manager4;
        case 5: return manager5;
        case 6: return manager6;
        case 7: return manager7;
        case 8: return manager8;
        case 9: return manager9;
        case 10: return manager10;
        case 11: return manager11;
        case 12: return manager12;
        case 13: return manager13;
        case 14: return manager14;
        case 15: return manager15;
        case 16: return manager16;
        case 17: return manager17;
        case 18: return manager18;
    }
}