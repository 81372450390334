import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { FireIcon } from '../../icons';
import { INotification } from '../../models';
import { notificationModalShow } from '../../redux/actions';
import style from './style.module.scss';
import { getRandomEventsResults } from '../../redux/selectors';

interface INotificationCardNewsProps {
    news: INotification;
}

const NotificationCardNews: React.FunctionComponent<INotificationCardNewsProps> = (props) => {
    const { news } = props;
    const dispatch = useDispatch();
    const readEvents = useSelector(getRandomEventsResults)
    const openNotificationModal = (e: any) => {
        if (news.isRead) {
            const event = readEvents.filter(e => news.content.actions.filter(k => k.eventAction === e.eventAction)[0])[0];
            dispatch(notificationModalShow(news.id, event.eventResult));

            e.stopPropagation();
            return;
        }

        dispatch(notificationModalShow(news.id));

        e.stopPropagation();
    }
    return (
        <div className={style.notificationCardNews} onClick={openNotificationModal}>
            <FireIcon />
            <div className={cn(style.news, news.isRead ? style.read : '')}>
                <div className={style.source}>
                    <div className={style.sourceName}>Город.ОК</div>
                    <div className={style.sourceNameTlg}>@GorodOK.News</div>
                </div>
                <div className={style.content}>{news.content.event}</div>
            </div>
        </div>);
}
export default NotificationCardNews;