import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ITimer } from "../../../models";
import { timerStop, timerUpdate } from "../../../redux/actions";
import { getLearningCompleted } from "../../../redux/selectors";

export const useGameTimer = (timer: ITimer) => {

  const dispatch = useDispatch();
  const interval: any = React.useRef(null);
  const isLearningComplete = useSelector(getLearningCompleted);

  useEffect(() => {
    const handleBeforeUnload = () => {
      dispatch(timerStop());
      clearInterval(interval.current);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [dispatch, interval]);

  useEffect(() => {
    if (!isLearningComplete)
      return;

    if (!timer.started) {
      clearInterval(interval.current);
    }

    if (timer.started) {
      interval.current = setInterval(() => dispatch(timerUpdate()), 1000);
    }

  }, [interval, timer.started, dispatch, isLearningComplete]);
}