import { Action } from "redux";
import { ICityObjectProgress } from "../../../models";
import { GAME_BUILD_OBJECT, GAME_DESTROY_OBJECT, GAME_UPGRADE_OBJECT, IGameBuildObjectAction, IGameDestroyObjectAction, IGameUpgradeObjectAction, IRemoveBuildingFromMapAction, REMOVE_BUILDING_FROM_MAP } from "../../actions";

export const initial: ICityObjectProgress[] = [
    {
        sphereId: 5,
        placeId: -1,
        objectId: 'C',
        startBuildTime: 0,
        constructCoef: {
            safetyCoef: 1,
            comfortCoef: 1,
            environmentCoef: 1,
            identityCoef: 1,
            modernityCoef: 1,
            controlCoef: 1,
        },
        upgrades: []
    },
    {
        sphereId: 6,
        placeId: -1,
        objectId: 'R',
        startBuildTime: 0,
        constructCoef: {
            safetyCoef: 1,
            comfortCoef: 1,
            environmentCoef: 1,
            identityCoef: 1,
            modernityCoef: 1,
            controlCoef: 1,
        },
        upgrades: []
    }
];

export const reducer = (state: ICityObjectProgress[], action: Action) => {
    switch (action.type) {
        case GAME_BUILD_OBJECT:
            const { object } = action as IGameBuildObjectAction;
            return [...state, object];

        case GAME_UPGRADE_OBJECT:
            const upgrade = action as IGameUpgradeObjectAction;

            return state.map(o => {
                if (o.objectId === upgrade.objectId && o.placeId === upgrade.placeId) {

                    // Если есть - обновляем
                    if (o.upgrades.findIndex(_ => _.id === upgrade.upgrade.id) > -1) {
                        return {
                            ...o,
                            upgrades: o.upgrades.map(u => {
                                if (u.id === upgrade.upgrade.id) {
                                    return { ...u, ...upgrade.upgrade };
                                }

                                return u;
                            })
                        };
                    }

                    // Если нет - добавляем
                    return {
                        ...o,
                        upgrades: [...o.upgrades, { ...upgrade.upgrade }]
                    };
                }

                return o;
            });

        case REMOVE_BUILDING_FROM_MAP:
            const { building } = action as IRemoveBuildingFromMapAction;
            return state.map(o => {
                if (o.objectId === building.objectId && o.placeId === building.placeId) {
                    o.placeId = null;
                    return {
                        ...o,
                    };
                }

                return o;
            });

        case GAME_DESTROY_OBJECT:
            const deconstruct = action as IGameDestroyObjectAction;

            return state.map(o => {

                if (o.objectId === deconstruct.objectId && o.placeId === deconstruct.placeId) {
                    o.startDeconstructionTime = deconstruct.startDeconstructionTime;
                    o.desctuctCoef = {
                        safetyCoef: deconstruct.randCoefs[0],
                        comfortCoef: deconstruct.randCoefs[1],
                        environmentCoef: deconstruct.randCoefs[2],
                        identityCoef: deconstruct.randCoefs[3],
                        modernityCoef: deconstruct.randCoefs[4],
                        controlCoef: deconstruct.randCoefs[5],
                    };

                    return {
                        ...o,
                    };
                }

                return o;
            });

        default:
            return state;
    }
}