import { Action } from "redux";
import { IErrorResponse } from "../../../api/common";
import { IFailAction } from "../../../models/common";
import { ICreateGame, IGameDto } from "../../../api/games";

export const CREATE_GAME_INIT = 'CREATE_GAME_INIT';
export const CREATE_GAME_DONE = 'CREATE_GAME_DONE';
export const CREATE_GAME_FAIL = 'CREATE_GAME_FAIL';

export interface ICreateGameInitAction extends Action {
    game: ICreateGame
}

export interface ICreateGameDoneAction extends Action {
    createdGame: IGameDto;
}

export function createGameInit(game: ICreateGame) {
    return { type: CREATE_GAME_INIT, game } as ICreateGameInitAction;
}

export function createGameDone(createdGame: IGameDto) {
    return { type: CREATE_GAME_DONE, createdGame } as ICreateGameDoneAction;
}

export function createGameFail(error: IErrorResponse) {
    return { type: CREATE_GAME_FAIL, error } as IFailAction;
}