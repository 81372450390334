import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLevel } from "../../hooks/useLevel";
import { getSphereById } from "../../initialData";
import { modalHide, objectDoneModalShow, objectDoneUpgradeLevelsModalShow, objectDoneUpgradeModalShow } from "../../redux/actions";
import { IState } from "../../redux/reducers";
import { getSelectedObject } from "../../redux/selectors";
import ModalComponent from "../ModalComponent";
import ImprovementCard, { UpgradeLevelCardState } from "../UpgradeLevelCard";
import style from './style.module.scss';

type IUpgradeLevelCardProps = { sphereId: number; placeId: number; objectId: string; previousModalId: number; }

const UpgradeLevesContainer: React.FunctionComponent<IUpgradeLevelCardProps> = ({ sphereId, objectId, placeId, previousModalId }) => {
    const dispatch = useDispatch();
    let [isShowWarn, setIsShowWarn] = useState<boolean>(false);
    const currentUserGovAndBusLevel = useLevel();
    const onUpgrade = (e: any, level: number) => {
        if (currentUserGovAndBusLevel < level) {

            setIsShowWarn(true)
        }
        else {
            dispatch(objectDoneUpgradeModalShow(objectId, placeId, sphereId, level));
            dispatch(modalHide(previousModalId));
            e.stopPropagation();
        }
    }

    const objectSelected = useSelector(s => getSelectedObject(s as IState, placeId, objectId));
    const cardState = (level: number): UpgradeLevelCardState => level === 1 ||
        (level === 2 && objectSelected && objectSelected.upgrades.length / 3 >= 1) ||
        (level === 3 && objectSelected && objectSelected.upgrades.length / 6 >= 1) ||
        (level === 4 && objectSelected && objectSelected.upgrades.length / 9 >= 1) ?
        UpgradeLevelCardState.Active : UpgradeLevelCardState.Blocked

    return isShowWarn ? <LevelNotReachForBuildingModal sphereId={sphereId} objectId={objectId} previousModalId={previousModalId}
        placeId={placeId} setIsShowHandle={setIsShowWarn} curBusAndGovLevel={currentUserGovAndBusLevel} /> : <ModalComponent title={`Улучшения для ${getSphereById(sphereId).objects.find(_ => _.id === objectId)?.name}`}
            backAction={() => {
                dispatch(modalHide(previousModalId));
                dispatch(objectDoneModalShow(objectId, placeId, sphereId));
            }}
            closeAction={() => dispatch(modalHide(previousModalId))} height={'25rem'}>

        <div className={style.improvementCardContainer} >{
            ['Первичные улучшения', 'Базовые улучшения', 'Технологические улучшения', 'Инновационные улучшения']
                .map((e: string, index: number): { description: string; level: number } =>
                    ({ description: e, level: ++index })) // нумерация уровней начинается с 1
                .map(cardInfo => <ImprovementCard key={cardInfo.description} description={cardInfo.description} cardState={cardState(cardInfo.level)}
                    level={cardInfo.level} onClick={_ => onUpgrade(_, cardInfo.level)} />)}
        </div>
    </ModalComponent>;
};
export default UpgradeLevesContainer;

const LevelNotReachForBuildingModal: React.FunctionComponent<IUpgradeLevelCardProps
    & { setIsShowHandle: React.Dispatch<React.SetStateAction<boolean>> }
    & { curBusAndGovLevel: number }>
    = ({ objectId, placeId, sphereId, setIsShowHandle, curBusAndGovLevel, previousModalId }) => {
        const dispatch = useDispatch();
        const renderNeedLevelScore = (level: number): number => {
            switch (level) {
                case 1: return 25;
                case 2: return 50;
                case 3: return 75;
                case 4:
                default: return -1;
            }
        }
        return curBusAndGovLevel < 4 ? <ModalComponent
            closeAction={() => dispatch(modalHide(previousModalId))}
            backAction={() => { setIsShowHandle(false); dispatch(objectDoneUpgradeLevelsModalShow(objectId, placeId, sphereId)); dispatch(modalHide(previousModalId)) }}
            height='10rem'>
            <div className={style.flex}>
                <div className={style.title}>
                    К сожалению, сейчас вы не можете совершить данные улучшения,
                    так как не достигли среднего значения в {renderNeedLevelScore(curBusAndGovLevel)} пунктов по шкалам "Индекс инвестиционной привлекательности" и "Одобрение государством".
                </div>
            </div>
        </ModalComponent> : null;
    }