import { Action } from 'redux';
import { ICityObjectProgress, ISphereUpgrade } from '../../../models';

export const GAME_BUILD_OBJECT = 'GAME_BUILD_OBJECT';
export const GAME_UPGRADE_OBJECT = 'GAME_UPGRADE_OBJECT';
export const GAME_DESTROY_OBJECT = 'GAME_DESTROY_OBJECT';
export const REMOVE_BUILDING_FROM_MAP = 'REMOVE_BUILDING_FROM_MAP';

export interface IGameBuildObjectAction extends Action {
  object: ICityObjectProgress;
  buildCost: number;
}

export interface IGameDestroyObjectAction extends Action {
  objectId: string;
  placeId: number;
  startDeconstructionTime: number;
  deconstructCost: number;
  randCoefs: number[];
}

export interface IGameUpgradeObjectAction extends Action {
  objectId: string;
  placeId: number;
  upgrade: ISphereUpgrade;
}
export interface IRemoveBuildingFromMapAction extends Action {
  building: ICityObjectProgress;
}

export function gameBuildObject(objectId: string, startBuildTime: number, placeId: number, sphereId: number, buildCost: number, randCoefs: number[]) {
  return {
    type: GAME_BUILD_OBJECT,
    buildCost, object: {
      objectId,
      placeId,
      startBuildTime,
      sphereId,
      upgrades: [],
      constructCoef: {
        safetyCoef: randCoefs[0],
        comfortCoef: randCoefs[1],
        environmentCoef: randCoefs[2],
        identityCoef: randCoefs[3],
        modernityCoef: randCoefs[4],
        controlCoef: randCoefs[5],
      }
    },
  } as IGameBuildObjectAction;
}

export function gameUpgradeObject(objectId: string, placeId: number, upgrade: ISphereUpgrade) {
  return { type: GAME_UPGRADE_OBJECT, objectId, placeId, upgrade } as IGameUpgradeObjectAction;
}

export function gameDestroyObject(objectId: string, placeId: number, startDeconstructionTime: number, deconstructCost: number, randCoefs: number[]) {
  return {
    type: GAME_DESTROY_OBJECT, deconstructCost, objectId, placeId, startDeconstructionTime, randCoefs
  } as IGameDestroyObjectAction;
}

export function removeBuildingFromMap(building: ICityObjectProgress) {
  return { type: REMOVE_BUILDING_FROM_MAP, building } as IRemoveBuildingFromMapAction;
}