import { IGame } from '../models';
import { httpGet, httpPost, httpPut } from './common';

export interface ICreateGame {
    state: string;
}

export interface IGameDto {
    id: number,
    beginDate: Date;
    lastActionDate: Date;
    state: string;
    email: string;
}

export function getGames() {
    return httpGet<IGame[]>('/api/games', { authorize: true });
}

export function create(game: ICreateGame) {
    return httpPost<IGameDto>('/api/games/create', game, { authorize: true });
}

export function score(id: number, value: number) {
    return httpPost<IGameDto>(`/api/games/${id}/score/${value}`, { authorize: true });
}

export function save(id: number, game: ICreateGame) {
    return httpPut<IGameDto>(`/api/games/${id}/save`, game, { authorize: true });
}

export function getById(id: number) {
    return httpGet<IGameDto>(`/api/games/${id}`, { authorize: true });
}

export interface IGameStatistic {
    action: string;
    state_before: string;
    state_after: string;
}

export function saveStatsApiCall(gameId: number, gameStats: IGameStatistic) {
    return httpPost<any>(`/api/game/${gameId}/stats`, gameStats, { authorize: true });
}
