import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormField } from '../../../hooks';
import { loginInit } from '../../../redux/actions';
import { getAuthError } from '../../../redux/selectors';
import { Button, Input } from '../../UiKit';
import style from './style.module.scss';


const StartPageContent: React.FunctionComponent = (props) => {
    const errorAuth = useSelector(getAuthError);
    const renderErrorMessage = () => {
        if (errorAuth !== null) {
            return <label htmlFor="" className={style.errorLabel}>{errorAuth}</label>;
        }
    }
    const userName = useFormField('');
    const password = useFormField('');
    const dispatch = useDispatch();
    const handleLogin = (event: any) => {

        if (userName.value !== "" && password.value !== "") {
            dispatch(loginInit(userName.value, password.value));
        }
        event.preventDefault();
        event.stopPropagation();

    }
    return <form className={style.menu} onSubmit={handleLogin}>
        <h1 className={style.loginText}>Вход в игру</h1>
        <Input required={true} label={"Email"} type={"text"} value={userName} />
        <Input required={true} label={"Пароль"} type={"password"} value={password} />
        {renderErrorMessage()}
        <div className={style.btn}>
            <Button isStretched={true} onClick={handleLogin} widthPercents={100}>Войти</Button>
        </div>
    </form>;
}
export default StartPageContent;
