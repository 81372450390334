import { Action } from "redux";
import { INotification } from "../../../models";
import { GAME_CLOSE_PUSH_NOTIFICATION, GAME_NEW_NOTIFICATION, GAME_READ_NOTIFICATION, INewNotificationAction, INotificationAction } from "../../actions";

export const initial: INotification[] = [];

export const reducer = (state: INotification[], action: Action) => {
    switch (action.type) {
        case GAME_NEW_NOTIFICATION:
            const { notification } = action as INewNotificationAction;
            return [notification, ...state];

        case GAME_READ_NOTIFICATION:
            const { id } = action as INotificationAction;

            return state.map(n => {
                if (n.id === id) {
                    return {
                        ...n,
                        isRead: true
                    }
                }

                return n;
            });

        case GAME_CLOSE_PUSH_NOTIFICATION:
            const pushId = action as INotificationAction;
    
            return state.map(n => {
                    if (n.id === pushId.id) {
                        return {
                            ...n,
                            isPushClose: true
                        }
                    }
    
                    return n;
                });         

        default:
            return state;
    }
}