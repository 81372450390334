import BuildingCard from "../BuildingCard"
import ModalComponent from "../ModalComponent"
import initialData from '../../initialData';
import { useDispatch } from "react-redux";
import { modalHide } from "../../redux/actions";
import { getPhotoObject } from "../../images/buildingObjects";
import style from './style.module.scss';

interface ISphereObjectsModal {
    sphereId: number;
    placeId: number;
    contextMenuElementId: string;
    closeAction(): void;
    previousModalId: number;
}

const SphereObjectsModal: React.FunctionComponent<ISphereObjectsModal> = (props) => {

    const dispatch = useDispatch();
    const { sphereId, placeId, contextMenuElementId, closeAction, previousModalId } = props;

    const sphere = initialData.citySpheresInfo.find(_ => _.sphereId === sphereId);

    const openContextMenuOnBack = () => {
        const elem = document.getElementById(contextMenuElementId);
        if (elem) {
            elem.dispatchEvent(new Event(`showcontextagain${contextMenuElementId}`, { bubbles: true }));
        }
        dispatch(modalHide());
    };

    return !sphere ? null :
        <ModalComponent title={sphere.sphereName} height={'35rem'}
            closeAction={closeAction}
            backAction={() => { openContextMenuOnBack(); closeAction && closeAction(); }} >
            <div className={style.buildingCards}>
                {sphere.objects.map(o => <BuildingCard placeId={placeId} key={o.id} objectId={o.id} photo={getPhotoObject(o.id)}
                    name={o.name}
                    price={o.params.costXnYn}
                    deadline={o.params.timecostXnYn}
                    desc={o.desc}
                    contextMenuElementId={contextMenuElementId}
                    sphereId={sphereId}
                    previousModalId={previousModalId}
                />)}
            </div>
        </ModalComponent>
}

export default SphereObjectsModal;