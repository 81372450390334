import { httpPost } from './common';

export interface IAuthResult {
    accessToken: string;
    authenticationScheme: string;
    validUntil: number;
}

export function authorize(username: string, password: string) {
    return httpPost<IAuthResult>('/api/user/auth', { username, password }, { authorize: false });
}