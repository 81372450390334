import { Action } from "redux";
import { ICityBildingPlacePoint } from "../../../models";
import { GO_TO_PLACE_DONE, GO_TO_PLACE_INIT, ICityPlacePointInitAction } from "../../actions";

export const initial: ICityBildingPlacePoint | undefined = undefined;

export const reducer = (state: ICityBildingPlacePoint | undefined, action: Action) => {
    switch (action.type) {
        case GO_TO_PLACE_INIT:
            const { point } = action as ICityPlacePointInitAction;
            return point;

        case GO_TO_PLACE_DONE:
            return undefined;

        default:
            return state;
    }
}