import { Action } from "redux";
import { call, put, takeLatest } from 'redux-saga/effects';
import { IErrorResponse } from '../../../api/common';
import { getGames } from '../../../api/games';
import { ISavedGame } from '../../../models';
import { getGamesDone, getGamesFail, GET_GAMES_INIT } from '../../actions/gameApi/getGames';

export function* gamesHandler(action: Action) {
    try {
        const result: ISavedGame[] = yield call(getGames,);
        yield put(getGamesDone(result));
    } catch (e) {
        const error = e as IErrorResponse;
        yield put(getGamesFail(error))
    }
}

export function* getGamesSaga() {
    yield takeLatest(GET_GAMES_INIT, gamesHandler);
}
