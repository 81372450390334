import showplaces from '../../initialData/showplaces';
import ModalComponent from '../ModalComponent';
import { Button } from '../UiKit';
import style from './style.module.scss';

interface IShowPlaceModalProps {
    showplaceId: number;
    handleClose?: () => void;
}

const ShowPlaceModal: React.FunctionComponent<IShowPlaceModalProps> = (props) => {
    const { showplaceId, handleClose } = props;
    const showplace = showplaces.filter(e => e.id === showplaceId)[0];

    return <ModalComponent showBackIcon={false} title={showplace.title} closeAction={handleClose}  height='25rem'>
        <div className={style.showplace}>
            <div className={style.info}>
                <div className={style.desc}>
                    <label htmlFor="" className={style.text}>  {showplace.desc}</label>
                    <div className={style.btn}>
                        <Button isStretched={true} isBlue={false} onClick={handleClose}>Закрыть</Button>
                    </div>
                </div>
                <div className={style.photo}>
                    <img src={showplace.photoUrl} alt="" />
                </div>
            </div>
        </div>
    </ModalComponent >
}
export default ShowPlaceModal;