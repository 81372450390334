import { Action } from "redux";
import { IErrorResponse } from "../../../api/common";
import { IFailAction } from "../../../models/common";

export const GET_GAME_INIT = 'GET_GAME_INIT';
export const GET_GAME_FAIL = 'GET_GAME_FAIL';

export interface IGetGameInitAction extends Action {
    id: number;
}

export function getGameInit(id: number) {
    return { type: GET_GAME_INIT, id } as IGetGameInitAction;
}

export function getGameFail(error: IErrorResponse) {
    return { type: GET_GAME_FAIL, error } as IFailAction;
}