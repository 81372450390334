// Исходные данные для игры

import { ISphere, ICityConstInfo, ICityBildingPlacePoint, IRandomEvent } from '../models';
import cityBaseInfoJson from './cityConstInfo.json';
import citySpheresJson from './citySpheres.json';
import cityBuildingPlacesJson from './cityBuildingPlaces.json';
import randomEventsJson from './randomEvents.json';
import { shuffle } from './../helpers';

export interface IInitialData {
    cityConstInfo: ICityConstInfo,
    citySpheresInfo: ISphere[],
    cityBuildingPointsInfo: ICityBildingPlacePoint[],
    randomEventsInfo: IRandomEvent[]
}

const cityConstInfo = cityBaseInfoJson as ICityConstInfo;
const citySpheresInfo = citySpheresJson as ISphere[];
const cityBuildingPointsInfo = cityBuildingPlacesJson as ICityBildingPlacePoint[];
const randomEventsInfo = shuffle(randomEventsJson) as IRandomEvent[];


const intialData: IInitialData = {
    cityConstInfo,
    citySpheresInfo,
    cityBuildingPointsInfo,
    randomEventsInfo
};

export function getSphereById(sphereId: number) {
    const sphr = citySpheresInfo.filter(_ => _.sphereId === sphereId);
    return sphr[0];
}

export function getPlaceById(placeId: number) {
    const plc = cityBuildingPointsInfo.filter(_ => _.id === placeId);
    return plc[0];
}

export default intialData;