import { formatPrice } from '../../../helpers/formatPrice';
import { paintDigits } from '../../../helpers/paintDigits';
import style from './style.module.scss';

interface INameValueElem {
    name: string;
    value: number;
    isValCapitalized?: boolean;
}
const NameValueComponent = (props: INameValueElem) => {
    const { name, value, isValCapitalized = false } = props;
    const valueDiv = isValCapitalized ?
        <div className={style.value}>{formatPrice(value)} тыс. ₽</div>
        :
        <div className={style.nonCapValue}>{paintDigits(value)}</div>

    return (<div className={style.nameValueElem}>
        <div className={style.name}>{name}</div>
        {valueDiv}
    </div>
    );
}
export default NameValueComponent;