import { useEffect, useState } from 'react';
import ContextMenu from '../ContextMenu';
import { ICityBildingPlacePoint, ICityObjectProgress } from '../../models';
import style from './style.module.scss';
import { getPhotoObject } from '../../images/buildingObjects';
import LockedKvadrant from '../LockedKvadrant';
import { useDispatch, useSelector } from 'react-redux';
import { getTimer } from '../../redux/selectors';
import { getSphereById } from '../../initialData';
import dayjs from 'dayjs';
import { objectDoneModalShow, onDeconstructModalShow, removeBuildingFromMap } from '../../redux/actions';
import cn from 'classnames';

interface ICityBuildingPlacePointProps {
    point: ICityBildingPlacePoint;
    scaleHeightCoeff: number;
    // scaleWidthtCoeff: number;
    building?: ICityObjectProgress;
}

const CityBuildingPlacePoint: React.FunctionComponent<ICityBuildingPlacePointProps> = (props) => {

    const dispatch = useDispatch();
    const [hover, setHover] = useState<boolean>(false);
    const { point, scaleHeightCoeff, building } = props;
    const timer = useSelector(getTimer);

    const height = 510 / scaleHeightCoeff + 1;

    const elementId = `place_${point.id}`;

    let buildingProgress = -1;
    let deconstructionProgress = -1;

    useEffect(() => {
        if (building && building.startDeconstructionTime) {

            const sphere = getSphereById(building?.sphereId);

            const obj = sphere.objects.find(_ => _.id === building.objectId);
            if (obj) {
                if (deconstructionProgress >= 1 && building.placeId != null) {
                    dispatch(removeBuildingFromMap(building));
                    dispatch(onDeconstructModalShow(obj.id, building.sphereId));
                }
            }
        }
    }, [timer.curDate, deconstructionProgress, building, dispatch]);

    if (building) {
        const sphere = getSphereById(building?.sphereId);
        const obj = sphere.objects.find(_ => _.id === building.objectId);
        if (obj) {
            buildingProgress = dayjs(timer.curDate).diff(building.startBuildTime, 'days') / obj.params.timecostXnYn;
            if (building.startDeconstructionTime) {
                deconstructionProgress = dayjs(timer.curDate).diff(building.startDeconstructionTime, 'days') / obj.params.undotimecostXnYn;
            }
        }
    }

    const getImage = () => {

        if (building && buildingProgress >= 1) {
            const photo = getPhotoObject(building.objectId);
            return <img className={cn(style.buildingPlacePhoto, hover ? style.brightness : '')} style={{ top: point.leftCorner.y, left: point.leftCorner.x, height: height }} alt="" src={photo.img} />;
        }

        return <div style={{ top: point.leftCorner.y, left: point.leftCorner.x, height: height }} className={!hover ? style.cityBuildingPlaceEmptyBackground : style.cityBuildingPlaceEmptyBackgroundHover} />;
    }

    const getProgressBar = () => {

        if (building && (buildingProgress <= 1)) {
            return <div className={style.buildingProgress} style={{ top: point.leftCorner.y, left: point.leftCorner.x, height: height }}>
                <LockedKvadrant objectId={building.objectId} sphereId={building.sphereId} placeId={building.placeId ?? -1} isDeconstruct={false} percent={buildingProgress * 100} />
            </div>;
        }

        return <></>;
    }
    const getDeconstructionProgressBar = () => {
        if (building && building.startDeconstructionTime && (deconstructionProgress < 1)) {

            return <div className={style.buildingProgress} style={{ top: point.leftCorner.y, left: point.leftCorner.x, height: height }}>
                <LockedKvadrant objectId={building.objectId} sphereId={building.sphereId} placeId={building.placeId ?? -1} isDeconstruct={true} percent={deconstructionProgress * 100} />
            </div>;
        }

        return <></>;
    }

    const showObjectInfo = (e: any) => {
        if (building && building.placeId && buildingProgress >= 1) {
            dispatch(objectDoneModalShow(building.objectId, building.placeId, building.sphereId));
            e.stopPropagation();
        }
    }

    return <>
        {building && !building.startDeconstructionTime && getProgressBar()}
        {getDeconstructionProgressBar()}
        {getImage()}
        <div id={elementId} onClick={showObjectInfo} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}
            style={{ top: point.center.y - 2, left: point.center.x, height: 160 / scaleHeightCoeff, width: 710 / scaleHeightCoeff }} className={style.cityBuildingPlaceClickable}>
            {point.id}
        </div>
        {!building && <ContextMenu elementId={elementId} xPosDelta={point.center.x} placeId={point.id} />}
        {/* <div style={{top: point.leftCorner.y, left: point.leftCorner.x }} className={style.cityBuildingPlace}>{point.id}</div> */}
    </>
}

export default CityBuildingPlacePoint;