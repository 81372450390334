import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import App from './app';
import './index.scss';
import { history, store } from './store/configureStore';

ReactDOM.render(<Provider store={store}>
  <Router history={history}>
    <App />
  </Router>
</Provider>, document.getElementById('root'));

