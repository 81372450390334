import { MenuLevelEmum } from '..';
import { DollarIcon, PeopleIcon, QuestionIcon, StarIcon } from '../../../icons';
import { ProgressBar, ProgressBarColor, ProgressBarSize, RowNameTooltipValue, ToolTip } from '../../UiKit';
import CustomScrollbar from '../../UiKit/Scrollbar';
import BackMenuHeader from '../MenuHeader/backHeader';
import {
    averageIncomeTooltipContent, comfortToolTipContent, controlToolTipContent, enviromentToolTipContent,
    happinessTooltipContent, identityToolTipContent, moderntityToolTipContent, quilityTooltipContent, saftyToolTipContent
} from '../../../initialData/tooltipInfo';
import style from './style.module.scss';
import { useSelector } from 'react-redux';
import { getEstimatedIndicators } from '../../../redux/selectors';
import SatisfactionResultsView from '../../SatisfactionResultsView';
import { formatPrice } from '../../../helpers/formatPrice';

interface IDetailsMenuProps {
    menuLevelSet: (level: MenuLevelEmum) => void;
}

const DetailsMenu: React.FunctionComponent<IDetailsMenuProps> = (props) => {
    const { menuLevelSet } = props;
    const estimatedIndicators = useSelector(getEstimatedIndicators);

    return (<>
        <span data-tut="Indicators" className={style.ind}  >
            <BackMenuHeader title={'Показатели'} onClick={() => menuLevelSet(MenuLevelEmum.Level0)} />

            <CustomScrollbar styleCustom={{ height: '93%', width: '420px' }}>
                <div className={style.details}>
                    <div className={style.progressBarQuality}>
                        <ProgressBar icon={<StarIcon />} color={ProgressBarColor.orange}
                            size={ProgressBarSize.big}
                            completedValuePercent={Math.round(estimatedIndicators.urbanquality / 360 * 100)} completedValueFact={estimatedIndicators.urbanquality} title={'Качество городской среды'}
                            tooltipEl={<ToolTip text={quilityTooltipContent} id={'quilityTooltipContent'}>
                                <QuestionIcon />
                            </ToolTip>}

                        />
                    </div>
                    <h2 className={style.indirectIndicatorsHeading}>Косвенные показатели</h2>
                    <div className={style.indirectIndicators}>
                        <RowNameTooltipValue name={'Безопасность'} value={Math.round(estimatedIndicators.safety)} tooltipInfo={saftyToolTipContent} id={`saftyToolTipContent`} />
                        <RowNameTooltipValue name={'Комфортность'} value={Math.round(estimatedIndicators.comfort)} tooltipInfo={comfortToolTipContent} id={`comfortToolTipContent`} />
                        <RowNameTooltipValue name={'Экологичность и здоровье'} value={Math.round(estimatedIndicators.environment)} tooltipInfo={enviromentToolTipContent} id={`enviromentToolTipContent`} />
                        <RowNameTooltipValue name={'Идентичность и разнообразие'} value={Math.round(estimatedIndicators.identity)} tooltipInfo={identityToolTipContent} id={`identityToolTipContent`} />
                        <RowNameTooltipValue name={'Современность и актуальность'} value={Math.round(estimatedIndicators.modernity)} tooltipInfo={moderntityToolTipContent} id={`moderntityToolTipContent`} />
                        <RowNameTooltipValue name={'Эффективность управления'} value={Math.round(estimatedIndicators.control)} tooltipInfo={controlToolTipContent} id={`controlToolTipContent`} />
                    </div>
                    <div className={style.info}>
                        <div className={style.averageIncome}>
                            <DollarIcon />
                            <div className={style.averageIncomeProgress}>
                                <RowNameTooltipValue isMoney={false} name={'Среднедушевой доход'} value={formatPrice(Math.round(estimatedIndicators.percitizen) * 1000)}
                                    tooltipInfo={averageIncomeTooltipContent} id={`averageIncomeTooltipContent`} />
                            </div>
                        </div>
                    </div>
                    <div className={style.info}>
                        <div className={style.progressBarHappiness} data-tut='Indicator1'>
                            <ProgressBar icon={<PeopleIcon />} color={ProgressBarColor.blue}
                                size={ProgressBarSize.small}
                                completedValuePercent={Math.round(estimatedIndicators.satisfaction)} completedValueFact={Math.round(estimatedIndicators.satisfaction)}
                                title={'Удовлетворенность жителей'}
                                tooltipEl={<ToolTip text={happinessTooltipContent} id={'happinessTooltipContent'}>
                                    <QuestionIcon />
                                </ToolTip>}
                            />

                        </div>
                        <SatisfactionResultsView />
                    </div>
                </div>
            </CustomScrollbar>
        </span>
    </>);
}

export default DetailsMenu;