import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PauseIcon } from "../../../icons";
import { Speed } from "../../../models";
import { timerSpeed } from "../../../redux/actions";
import { getTimer } from "../../../redux/selectors";
import { StatusValue } from "../Common";
import SpeedItemsCombobox from "../SpeedItemsCombobox";
import style from "./style.module.scss";

const SpeedItem = () => {
  const dispatch = useDispatch();
  const [isVisibleSpeedSelector, setVisibleSpeedSelector] = useState(false);
  const [speed, setSpeed] = useState<Speed>(1);
  const timer = useSelector(getTimer);

  useMemo(() => {
    setSpeed(timer.speed);
  }, [timer.speed]);

  const setSpeedAndClose = (s: Speed) => {
    setSpeed(s);
    setVisibleSpeedSelector(false);
    dispatch(timerSpeed(s));
  };
  return (
    <div className={style.speedItemContainer} data-tut="IndicatorTime">
      <StatusValue
        statusName={"Скорость:"}
        value={
          speed !== 0 ? `x${speed}` : <PauseIcon className={style.pause} />
        }
        selectableValue={true}
        onSelect={() => {
          isVisibleSpeedSelector
            ? setVisibleSpeedSelector(false)
            : setVisibleSpeedSelector(true);
        }}
      />
      {isVisibleSpeedSelector && (
        <SpeedItemsCombobox onSelect={setSpeedAndClose} />
      )}
    </div>
  );
};

export default SpeedItem;
