import { useQuery } from ".";
import { loginDone } from "../redux/actions";
import { useDispatch } from "react-redux";

export function useLtiAuth(){
    const query = useQuery();
    const dispatch = useDispatch();

    const token = query.get('accessToken');
    const until = query.get('validUntil');
    const scheme = query.get('authenticationScheme');

    if (token && until && scheme) {
      dispatch(loginDone({accessToken: token,  validUntil: Date.parse(until), authenticationScheme: scheme}));

      return true;
    }
    
    return false;
}