import style from './style.module.scss';

interface IBuildingPhotoProps {
    photo: any;
    isBordered?: boolean;
}

const BuildingPhoto: React.FunctionComponent<IBuildingPhotoProps> = (props) => {
    const { photo, isBordered = true } = props;

    return <div className={isBordered ? style.photo : style.unborderedPhoto}>
        <img src={photo} alt="" className={style.buildingImg} />
    </div>
}

export default BuildingPhoto;