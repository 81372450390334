export const saftyToolTipContent = `Данный показатель помогает определить существующий уровень безопасности города и оценить те параметры среды, которые могут способствовать причинению вреда жизни и здоровью человека.
Максимальное значение, которое вы можете достигнуть по нему - 60.`;

export const comfortToolTipContent = `Данный показатель помогает оценить удобство и доступность городских пространств.
Максимальное значение, которое вы можете достигнуть по нему - 60.`;

export const enviromentToolTipContent = `Показатель, определяющий, в какой мере использование инфраструктуры города соответсвует принципу сохранения и улучшения окружающей среды, а также измеряет общее состояние экологии городской среды.
Максимальное значение, которое вы можете достигнуть по нему - 60.`;

export const identityToolTipContent = `Показатель отвечает за оценку своеобразия и узнаваемости городских территорий. Кроме этого, он определяет вариативность пространственных решений и функциональное разнообразие городской среды.
Максимальное значение, которое вы можете достигнуть по нему - 60.`;

export const moderntityToolTipContent = `Показатель измеряет параметры, описывающие город с точки зрения возможностей, которые он предоставляет жителям. Также он определяет соответствие городских пространств международным принципам формирования городской среды.
Максимальное значение, которое вы можете достигнуть по нему - 60.`;
export const controlToolTipContent = `Показатель отражает параметры, которые непосредственно характеризуют работу органов управления на местах и территориях, необходимых для формирования качественной и эффективной городской среды.
Максимальное значение, которое вы можете достигнуть по нему - 60.`;

export const businessToolTipContent = `Показатель уровня инвестиционной привлекательности. Максимальное значение, которое вы можете достигнуть по нему - 100.`;
export const govToolTipContent = `Показатель уровня одобрения государством. Максимальное значение, которое вы можете достигнуть по нему - 100.`;

export const quilityTooltipContent = `Данный индекс является суммарным значением косвенных показателей, указанных ниже и является ключевой метрикой игры. Постройка зданий, найм руководителей сфер, реакция на случайные события и прочие действия влияют на данный показатель. Максимальное значение, которое вы можете достигнуть по нему - 360.`;

export const happinessTooltipContent = `Показатель отражает качество вашей работы со стороны жителей города. Чтобы его увеличить необходимо быть внимательным к запросам, которые поступают каждый год. Также этот показатель может увеличить или сократить срок игры. Максимальное значение, которое вы можете достигнуть по нему - 100`;

export const averageIncomeTooltipContent = `Показатель описывает средний уровень достатка жителей. Высчитывается путем деления общего дохода на фиксированное количество жителей. Чем выше показатель, тем более благосклонны к вашим действиям граждане.`;
