import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEventListener, useWindowSize } from "../../hooks";
import { ExclamationIcon } from "../../icons";
import gameMap from "../../images/game-map.jpg";
import initialData from "../../initialData";
import showplaces, { IShowPlace } from "../../initialData/showplaces";
import { ICityBildingPlacePoint, ICoord } from "../../models";
import {
  goToCityPlacePointDone,
  showplacesModalShow,
} from "../../redux/actions";
import { getGoToPoint, getSelectedObjects } from "../../redux/selectors";
import CityBuildingPlacePoint from "../CityBuildingPlacePoint";
import GameMapMini2 from "../GameMapMiniNew";

import style from "./style.module.scss";

const GameMap = () => {
  const [coeff, setCoeff] = useState<number>(0);
  const [startWidth, setOriginWidth] = useState<number>(0);
  const [startHeight, setOriginHeight] = useState<number>(0);
  const [imageWidth, setImageWidth] = useState<number>();
  const [imageHeight, setImageHeight] = useState<number>();
  const [currentMapPosition, setCurrentMapPosition] = useState<number>(0);

  const dispatch = useDispatch();
  const goToPoint = useSelector(getGoToPoint);
  const selectedObjects = useSelector(getSelectedObjects);

  const [points, setPoints] = useState<ICityBildingPlacePoint[]>(
    initialData.cityBuildingPointsInfo
  );
  const [showPlacepoints, setshowPlacepoints] = useState<IShowPlace[]>(
    showplaces
  );

  const startWidthRef = useRef({});
  startWidthRef.current = startWidth;

  const startHeightRef = useRef({});
  startHeightRef.current = startHeight;

  const coeffRef = useRef({});
  coeffRef.current = coeff;
  const imageComponent = useRef<HTMLImageElement>(null);
  const gameComponent = useRef<HTMLDivElement>(null);

  const defineMapSize = () => {
    if (imageComponent && imageComponent.current) {
      const originalHeight = imageComponent.current.clientHeight;
      setOriginHeight(originalHeight);
      const originalWidth = imageComponent.current.clientWidth;
      setOriginWidth(originalWidth);
      if (originalHeight !== 0 || originalWidth !== 0) {
        const coeff = originalWidth / originalHeight;
        setCoeff(coeff);
      }
    }
  };

  const calulatePointPosition = useCallback(
    (imageHeight: number, imageWidth: number) => {
      const imHeight = Number(startHeightRef.current);
      const imWidth = Number(startWidthRef.current);

      const newPoints = points.map((gp) => {
        if (gp.center === undefined)
          return { ...gp, center: { x: 0, y: 0 }, leftCorner: { x: 0, y: 0 } };

        const centerCoeff = calcCoordScaleCoeff(gp.center, imHeight, imWidth);
        let cy = imageHeight * centerCoeff.coeffHeight;
        const cx = imageWidth * centerCoeff.coeffWidth;
        if (cy + 60 > imHeight) cy = imHeight - 60;

        const leftCornerCoeff = calcCoordScaleCoeff(
          gp.leftCorner,
          imHeight,
          imWidth
        );
        let ly = imageHeight * leftCornerCoeff.coeffHeight;
        const lx = imageWidth * leftCornerCoeff.coeffWidth;
        if (ly + 60 > imHeight) ly = imHeight - 60;

        return {
          ...gp,
          center: { x: cx, y: cy },
          leftCorner: { x: lx, y: ly },
        };
      });

      setPoints(newPoints);
      // eslint-disable-next-line
    }, []
  );

  const calulateShowplacePointPosition = useCallback(
    (imageHeight: number, imageWidth: number) => {
      const imHeight = Number(startHeightRef.current);
      const imWidth = Number(startWidthRef.current);

      const newPoints = showplaces.map((gp) => {
        if (gp.coord === undefined) return { ...gp, coord: { x: 0, y: 0 } };

        const centerCoeff = calcCoordScaleCoeff(gp.coord, imHeight, imWidth);
        let cy = imageHeight * centerCoeff.coeffHeight;
        const cx = imageWidth * centerCoeff.coeffWidth;
        if (cy + 60 > imHeight) cy = imHeight - 60;

        return { ...gp, coord: { x: cx, y: cy } };
      });

      setshowPlacepoints(newPoints);
      // eslint-disable-next-line
    },
    []
  );

  const calculateSize = useCallback(() => {
    if (gameComponent && gameComponent.current && coeffRef.current > 0) {
      // const statisticContainer = document.getElementById('statisticContainer');
      // let heightDelta = 0;
      // if (statisticContainer) {
      //   heightDelta = statisticContainer.offsetHeight;
      //   // statisticSwipeUp
      //   const statisticSwipeUp = document.getElementById('statisticSwipeUp');
      //   if (statisticSwipeUp) heightDelta -= 18;
      // }

      const height = gameComponent.current.offsetHeight;
      const width = height * Number(coeffRef.current);

      setImageHeight(height);
      setImageWidth(width);
      calulateShowplacePointPosition(height, width);
      calulatePointPosition(height, width);
    }
  }, [calulatePointPosition, calulateShowplacePointPosition]);

  const calcCoordScaleCoeff = (
    coord: ICoord,
    imageHeight: number,
    imageWidth: number
  ) => {
    const coeffHeight = coord.y / imageHeight;
    const coeffWidth = coord.x / imageWidth;

    return { coeffHeight, coeffWidth };
  };

  useEffect(() => {
    if (goToPoint) {
      const currentWidth = gameComponent.current?.clientWidth;
      const scrollLeft =
        gameComponent.current?.scrollLeft === 0
          ? currentWidth
          : gameComponent.current?.scrollLeft;
      if (goToPoint && currentWidth && scrollLeft) {
        let x = goToPoint.center.x;
        let y = goToPoint.center.y;

        if (
          (x > scrollLeft &&
            x - scrollLeft + currentWidth > currentWidth - 300) ||
          (x < scrollLeft && scrollLeft + currentWidth - x > currentWidth - 300)
        ) {
          x = x - currentWidth / 2;
        } else {
          return;
        }

        y = 0;

        gameComponent.current?.scrollTo(x, y);

        dispatch(goToCityPlacePointDone());
      }
    }
  }, [goToPoint, dispatch]);

  useEffect(() => {
    calculateSize();
  }, [coeff, calculateSize]);

  useWindowSize(calculateSize);

  const onImageLoad = () => {
    defineMapSize();
  };

  // Логика для захвата карты и перемещения
  const isDown = useRef<boolean>(false);
  const scrollLeft = useRef<number>(0);
  const startX = useRef<number>(0);

  useEffect(() => {
    if (imageWidth) {
      gameComponent.current?.scrollTo(imageWidth / 2 - 600, 0);
      setCurrentMapPosition(imageWidth / 2 - 600);
    }
  }, [imageWidth]);

  const handleMouseDown = useCallback(
    (e: any) => {
      if (gameComponent.current === null) return;

      isDown.current = true;

      startX.current = e.pageX - gameComponent.current.offsetLeft;
      scrollLeft.current = gameComponent.current.scrollLeft;
    },
    [gameComponent]
  );

  const handleMouseMove = useCallback(
    (e: any) => {
      if (!isDown.current || gameComponent.current == null) return;
      e.preventDefault();
      const x = e.pageX - gameComponent.current.offsetLeft;
      const walk = (x - startX.current) * 3; //scroll-fast
      const curPosition = scrollLeft.current - walk;
      gameComponent.current.scrollLeft = curPosition;
      setCurrentMapPosition(curPosition);
    },
    [gameComponent]
  );

  useEventListener("mousedown", handleMouseDown, gameComponent.current);
  useEventListener(
    "mouseleave",
    () => {
      isDown.current = false;
    },
    gameComponent.current
  );
  useEventListener(
    "mouseup",
    () => {
      isDown.current = false;
    },
    gameComponent.current
  );
  useEventListener("mousemove", handleMouseMove, gameComponent.current);
  useEventListener(
    "dragstart",
    (e: any) => {
      e.preventDefault();
    },
    gameComponent.current
  );

  const renderPoints = () => {
    if (imageHeight === 0 || imageWidth === 0) return null;

    if (imageHeight && imageWidth) {
      const scaleCoeff = startHeight / imageHeight;
      return points.map((x, index) => {
        const building = selectedObjects.find((_) => _.placeId === x.id);

        return (
          <CityBuildingPlacePoint
            key={index}
            point={x}
            building={building}
            scaleHeightCoeff={scaleCoeff}
          />
        );
      });
    }
  };
  const renderShowPlacePoints = () => {
    return showPlacepoints.map((x, index) => {
      return (
        <div
          key={index}
          className={style.showplaceIcon}
          style={{ top: x.coord.y, left: x.coord.x }}
        >
          <ExclamationIcon
            onClick={() => dispatch(showplacesModalShow(x.id))}
          />
        </div>
      );
    });
  };
  return (
    <>
      <div className={style.gameMapContainer}>
        <div
          className={style.gameMap}
          ref={gameComponent}
          style={
            imageHeight === 0 || imageWidth === 0 ? {} : { height: "100%" }
          }
        >
          <img
            className={style.image}
            src={gameMap}
            ref={imageComponent}
            alt=""
            onLoad={onImageLoad}
            style={
              imageHeight === 0 || imageWidth === 0
                ? {}
                : { height: imageHeight, width: imageWidth }
            }
          />
          {renderShowPlacePoints()}
          {renderPoints()}
        </div>
      </div>
      {gameComponent && gameComponent.current
        && <GameMapMini2 sizeWidth={imageWidth ?? 0} center={gameComponent.current.clientWidth / 2} currentPosition={currentMapPosition} />}

    </>
  );
};

export default GameMap;
