import { Button } from '../../UiKit';
import style from './style.module.scss';

interface IProps {
    isContentComponent?: boolean;
    text: string | (() => JSX.Element);
    onNext?: () => void;
    onFinish: () => void;
    isLastStep?: boolean;
}

const StepTour: React.FunctionComponent<IProps> = (props) => {

    const { text, onNext, isLastStep = false, onFinish, isContentComponent } = props;

    return <div className={style.card}>
        <div className={style.message}>
            {isContentComponent ? (text as (() => JSX.Element))() : <div> <span>{text}</span></div>}
        </div>
        <div className={style.btns}>
            {!isLastStep && <Button isBlue={true} onClick={onNext}>Продолжить</Button>}
            <Button isBlue={false} onClick={() => onFinish()}>{isLastStep ? "Завершить" : "Пропустить обучение"}</Button>
        </div>
    </div>
}

export default StepTour;