import { Action } from "redux";
import { printErrorResponse } from "../../../api/common";
import { IFailAction } from "../../../models/common";
import { SAVE_GAME_INIT, SAVE_GAME_DONE, SAVE_GAME_FAIL, ISaveGameInitAction } from "../../actions/gameApi/saveGame";

export interface IState {
    id: number | null
    inProgress: boolean;
    error: string | null;
}

export const initial: IState = {
    id: 0,
    inProgress: false,
    error: null,
}

export const reducer = (state: IState = initial, action: Action): IState => {
    switch (action.type) {
        case SAVE_GAME_INIT:
            const { id } = action as ISaveGameInitAction;
            return {
                ...state,
                id,
                inProgress: true,
                error: null
            };

        case SAVE_GAME_DONE:

            return {
                ...state,
                id: null,
                inProgress: false,
                error: null
            };

        case SAVE_GAME_FAIL:
            const { error } = action as IFailAction;
            return {
                ...state,
                inProgress: false,
                error: printErrorResponse(error)
            };

        default:
            return state;
    }
}