import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { randomGen7_13, termDaysFormat } from '../../../helpers';
import { formatPrice } from '../../../helpers/formatPrice';
import { CheckedBlueIcon, ChekedCircleIcon } from '../../../icons';
import { ISphereUpgrade } from '../../../models';
import { gameUpgradeObject } from '../../../redux/actions';
import { getTimerCurDate } from '../../../redux/selectors';
import NameValueComponent from '../../BuildingInfo/NameValueComponent';
import { Button } from '../../UiKit';
import style from './style.module.scss';

interface IUpgradeCardProps {
    objectId: string;
    placeId: number;
    upgrade: ISphereUpgrade;
    applyedUpgrade: ISphereUpgrade | undefined;
    disable?: boolean;
}

const UpgradeCard: React.FunctionComponent<IUpgradeCardProps> = (props) => {
    const { upgrade, placeId, objectId, applyedUpgrade, disable } = props;
    const dispatch = useDispatch();
    const time = useSelector(getTimerCurDate);

    const upgradeWasDone = applyedUpgrade ? dayjs(time).diff(applyedUpgrade.startUpgradeTime, 'days') / applyedUpgrade.timecostXnYnZn >= 1 : false;

    const handleOnUpgrade = (e: any) => {
        if (!upgradeWasDone) {

            dispatch(gameUpgradeObject(objectId, placeId, {
                ...upgrade,
                isDone: false,
                startUpgradeTime: time,
                upgradeCoeff: {
                    safetyCoef: randomGen7_13(),
                    comfortCoef: randomGen7_13(),
                    environmentCoef: randomGen7_13(),
                    identityCoef: randomGen7_13(),
                    modernityCoef: randomGen7_13(),
                    controlCoef: randomGen7_13(),
                }
            }));
        }
        e.stopPropagation();
    };

    const upgradeStatus = () => {
        if (upgradeWasDone) return 'Улучшено';
        if (applyedUpgrade && !upgradeWasDone) return 'Улучшается';
    }

    // if(applyedUpgrade) {
    //     console.log(applyedUpgrade, upgradeWasDone, dayjs(time).diff(applyedUpgrade.startUpgradeTime, 'days') / applyedUpgrade.timecostXnYnZn)
    // }

    const upgradeBtn = upgradeWasDone || applyedUpgrade ?
        <Button widthPercents={99} isBlue={false} isStretched={true} onClick={() => { }}>
            <div className={style.upgradeBtnContent}>
                <CheckedBlueIcon /> {upgradeStatus()}
            </div>
        </Button>
        : !disable ? <Button widthPercents={100} isStretched={true} onClick={handleOnUpgrade}>Улучшить</Button> :
            <Button isBlue={false} isGrey={true} widthPercents={100} isStretched={true} onClick={() => { }}>Улучшить</Button>

    return <div className={style.upgradeCard}>

        {upgradeWasDone ?
            <div className={style.header}>
                <ChekedCircleIcon className={style.chedkedCircleIcon} />
                <h1 className={style.heading}>{upgrade.title}</h1>
            </div>
            : <h1 className={style.heading}>{upgrade.title}</h1>
        }
        <label htmlFor="" className={style.description}>{upgrade.desc}</label>

        <h2 className={style.infoHeading}>Влияние на качество городской среды:</h2>
        <div className={style.info}>
            <div className={style.row}>
                <NameValueComponent name={"Безопасность"} value={upgrade.safetyXnYnZn} />
                <NameValueComponent name={"Экологичность и здоровье"} value={upgrade.environmentXnYnZn} />
                <NameValueComponent name={"Современность и актуальность"} value={upgrade.modernityXnYnZn} />
            </div>
            <div className={style.row}>
                <NameValueComponent name={"Комфортность"} value={upgrade.comfortXnYnZn} />
                <NameValueComponent name={"Идентичность и разнообразие"} value={upgrade.identityXnYnZn} />
                <NameValueComponent name={"Эффективность управления"} value={upgrade.controlXnYnZn} />
            </div>
        </div>

        <div className={style.details}>
            <div className={style.names}>
                <div className={style.name}>Cтоимость:</div>
                <div className={style.name}>Срок:</div>
            </div>
            <div className={style.values}>
                <div className={style.value}>{formatPrice(upgrade.costXnYnZn)} тыс ₽</div>
                <div className={style.value}>{termDaysFormat(upgrade.timecostXnYnZn)}</div>
            </div>
            <div className={style.upgradeBtn}>{upgradeBtn}
            </div>
        </div>
    </div >
}
export default UpgradeCard;