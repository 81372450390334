import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BigLockIcon } from '../../icons';
import { onConstructModalShow } from '../../redux/actions';
import { RoundProgressBar } from '../UiKit';
import style from './style.module.scss';

interface ILockedKvadrantProps {
    percent: number;
    isDeconstruct?: boolean;
    placeId: number;
    objectId?: string;
    sphereId?: number;
}

const LockedKvadrant: React.FunctionComponent<ILockedKvadrantProps> = (props) => {
    const { percent, isDeconstruct = false, placeId, objectId, sphereId } = props;
    const [hover, setHover] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isDeconstruct && percent >= 100) {
            return () => dispatch(onConstructModalShow(objectId, sphereId));
        }
        return () => {}; 
    }, [isDeconstruct, dispatch, percent, objectId, sphereId]);

    return (
        <div className={style.lockedKvadrant} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)
        }>
            <div className={style.pic}   >
                <div className={style.icon} >
                    <RoundProgressBar value={percent} placeId={placeId} />
                </div>
                {hover ?
                    <div className={style.percent} >
                        <BigLockIcon />
                    </div>
                    :
                    <div className={style.percent}>{`${Math.round(percent)}%`}</div>
                }
            </div>

            <div className={style.buildingName}>
                Идет {isDeconstruct ? 'снос' : 'строительство'}
            </div>
        </div>);
}

export default LockedKvadrant;
