import { paintDigits } from '../../../helpers';
import style from './style.module.scss';

interface IRowNameValue {
    name: string;
    value: number;
}

const minCoef: number = .7;
const maxCoef: number = 1.3;

const RowNameValue = (props: IRowNameValue) => {
    const { name, value } = props;

    const minValueRouned: number = parseInt((value * minCoef).toFixed(0));
    const maxValueRouned: number = parseInt((value * maxCoef).toFixed(0));

    return <div className={style.row}>
        <div className={style.row__name}>{name}</div>
        {minValueRouned < maxValueRouned ?
            <div className={style.row__value}>
                от &nbsp;
                {paintDigits(minValueRouned)}
                &nbsp;  до&nbsp;
                {paintDigits(maxValueRouned)}
            </div>
            :
            <div className={style.row__value}>
                от&nbsp;
                {paintDigits(maxValueRouned)}
                &nbsp;  до&nbsp;
                {paintDigits(minValueRouned)}
            </div>}
    </div>;
}

export default RowNameValue;