import React from 'react';
import { moneyToString } from '../../helpers';
import { FireIcon } from '../../icons';
import { IRandomEventAction } from '../../models';
import NameValueComponent from '../BuildingInfo/NameValueComponent';
import style from './style.module.scss';

interface IRandomEvenContentProps {
    eventName: string;
    action: IRandomEventAction;
}

const RandomEventResultContent: React.FunctionComponent<IRandomEvenContentProps> = (props) => {
    const { action, eventName } = props;
    return action && eventName ? <div className={style.randomEvenResultContent}  >
        <div className={style.notificationResultCardNews}>
            <FireIcon />
            <div className={style.news}>
                <div className={style.source}>
                    <div className={style.sourceName}>{"Город.ОК"}</div>
                    <div className={style.sourceNameTlg}>{'@GorodOK.News'}</div>
                </div>
                <div className={style.content}>{action.eventResult}</div>
            </div>
        </div>
        <div className={style.notificationCardNews}>
            <div className={style.source}>
                <FireIcon />
                <div className={style.sourceName}>{"Город.ОК"}</div>
                <div className={style.sourceNameTlg}>{'@GorodOK.News'}</div>
            </div>
            <div className={style.content}>{eventName}</div>
        </div>
        <h1 className={style.results}>Результаты</h1>
        <div className={style.details}>
            <div className={style.info}>
                <NameValueComponent name={"Безопасность"} value={action.safetyrandomevent} />
                <NameValueComponent name={"Экологичность и здоровье"} value={action.environmentrandomevent} />
                <NameValueComponent name={"Современность и актуальность"} value={action.modernityrandomevent} />
                <NameValueComponent name={"Индекс инвестиционной привлекательности"} value={action.businessscale} />
            </div>
            <div className={style.info}>
                <NameValueComponent name={"Комфортность"} value={action.comfortrandomevent} />
                <NameValueComponent name={"Идентичность и разнообразие"} value={action.identityrandomevent} />
                <NameValueComponent name={"Эффективность управления"} value={action.controlrandomevent} />
                <NameValueComponent name={"Одобрение государством"} value={action.govscale} />
            </div>
        </div>
        <div className={style.budget}>
            <div className={style.name}>Стоимость выбора опции по событию:</div>
            <div className={style.value}>{moneyToString(action.randomeventcost)}</div>
        </div>
        <div className={style.budget}>
            <div className={style.name}>Доход от выбора по событию:</div>
            <div className={style.value}>{moneyToString(action.randomeventplus)}</div>
        </div>
    </div> : null
}
export default RandomEventResultContent;