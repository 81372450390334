import { useState, useEffect } from 'react';
import gameMap from '../../images/minimap.png';
import style from './style.module.scss';

type IGameMapMiniProps = {
    center: number;
    currentPosition: number;
    sizeWidth: number;
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const GameMapMini: React.FC<IGameMapMiniProps> = ({ currentPosition, center, sizeWidth }) => {
    // размер окна минус левое меню - то есть размер видимого игрового поля в px
    let { width: windowWidth } = useWindowDimensions();
    // windowWidth -= 320;
    //размер минимапа в px
    const mapWidth = windowWidth / 3;

    // размер прямоугольника который скользит по миникарте в процентах
    const mapRectWidth = 100 * (windowWidth) / (sizeWidth);
    const drawCalcPercent = (val: number) => `calc(${val}%)`;



    // позиция прямоугольника скользящего по миникарте в процентах от левого края миникарты в процентах
    let positionFromLeftInPersent = ((currentPosition) / (sizeWidth)) * 100;
    if (positionFromLeftInPersent > 100)
        positionFromLeftInPersent = 100;
    if (positionFromLeftInPersent < 0)
        positionFromLeftInPersent = 0;

    let widthLeftBlur = positionFromLeftInPersent
    if (widthLeftBlur + mapRectWidth > 100)
        widthLeftBlur = 100 - mapRectWidth;
    if (widthLeftBlur < 0)
        widthLeftBlur = 0

    let leftCurpos = positionFromLeftInPersent;
    if (leftCurpos + mapRectWidth > 100)
        leftCurpos = 100 - mapRectWidth;
    if (leftCurpos < 0)
        leftCurpos = 0;

    let leftStyle = {
        left: 0,
        width: drawCalcPercent(widthLeftBlur)
    };

    let centerStyle = {
        left: drawCalcPercent(
            leftCurpos + mapRectWidth > 100 ?
                (100 - mapRectWidth - 0) :
                leftCurpos
        ),
        width: `${leftCurpos + mapRectWidth >
            100 ? mapRectWidth :
            mapRectWidth + 0}%`
    }

    let widthRightBlur = (100 - positionFromLeftInPersent - mapRectWidth);
    if (widthRightBlur < 0)
        widthRightBlur = 0;

    let rightStyle = {
        right: 0,
        width: drawCalcPercent(widthRightBlur > 100 ? 100 : widthRightBlur)
    };

    return <div className={style.miniMapContainer} style={{ left: center }} >
        <img src={gameMap} alt="" style={{
            height: `${7}rem`,
            width: `${mapWidth / 10}rem`
        }} />
        <div className={style.miniMapContainerBorder} />
        <div className={style.blur} style={leftStyle} />
        <div className={style.currentPosition} style={centerStyle} />
        <div className={style.blur} style={rightStyle} />
    </div>
}

export default GameMapMini;