import ModalComponent from '../ModalComponent';
import { Button } from '../UiKit';
import style from './style.module.scss';

const KvartalModal: React.FunctionComponent<{ closeAction: () => void; kvartalIncome: number; }> = ({ closeAction, kvartalIncome }) => <ModalComponent
    showBackIcon={false} closeAction={closeAction} height={'15rem'} >
    <div className={style.resultContent}>
        <div className={style.warnLabel}>
            Ваш доход за прошлый квартал составил: {kvartalIncome} тыс. р.
        </div>
        <div className={style.btns}>
            <Button isStretched={true} isBlue={false} onClick={closeAction}>Ок</Button>
        </div>
    </div>
</ModalComponent >

export default KvartalModal;