import G1 from './G1.png';
import G1_hover from './G1_hover.png';
import G2 from './G2.png';
import G2_hover from './G2_hover.png';
import G3 from './G3.png';
import G3_hover from './G3_hover.png';
import H1 from './H1.png';
import H1_hover from './H1_hover.png';
import H2 from './H2.png';
import H2_hover from './H2_hover.png';
import H3 from './H3.png';
import H3_hover from './H3_hover.png';
import O1 from './O1.png';
import O1_hover from './O1_hover.png';
import O2 from './O2.png';
import O2_hover from './O2_hover.png';
import O3 from './O3.png';
import O3_hover from './O3_hover.png';
import S1 from './S1.png';
import S1_hover from './S1_hover.png';
import S2 from './S2.png';
import S2_hover from './S2_hover.png';
import S3 from './S3.png';
import S3_hover from './S3_hover.png';
import C from './C.png';
import R from './R.png';

export interface IPhotoObject {
    id: string,
    img: string,
    imgHover: string
}

const buildingObjects = [
  {
    id: 'G1',
    img: G1,
    imgHover: G1_hover,
  },
  {
    id: 'G2',
    img: G2,
    imgHover: G2_hover,
  },
  {
    id: 'G3',
    img: G3,
    imgHover: G3_hover,
  },
  {
    id: 'H1',
    img: H1,
    imgHover: H1_hover,
  },
  {
    id: 'H2',
    img: H2,
    imgHover: H2_hover,
  },
  {
    id: 'H3',
    img: H3,
    imgHover: H3_hover,
  },
  {
    id: 'O1',
    img: O1,
    imgHover: O1_hover,
  },
  {
    id: 'O2',
    img: O2,
    imgHover: O2_hover,
  },
  {
    id: 'O3',
    img: O3,
    imgHover: O3_hover,
  },
  {
    id: 'S1',
    img: S1,
    imgHover: S1_hover,
  },
  {
    id: 'S2',
    img: S2,
    imgHover: S2_hover,
  },
  {
    id: 'S3',
    img: S3,
    imgHover: S3_hover,
  },
  {
    id: 'C',
    img: C,
    imgHover: C,
  },
  {
    id: 'R',
    img: R,
    imgHover: R,
  },
] as IPhotoObject[];

export function getPhotoObject(objecid: string) {
    const bo = buildingObjects.filter(o => o.id === objecid);

    return bo[0];
}