import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import MenuHeader from ".";
import { MenuLevelEmum } from "..";
import { AlertNoIcon, AlertYesIcon, LogoIcon } from "../../../icons";
import { modalHide } from "../../../redux/actions";
import { getNotifications } from "../../../redux/selectors";
import style from './style.module.scss';

interface IMainMenuHeaderProps {
    menuLevelSet: (level: MenuLevelEmum) => void;
}
const MainMenuHeader = (props: IMainMenuHeaderProps) => {
    const { menuLevelSet } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const notifications = useSelector(getNotifications);
    const alerts = notifications.length > 0;

    return <MenuHeader>
        <div className={style.logo} onMouseDown={() => { dispatch(modalHide()); history.push('/profile'); }}>
            <LogoIcon />
            <div className={style.title}>Urbanizator</div>
        </div>

        <div className={style.info}>
            {alerts ? <AlertYesIcon onClick={() => menuLevelSet(MenuLevelEmum.Notifications)} /> : <AlertNoIcon />}
        </div>

    </MenuHeader>
}

export default MainMenuHeader;