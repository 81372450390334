import { Action } from "redux";
import { IAuthResult } from "../../api/auth";
import { IErrorResponse } from "../../api/common";

export const LOGIN_INIT = 'LOGIN_INIT';
export const LOGIN_DONE = 'LOGIN_DONE';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export interface ILoginInitAction extends Action {
    username: string;
    password: string;
}

export interface ILoginDoneAction extends Action {
    result: IAuthResult;
}

export interface IFailAction extends Action {
    error: IErrorResponse;
}

export function loginInit(username: string, password: string) {
    return { type: LOGIN_INIT, username, password } as ILoginInitAction;
}

export function loginDone(result: IAuthResult) {
    return { type: LOGIN_DONE, result } as ILoginDoneAction;
}

export function loginFail(error: IErrorResponse) {
    return { type: LOGIN_FAIL, error } as IFailAction;
}

export function logout() {
    return { type: LOGOUT } as IFailAction;
}