import { PauseIcon } from '../../../icons';
import { Speed } from '../../../models';
import style from './style.module.scss';



interface ISpeedItemsComboboxProps {
    onSelect: (s: Speed) => void;
}

const SpeedItemsCombobox: React.FunctionComponent<ISpeedItemsComboboxProps> = (props) => {

    const { onSelect } = props;

    return <div className={style.speedItemsCombobox}>
        <div className={style.speedItem} onMouseDown={() => onSelect(0)}><PauseIcon className={style.pauseSvg}/></div>

        <div className={style.speedItem} onMouseDown={() => onSelect(1)}>x1</div>
        <div className={style.speedItem} onMouseDown={() => onSelect(2)}>x2</div>
        <div className={style.speedItem} onMouseDown={() => onSelect(3)}>x3</div>
        <div className={style.speedItem} onMouseDown={() => onSelect(4)}>x4</div>
        <div className={style.speedItem} onMouseDown={() => onSelect(5)}>x5</div>
    </div>
}

export default SpeedItemsCombobox;