import React from 'react';
import cn from 'classnames';
import { CircleIcon } from '../../../icons';
import './style.scss';

const ROUND_LENGTH: number = 210.48;

interface IProps {
    value: number;
    placeId: number;
}

const RoundProgressBar = ({ value, placeId }: IProps) => {

    const className = `spider-${Date.now()}-${placeId}`;
    const createBar = () => {
        const bar = ROUND_LENGTH * value / 100;
        var style = document.createElement('style');
        style.innerHTML = `.${className} > * > .bar {   stroke-dashoffset: calc(${ROUND_LENGTH}px - ${bar}px) !important;}`;
        document.getElementsByTagName('head')[0].appendChild(style);
    }

    createBar();
    return (
        <div className={cn('spider', className)}>
            <CircleIcon className="round" />
        </div>
    );
}

export default RoundProgressBar;