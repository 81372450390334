import MenuHeader from ".";
import { BackIcon } from "../../../icons";
import style from './style.module.scss';

interface IBackMenuHeaderProps {
    title: string;
    onClick: (e:any) => void;
}

const BackMenuHeader: React.FunctionComponent<IBackMenuHeaderProps> = (props) => {

    const { title, onClick } = props;

    return <MenuHeader>
        <div onMouseDown={onClick}>
            <div className={style.logo}>
                <BackIcon />
                <div className={style.title}>{title}</div>
            </div>
        </div>
    </MenuHeader>;
}

export default BackMenuHeader;