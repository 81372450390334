import { Action } from "redux";
import { LEARNING_COMPLEATED } from "../../actions/game/learning";

export const initial = false;

export const reducer = (state: boolean = initial, action: Action): boolean => {
    switch (action.type) {
        case LEARNING_COMPLEATED:
            return true;

        default:
            return state;
    }
}