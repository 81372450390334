import { Action } from "redux";
import { ITimer, Speed } from "../../../models";
import { ITimerSpeed, TIMER_RETURN_PREVIOUS_SPEED, TIMER_SPEED, TIMER_START, TIMER_STOP, TIMER_UPDATE } from "../../actions/game";
import dayjs from 'dayjs';

export const initial: ITimer = {
    startDate: Date.now(),
    curDate: Date.now(),
    speed: 1,
    previousSpeed: 1,
    daysFromStart: 0,
    started: false
}

export const reducer = (state: ITimer = initial, action: Action): ITimer => {
    switch (action.type) {
        case TIMER_START:
            return {
                ...state,
                startDate: Date.now(),
                started: true
            };

        case TIMER_STOP:
            console.log('timer stop')
            return {
                ...state,
                started: false
            };

        case TIMER_SPEED:
            const { speed } = action as ITimerSpeed;
            return {
                ...state,
                speed: speed,
                previousSpeed: state.speed
            };

        case TIMER_RETURN_PREVIOUS_SPEED:
            return {
                ...state,
                speed: (state.previousSpeed === 0 ? state.speed : state.previousSpeed)
            };

        case TIMER_UPDATE:

            if (state.started) {
                const delta = calcSpeedDelta(state.speed);
                const daysFromStart = state.daysFromStart + (delta ?? 0);
                return {
                    ...state,
                    daysFromStart: daysFromStart,
                    curDate: dayjs(state.startDate).add(daysFromStart, 'days').valueOf()
                };
            }

            return state;

        default:
            return state;
    }
}

const calcSpeedDelta = (speed: Speed) => {

    switch (speed) {
        case 1:
            return 1 / 30;
        case 2:
            return 1 / 2;
        case 3:
            return 2 / 3;
        case 4:
            return 3 / 4;
        case 5:
            return 1;
    }
}