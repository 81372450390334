import { all, fork } from 'redux-saga/effects';
import { authSaga } from './auth';
import { getGamesSaga } from './gameApi/getGames';
import { createGameSaga } from './gameApi/createGame';
import { saveGameSaga } from './gameApi/saveGame';
import { getGameSaga } from './gameApi/getGame';
import { addGameStatsSaga } from './gameApi/addGameStats';
import { scoreGameSaga } from './gameApi/scoreGame';

export function* configureSaga() {
    yield all([
        fork(authSaga),
        fork(getGamesSaga),
        fork(scoreGameSaga),
        fork(createGameSaga),
        fork(saveGameSaga),
        fork(getGameSaga),
        fork(addGameStatsSaga),
    ]);
}
