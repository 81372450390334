import React from 'react';
import style from './style.module.scss';

interface IInputProps {
    type: string;
    label?: string;
    value?: any;
    required: boolean;
}
const Input: React.FunctionComponent<IInputProps> = (props) => {
    const { label, value, type, required } = props;
    return <div className={style.input}>
        <label htmlFor="" className={style.textInputLabel}>{label}</label>
        <input  {...required} type={type} className={style.textInput} {...value} />
    </div>
}

export default Input;