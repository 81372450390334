import { Action } from "redux";
import { IErrorResponse } from "../../../api/common";
import { IFailAction } from "../../../models/common";

export const SCORE_GAME_INIT = 'SCORE_GAME_INIT';
export const SCORE_GAME_DONE = 'SCORE_GAME_DONE';
export const SCORE_GAME_FAIL = 'SCORE_GAME_FAIL';

export interface IScoreGameInitAction extends Action {
    id: number,
    value: number
}

export interface IScoreGameDoneAction extends Action {
}

export function scoreGameInit(id: number, score: number) {
    return { type: SCORE_GAME_INIT, id, value: score } as IScoreGameInitAction;
}

export function scoreGameDone() {
    return { type: SCORE_GAME_DONE } as IScoreGameDoneAction;
}

export function scoreGameFail(error: IErrorResponse) {
    return { type: SCORE_GAME_FAIL, error } as IFailAction;
}