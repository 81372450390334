import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { randomGen7_13 } from '../../helpers';
import { formatPrice } from '../../helpers/formatPrice';
import { CheckIcon } from '../../icons';
import { getPhotoManager } from '../../images/managers';
import { IModalDialog, ISphereManager } from '../../models';
import { hireManager, IObjectKeyInfo } from '../../redux/actions';
import { getHiredManagers, getModalDialog } from '../../redux/selectors';
import NameValueComponent from '../BuildingInfo/NameValueComponent';
import { Button } from '../UiKit';
import style from './style.module.scss';
import { clearMenuStateOnHireManager } from '../../redux/actions/menu';
import { MenuLevelEmum } from '../GameMenu';

interface IManagerCardProps {
    manager: ISphereManager;
    isHired: boolean;
    objectId: string;
    isFromSphere?: boolean;
}

const ManagerCard: React.FunctionComponent<IManagerCardProps> = (props) => {
    const { manager, objectId, isHired, isFromSphere = false } = props;
    const hiredManagers = useSelector(getHiredManagers);
    const modalDialoges = useSelector(getModalDialog);

    //TODO зарефакторить: сейчас получаем placeId из открытого диалога который может не существовать в данный момент
    let placeId = -1;
    if (modalDialoges) {
        const objectDoneModal = modalDialoges.filter((e: IModalDialog) => e.dialogType === "ObjectDoneModal")[0];
        if (objectDoneModal) placeId = objectDoneModal.placeId ?? -1;
    }

    // если на здании уже закреплен руководитель сферы то больше нелья нанять
    const isManagerAlreadyOnObject = hiredManagers.filter(e =>
        e.objectIds.some(e => e.objectId === objectId && e.placeId === placeId))[0] !== undefined;

    const isCurrentMangerHired = hiredManagers.filter(e => e.managerId === manager.id)[0];
    let isCurrentManagerHiredOnObject = false;
    if (isCurrentMangerHired) {
        isCurrentManagerHiredOnObject = isCurrentMangerHired.objectIds.some(e => e.objectId === objectId && e.placeId === placeId);
    }
    const dispatch = useDispatch();
    // чтобы получить placeId

    const handleOnHire = () => {
        const rndCoef = [randomGen7_13(), randomGen7_13(), randomGen7_13(), randomGen7_13(), randomGen7_13(), randomGen7_13()];
        dispatch(hireManager({ objectId, placeId: placeId } as IObjectKeyInfo, manager, rndCoef));
        dispatch(clearMenuStateOnHireManager(MenuLevelEmum.Level0));
    };


    return <div className={style.managerCard}  >
        <div className={style.header}>
            <img src={getPhotoManager(manager.id)} alt='' />
            <div className={style.name}>{manager.title}</div>
            {isHired ? <div className={style.isHired}> <CheckIcon /> <span>Нанят</span> </div> : null}
        </div>
        <div className={style.content}>
            <div className={style.description}>
                {manager.desc}
            </div>
            <div className={style.info}>
                <NameValueComponent name={"Безопасность"} value={manager.safetymanagerXnYn} />
                <NameValueComponent name={"Экологичность и здоровье"} value={manager.environmentmanagerXnYn} />
                <NameValueComponent name={"Современность и актуальность"} value={manager.modernitymanagerXnYn} />
                <NameValueComponent name={"Комфортность"} value={manager.comfortmanagerXnYn} />
                <NameValueComponent name={"Идентичность и разнообразие"} value={manager.identitymanagerXnYn} />
                <NameValueComponent name={"Эффективность управления"} value={manager.controlmanagerXnYn} />
                <NameValueComponent name={"Индекс инвестиционной привлекательности"} value={manager.businessscale} />
                <NameValueComponent name={"Одобрение государством"} value={manager.govscale} />
            </div>
            <div className={style.cost}>
                <div className={style.name}>Cтоимость:</div>
                <div className={style.value}>{formatPrice(manager.managerwageXnYn)} <span> тыс ₽/день</span></div>

                <div className={style.hireBtn}>{isFromSphere && objectId && !isCurrentManagerHiredOnObject && !isManagerAlreadyOnObject &&
                    <Button widthPercents={100} isStretched={false} onClick={handleOnHire}>Нанять</Button>}</div>
            </div>
        </div>
    </div>
}
export default ManagerCard;