import dayjs from 'dayjs';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import initialData from "../initialData";
import { changeMayorTerm, gameFinished, gameOverGovScaleModalShow, gameOverModalShow, grantForBusinessModalShow, InverviewModalShow, kvartalModalShow, moneyDeficitModalShow, resultModalShow, setEstimatedIndicators, timerStop, yearResultSet } from "../redux/actions";
import { ICityObjectProgress, IEstimatedIndicators } from "../models";
import { getTimerCurDate } from "../redux/selectors";
import { store } from "../store/configureStore";

const differencebuildingcoef = 1; // Коэффициент влияния разнообразия на сферы
const scorecoef = 2.7780;
const locationcoef = 1;
const locationscorecoef = 1;

export function useMainGameLoop() {
    const dispatch = useDispatch();

    const curDate = useSelector(getTimerCurDate);

    // Игрогвые расчеты
    useEffect(() => {

        // маппим значения  estimatedIndicators в массив

        // Текущее значение показателя безопасности - от 0 до 60
        // safety = (∑safetyXn) + ∑safetymanagerXn + (∑safetymanagerXnYn) + (∑safetyrandomevent) + (safetybase)

        // Текущее значение показателя комфортности - от 0 до 60
        // comfort = (∑comfortXn) + ∑comfortmanagerXn + (∑comfortmanagerXnYn) + (∑comfortrandomevent) + (comfortbase)

        // Текущее значение показателя экологичности и здоровья - от 0 до 60
        // environment = (∑environmentXn) + ∑environmentmanagerXn + (∑environmentmanagerXnYn) + (∑environmentrandomevent) + (environmentbase)

        // Текущее значение показателя индентичности и разнообразия - от 0 до 60
        // identity = (∑identityXn) + ∑identitymanagerXn + (∑identitymanagerXnYn) + (∑identityrandomevent) + (∑identitylocation) + (∑identitydifferencebuilding) + (identitybase)

        // Текущее значение показателя современности и актуальности - от 0 до 60
        // modernity = (∑modernityXn) + ∑modernitymanagerXn + (∑modernitymanagerXnYn) + (∑modernityrandomevent) + (modernitybase)

        // Текущее значение показателя эффективности управления - от 0 до 60
        // control = (∑controlXn) + ∑controlmanagerXn + (∑controlmanagerXnYn) + (∑controlrandomevent) + (∑controllocation) + (controlbase)

        // Итоговые показатели
        const { cityConstInfo } = initialData;
        let safetyResult = cityConstInfo.safetybase;
        let comfortResult = cityConstInfo.comfortbase;
        let environmentResult = cityConstInfo.environmentbase;
        let identityResult = cityConstInfo.identitybase;
        let modernityResult = cityConstInfo.modernitybase;
        let controlResult = cityConstInfo.controlbase;
        let businessscaleResult = cityConstInfo.businessscale;
        let govscaleResult = cityConstInfo.govscale;
        // Показатели по сфере Xn
        // 0 Жилье и прилегающее пространство
        // 1 Озелененное пространство
        // 2 Общественно-деловая инфраструктура
        // 3 Социально-досуговая инфраструктура
        // 4 Общегородское пространство
        // 5 Улично-дорожная сеть
        const safetyXn = [0, 0, 0, 0, 0, 0];
        const comfortXn = [0, 0, 0, 0, 0, 0];
        const environmentXn = [0, 0, 0, 0, 0, 0];
        const identityXn = [0, 0, 0, 0, 0, 0];
        const modernityXn = [0, 0, 0, 0, 0, 0];
        const controlXn = [0, 0, 0, 0, 0, 0];

        let identitydifferencebuilding = 0;

        const { game: gameState } = store.getState();

        const { selectedObjects } = gameState;

        const identitylocation = calcIdentiryLocation(selectedObjects, curDate);
        const controllocation = identitylocation;

        let dailyrevenueN = 0, managerwageN = 0;
        const dailycostN = [0, 0, 0, 0, 0, 0];

        selectedObjects.forEach(so => {
            const sphere = initialData.citySpheresInfo.find(_ => _.sphereId === so.sphereId);
            const cityObject = sphere?.objects.find(_ => _.id === so.objectId);

            if (cityObject) {

                const isBuild = dayjs(curDate).diff(so.startBuildTime, 'days') >= cityObject.params.timecostXnYn;

                // Если здание построенно и не снесено, то учитываем его в расчетах
                if (isBuild && !so.desctuctCoef && so.constructCoef) {
                    // console.log('Buid coeff: ', so.constructCoef, object);

                    const isBuildingNeedToZeroInfluenceParams = calcBuildingInfluence(so, curDate);

                    safetyXn[so.sphereId - 1] += cityObject.params.safetyXnYn > 0 && isBuildingNeedToZeroInfluenceParams ? 0 : cityObject.params.safetyXnYn * so.constructCoef.safetyCoef;
                    comfortXn[so.sphereId - 1] += cityObject.params.comfortXnYn > 0 && isBuildingNeedToZeroInfluenceParams ? 0 : cityObject.params.comfortXnYn * so.constructCoef.comfortCoef;
                    environmentXn[so.sphereId - 1] += cityObject.params.environmentXnYn > 0 && isBuildingNeedToZeroInfluenceParams ? 0 : cityObject.params.environmentXnYn * so.constructCoef.environmentCoef;
                    identityXn[so.sphereId - 1] += cityObject.params.identityXnYn > 0 && isBuildingNeedToZeroInfluenceParams ? 0 : cityObject.params.identityXnYn * so.constructCoef.identityCoef;
                    modernityXn[so.sphereId - 1] += cityObject.params.modernityXnYn > 0 && isBuildingNeedToZeroInfluenceParams ? 0 : cityObject.params.modernityXnYn * so.constructCoef.modernityCoef;

                    controlXn[so.sphereId - 1] += cityObject.params.controlXnYn * so.constructCoef.controlCoef;

                    dailyrevenueN += cityObject.params.dailyrevenueXnYnbase;
                    dailycostN[so.sphereId - 1] += cityObject.params.dailycostXnYnbase;

                    so.upgrades.forEach(up => {

                        const upgradeWasDone = dayjs(curDate).diff(up.startUpgradeTime, 'days') / up.timecostXnYnZn >= 1;
                        if (upgradeWasDone && up.upgradeCoeff) {
                            // console.log('Upgrade coeff: ', up.upgradeCoeff);
                            safetyXn[so.sphereId - 1] += up.safetyXnYnZn * up.upgradeCoeff.safetyCoef;
                            comfortXn[so.sphereId - 1] += up.comfortXnYnZn * up.upgradeCoeff.comfortCoef;
                            environmentXn[so.sphereId - 1] += up.environmentXnYnZn * up.upgradeCoeff.environmentCoef;
                            identityXn[so.sphereId - 1] += up.identityXnYnZn * up.upgradeCoeff.identityCoef;
                            modernityXn[so.sphereId - 1] += up.modernityXnYnZn * up.upgradeCoeff.modernityCoef;
                            controlXn[so.sphereId - 1] += up.controlXnYnZn * up.upgradeCoeff.controlCoef;
                            dailyrevenueN += up.dailyrevenueXnYnZn;
                        }
                    });
                }
                // Если здание снесено, то учитываем его в расчетах
                if (so.startDeconstructionTime) {
                    const isDestruct = dayjs(curDate).diff(so.startDeconstructionTime, 'days') >= cityObject.params.undotimecostXnYn;
                    if (isDestruct && so.desctuctCoef) {
                        // console.log('Destroy coeff: ', so.desctuctCoef);
                        safetyXn[so.sphereId - 1] = safetyXn[so.sphereId - 1] - cityObject.params.safetyXnYn * so.desctuctCoef.safetyCoef;
                        comfortXn[so.sphereId - 1] = comfortXn[so.sphereId - 1] - cityObject.params.comfortXnYn * so.desctuctCoef.comfortCoef;
                        environmentXn[so.sphereId - 1] = environmentXn[so.sphereId - 1] - cityObject.params.environmentXnYn * so.desctuctCoef.environmentCoef;
                        identityXn[so.sphereId - 1] = identityXn[so.sphereId - 1] - cityObject.params.identityXnYn * so.desctuctCoef.identityCoef;
                        modernityXn[so.sphereId - 1] = modernityXn[so.sphereId - 1] - cityObject.params.modernityXnYn * so.desctuctCoef.modernityCoef;
                        controlXn[so.sphereId - 1] = controlXn[so.sphereId - 1] - cityObject.params.controlXnYn * so.desctuctCoef.comfortCoef;
                    }
                }
            }

            const clonesCount = selectedObjects.filter(_ => _.objectId === so.objectId).length;
            let clonesCoeff = 0;
            if (clonesCount >= 3) clonesCoeff = -1 * (clonesCount - 2); // если объект существует в 2 экз-х = 0, если объект существует в 4 экз-х = -2
            else if (clonesCount === 1) clonesCoeff = 1;
            identitydifferencebuilding += clonesCoeff * differencebuildingcoef;
        });

        identityResult += (identitydifferencebuilding + identitylocation);
        controlResult += controllocation;

        const { hiredManagers } = gameState;

        hiredManagers.forEach(m => {
            // пробегаем по обьектам, на которые наняты менеджеры
            m.objectIds.forEach(_ => {
                if (m.hireCoef) {
                    // console.log('Hire coeff: ', m.hireCoef);
                    safetyResult += m.manager.safetymanagerXnYn * m.hireCoef.safetyCoef;
                    comfortResult += m.manager.comfortmanagerXnYn * m.hireCoef.comfortCoef;
                    environmentResult += m.manager.environmentmanagerXnYn * m.hireCoef.environmentCoef;
                    identityResult += m.manager.identitymanagerXnYn * m.hireCoef.identityCoef;
                    modernityResult += m.manager.modernitymanagerXnYn * m.hireCoef.modernityCoef;
                    controlResult += m.manager.controlmanagerXnYn * m.hireCoef.controlCoef;
                    businessscaleResult += m.manager.businessscale;
                    govscaleResult += m.manager.govscale;
                    managerwageN += m.manager.managerwageXnYn;
                }
            })
        });

        const { randomEventsResults } = gameState;

        randomEventsResults.forEach(re => {
            safetyResult += re.safetyrandomevent;
            comfortResult += re.comfortrandomevent;
            environmentResult += re.environmentrandomevent;
            identityResult += re.identityrandomevent;
            modernityResult += re.modernityrandomevent;
            controlResult += re.controlrandomevent;
            businessscaleResult += re.businessscale;
            govscaleResult += re.govscale
        });

        safetyResult += safetyXn.reduce((a, b) => a + b);
        comfortResult += comfortXn.reduce((a, b) => a + b);
        environmentResult += environmentXn.reduce((a, b) => a + b);
        identityResult += identityXn.reduce((a, b) => a + b);
        modernityResult += modernityXn.reduce((a, b) => a + b);
        controlResult += controlXn.reduce((a, b) => a + b);

        // Расчет удовлетворенности по каждой сфере
        const satisfactionXnTempDynamic = [0, 0, 0, 0, 0, 0]
            .map((_, i) => safetyXn[i] + comfortXn[i] + environmentXn[i] + identityXn[i] + modernityXn[i] + controlXn[i]);

        let satisfaction = initialData.cityConstInfo.satisfactionbase + satisfactionXnTempDynamic.reduce((a, b) => a + b) / satisfactionXnTempDynamic.length;

        const satisfactionXnTempYear = [0, 0, 0, 0, 0, 0]
            .map((_, i) => {
                const sat = safetyXn[i] + comfortXn[i] + environmentXn[i] + identityXn[i] + modernityXn[i] + controlXn[i];
                if (sat === 0) {
                    return - 1;
                }

                return sat;
            });

        const minSatisfactionXn = satisfactionXnTempYear.sort((a, b) => a - b);
        const S3TXn = minSatisfactionXn[0] + minSatisfactionXn[1] + minSatisfactionXn[2];
        // console.log(safetyXn, comfortXn, environmentXn, identityXn, modernityXn, controlXn)
        const satisfactionXn = satisfactionXnTempYear.map(TXn => {
            const sXn = S3TXn !== 0 ? TXn / S3TXn * 100 : 0;
            if (sXn < 0) {
                return 25;
            }
            return sXn;
        });

        //Текущее значение индекса городской среды. Расчитывается как среднеарифметическая всех косвенных показателей - от 0 до 360
        // urbanquality = safety + comfort + environment + identity + modernity + control

        let currentMoney = initialData.cityConstInfo.moneybase;
        if (gameState.estimatedIndicators.money !== 0) {
            currentMoney = gameState.estimatedIndicators.money;
        }

        // Случайные события учитываем один раз
        randomEventsResults.forEach(re => {
            currentMoney -= re.randomeventcost;
            currentMoney += re.randomeventplus;
            re.randomeventcost = 0;
            re.randomeventplus = 0;
        });

        // Текущие ежедневные доходы
        const moneyDplus = initialData.cityConstInfo.moneyDplusbase + dailyrevenueN;

        // Годовой бюджет 
        const moneyyear = moneyDplus * 365;

        // Текущая процентная ставка за дефицит
        let deficitrate = 0;
        const deficitroof1 = moneyyear * initialData.cityConstInfo.deficit1 * -1;
        const deficitroof2 = moneyyear * initialData.cityConstInfo.deficit2 * -1;
        const deficitroof3 = moneyyear * initialData.cityConstInfo.deficit3 * -1;
        if (0 > currentMoney && currentMoney >= deficitroof1) {
            deficitrate = initialData.cityConstInfo.deficitminimum;
        }
        else if (deficitroof1 > currentMoney && currentMoney >= deficitroof2) {
            deficitrate = initialData.cityConstInfo.deficitmedium;
        }
        else if (deficitroof2 > currentMoney && currentMoney >= deficitroof3) {
            deficitrate = initialData.cityConstInfo.deficitmaximum;
        }

        // Текущие ежедневные расходы
        const moneyDminus = initialData.cityConstInfo.moneyDminusbase + dailycostN.reduce((a, b) => a + b) + (currentMoney * deficitrate / 365) + managerwageN;

        let moneyNew = currentMoney + moneyDplus - moneyDminus;

        safetyResult = safetyResult >= 60 ? 60 : safetyResult;
        comfortResult = comfortResult >= 60 ? 60 : comfortResult;
        environmentResult = environmentResult >= 60 ? 60 : environmentResult;
        identityResult = identityResult >= 60 ? 60 : identityResult;
        modernityResult = modernityResult >= 60 ? 60 : modernityResult;
        controlResult = controlResult >= 60 ? 60 : controlResult;
        businessscaleResult = businessscaleResult >= 100 ? 100 : businessscaleResult;
        govscaleResult = govscaleResult >= 100 ? 100 : govscaleResult
        const urbanquality = Math.round(safetyResult + comfortResult + environmentResult + identityResult + modernityResult + controlResult);

        const { svobodniy, svobodniyup } = initialData.cityConstInfo;
        const svobodniyFresh = svobodniy * svobodniyup * satisfaction;
        const percitizen = ((moneyDplus * 30) / (svobodniyFresh * (urbanquality / 6) * svobodniyup)) * 4.9;
        const score = (urbanquality / 6) * scorecoef;

        // Итоги года

        const { timer: { startDate: startGameDate }, yearsResults } = gameState;
        const yrLen = yearsResults.length

        const years = Math.floor(dayjs(curDate).diff(startGameDate, 'years')) + 1;
        const isNewYear = years - 1 > 0 && years > yearsResults[yrLen - 1].yearId;
        console.log('Какой идет год: ', years, 'Новый год: ', isNewYear)

        const months = Math.floor(dayjs(curDate).diff(startGameDate, 'months')) + 1;
        const { game: { estimatedIndicators: { sumMoneyDplusPerKvartal } } } = store.getState();

        const monthId = yearsResults[yearsResults.length - 1]?.monthId ?? 0
        const dayId = yearsResults[yearsResults.length - 1]?.dayId ?? 0

        const isNewKvartal = months % 3 === 1 && months > monthId && months > 3;

        const HOUSING_INDEX = 0, PARKS_INDEX = 1, OFFICE_INDEX = 2, SOCIAL_INDEX = 3, CITY_INDEX = 4, ROADS_INDEX = 5;
        const days = Math.floor(dayjs(curDate).diff(startGameDate, 'days')) + 1;
        let satisfactionYear = 0;
        dispatch(yearResultSet({
            yearId: years,
            monthId: months,
            dayId: days,
            moneyhousing: dailycostN[HOUSING_INDEX], // 0 Жилье и прилегающее пространство
            moneyparks: dailycostN[PARKS_INDEX], // 1 Озелененное пространство
            moneyoffice: dailycostN[OFFICE_INDEX], // 2 Общественно-деловая инфраструктура
            moneysocial: dailycostN[SOCIAL_INDEX], // 3 Социально-досуговая инфраструктура
            moneycity: dailycostN[CITY_INDEX], // 4 Общегородское пространство
            moneyroads: dailycostN[ROADS_INDEX], // 5 Улично-дорожная сеть
            moneysum: dailycostN.reduce((a, b) => a + b),
            satisfactionhousing: satisfactionXn[HOUSING_INDEX],
            satisfactionparks: satisfactionXn[PARKS_INDEX],
            satisfactionoffice: satisfactionXn[OFFICE_INDEX],
            satisfactionsocial: satisfactionXn[SOCIAL_INDEX],
            satisfactioncity: satisfactionXn[CITY_INDEX],
            satisfactionroads: satisfactionXn[ROADS_INDEX]
        }));

        const mayortermplus = 75;
        const mayortermminus = 50;
        const mayortermgameover = 25;

        if (isNewKvartal) {
            dispatch(kvartalModalShow(sumMoneyDplusPerKvartal));
        }

        const isShowInterviewModal = days % 365 === 9 && days > dayId

        if (isShowInterviewModal) {
            dispatch(InverviewModalShow());
        }

        // Проверка удовлетворенности по итогам года
        if (isNewYear) {

            const yr = yearsResults[yrLen - 1];
            console.log(yr, yearsResults)
            // Изменение показателя удовлетворенности по итогам года
            const satisfactioncoef = 10
            const satisfactioncorrect = 0;
            let satisfactionhousingback = 0,
                satisfactionparksback = 0,
                satisfactionofficeback = 0,
                satisfactionsocialback = 0,
                satisfactioncityback = 0,
                satisfactionroadsback = 0;
            if (yrLen > 1) {
                satisfactionhousingback = yearsResults[yrLen - 2].satisfactionhousing;
                satisfactionparksback = yearsResults[yrLen - 2].satisfactionparks;
                satisfactionofficeback = yearsResults[yrLen - 2].satisfactionoffice;
                satisfactionsocialback = yearsResults[yrLen - 2].satisfactionsocial;
                satisfactioncityback = yearsResults[yrLen - 2].satisfactioncity;
                satisfactionroadsback = yearsResults[yrLen - 2].satisfactionroads;
            }

            const calcCoefs = (moneyShere: number, satisfactionShereBack: number) =>
                (moneyShere / yr.moneysum - satisfactioncorrect) * satisfactioncoef * satisfactionShereBack;
            // TODO: здесь пока не реализована механика с опросами поэтому в расчет не берем показатель удовлетворенности за год
            satisfactionYear = satisfaction
                + calcCoefs(yr.moneyhousing, satisfactionhousingback)
                + calcCoefs(yr.moneyparks, satisfactionparksback)
                + calcCoefs(yr.moneyoffice, satisfactionofficeback)
                + calcCoefs(yr.moneysocial, satisfactionsocialback)
                + calcCoefs(yr.moneycity, satisfactioncityback)
                + calcCoefs(yr.moneyroads, satisfactionroadsback);

            const minusMayorTermCount = gameState.cityInfo?.mayorterm ?? 0;

            // Игрок не может править больше 10 лет(ни при каких условиях).
            if (years > (9 - (10 - minusMayorTermCount))) {
                dispatch(gameFinished());
                dispatch(timerStop());
                dispatch(gameOverModalShow());
                return;
            }
            const { businessscaleContinueClause, businessscaleBonusClause, govscalemin5 } = cityConstInfo;

            // За 5 лет шкала "Индекс инвестиционной привлекательности" должна превышать определенного значения (businessscaleContinueClause), которое будет задано, иначе игра заканчивается.
            if (years > 5 && businessscaleResult < businessscaleContinueClause) {
                dispatch(gameFinished());
                dispatch(timerStop());
                dispatch(gameOverModalShow());
                return;
            }

            // За 5 лет шкала "Одобрение государством" должна превышать определенного значения , которое будет задано, иначе игра заканчивается.
            if (years > 5 && govscaleResult < govscalemin5) {
                dispatch(gameFinished());
                dispatch(timerStop());
                dispatch(gameOverGovScaleModalShow());
                return;
            }

            // Игроку можно повысить срок правления только если ему уже сокращали срок правления. 
            // minusMayorTermCount - счетчик сокращения и добавления срока меру
            if (satisfaction >= mayortermplus) {
                // Если удовлетворенность жителей по итогам года стоставила больше 75 процентов, то срок мэра увеличивается на год, 
                //  если до этого был уменьшен
                if (minusMayorTermCount < 10) {
                    dispatch(changeMayorTerm(1));
                    dispatch(resultModalShow('ResultTermProlongation'));
                }
            }
            else if (satisfaction >= mayortermgameover && satisfaction < mayortermminus) { // Если менее 50 процентов - сокращается на год
                dispatch(changeMayorTerm(-1));
                dispatch(resultModalShow('ResultWarning'));
            }
            else {
                dispatch(resultModalShow('ResultYear'));
            }

            // При достижении определенного значения шкалы уровня удовлетворенности бизнеса раз в год выплачивать грант из федерального центра. 
            // Это будет сопровождаться плашкой и одномоментным изменением количества бюджетных денег.
            if (businessscaleResult >= businessscaleBonusClause) {
                moneyNew += 500000;
                dispatch(grantForBusinessModalShow());
            }

            if (satisfaction < mayortermgameover) { // Если меньше 25 процентов - игра оканчивается по итогам этого года - игра останавляется
                dispatch(gameFinished());
                dispatch(timerStop());
                dispatch(gameOverModalShow());
            }

            // if (moneyNew >= deficitroof2 && moneyNew < deficitroof1) { //   money < deficitroof1 & money ≥ deficitroof2
            //     dispatch(moneyDeficitModalShow('deficit2'));
            // }

            // if (moneyNew >= deficitroof3 && moneyNew < deficitroof2) { // if money < deficitroof2 & money ≥ deficitroof3
            //     dispatch(moneyDeficitModalShow('deficit3'));
            // }
        }
        if (moneyNew < deficitroof3 * 2) {
            dispatch(gameFinished());
            dispatch(timerStop());
            dispatch(moneyDeficitModalShow('gameover'));
        }

        const indicators: IEstimatedIndicators = {
            money: moneyNew,
            sumMoneyDplusPerKvartal: isNewKvartal ? 0 : sumMoneyDplusPerKvartal + moneyDplus,
            moneyDplus: moneyDplus,
            moneyDminus: moneyDminus,
            urbanquality,
            safety: safetyResult,
            comfort: comfortResult,
            environment: environmentResult,
            identity: identityResult,
            modernity: modernityResult,
            control: controlResult,
            businessscale: businessscaleResult,
            govscale: govscaleResult,
            percitizen,
            satisfaction,
            satisfactionYear,
            score,
            svobodniy: svobodniyFresh,
            deficitroof2,
            deficitroof3
        };

        dispatch(setEstimatedIndicators(indicators));

        // console.log(`${curDate} | ${dayjs(curDate).format('DD-MM-YYYYY HH:mm:ss')}`);
        // console.log('--- Индикаторы', indicators);
        // console.log(`--- Деньги на T-1 ${currentMoney} | Годовой бюджет ${moneyyear} | Текущая процентная ставка за дефицит ${deficitrate}`);
        // console.log(`--- moneyDminus ${moneyDminus} | moneyDplus ${moneyDplus} `);

    }, [curDate, dispatch]);
}

function calcIdentiryLocation(selectedObjects: ICityObjectProgress[], curDate: number) {
    let buildObjects: { placeId: number, objectId: string }[] =
        selectedObjects
            .filter(so => isBuildPred(so, curDate))
            .map(so => ({ placeId: so.placeId!, objectId: so.objectId }));

    let locationscoreSum = 0;
    //locationscore XIV - G

    /**
     * Проверки для 1ой локации
     */

    /**
     * 1.1	В К1.1 построено H3/G1/O2/S1 & в K4.1 и K4.2 построен хотя бы один Hn -	3
     */
    const bo11 = buildObjects.filter(bo => 'H3/G1/O2/S1'.indexOf(bo.objectId) >= 0 && placesLocation1.includes(bo.placeId)).length > 0
        && buildObjects.filter(bo => bo.objectId.startsWith('H') && placesLocation4.includes(bo.placeId)).length > 0;

    if (bo11) {
        locationscoreSum += 3;
    }
    else {
        // 1.2	В К1.1 построено G1 & в K4.1 и K4.2 построен хотя бы один Gn - 3

        const bo12 = buildObjects.filter(bo => bo.objectId === 'G1' && placesLocation1.includes(bo.placeId)).length > 0
            && buildObjects.filter(bo => bo.objectId.startsWith('G') && placesLocation4.includes(bo.placeId)).length > 0;

        if (bo12) {
            locationscoreSum += 3;
        }
        else {
            // 1.3	В К1.1 построено O2 & в K4.1 и K4.2 построен хотя бы один On	3              
            const bo13 = buildObjects.filter(bo => bo.objectId === 'O2' && placesLocation1.includes(bo.placeId)).length > 0
                && buildObjects.filter(bo => bo.objectId.startsWith('O') && placesLocation4.includes(bo.placeId)).length > 0;

            if (bo13) {
                locationscoreSum += 3;
            }
            else {
                // 1.4	В К1.1 построено S1 & в K4.1 и K4.2 построен хотя бы один Sn	3
                const bo14 = buildObjects.filter(bo => bo.objectId === 'S1' && placesLocation1.includes(bo.placeId)).length > 0
                    && buildObjects.filter(bo => bo.objectId.startsWith('S') && placesLocation4.includes(bo.placeId)).length > 0;

                if (bo14) {
                    locationscoreSum += 3;
                }
                else {
                    // 1.5	В К1.1 построено H3/G1/O2/S1 & в K4.1 и K4.2 не построено ни одного Hn	2
                    const bo15 = buildObjects.filter(bo => 'H3/G1/O2/S1'.indexOf(bo.objectId) >= 0 && placesLocation1.includes(bo.placeId)).length > 0
                        && buildObjects.filter(bo => bo.objectId.startsWith('H') && placesLocation4.includes(bo.placeId)).length === 0;

                    if (bo15) {
                        locationscoreSum += 2;
                    }
                    else {
                        // 1.6	В К1.1 построено G1 & в K4.1 и K4.2 не построено ни одного Gn	2
                        const bo16 = buildObjects.filter(bo => bo.objectId === 'G1' && placesLocation1.includes(bo.placeId)).length > 0
                            && buildObjects.filter(bo => bo.objectId.startsWith('G') && placesLocation4.includes(bo.placeId)).length === 0;

                        if (bo16) {
                            locationscoreSum += 2;
                        }
                        else {
                            // 1.7	В К1.1 построено O2 & в K4.1 и K4.2 не построено ни одного On	2
                            const bo17 = buildObjects.filter(bo => bo.objectId === 'O2' && placesLocation1.includes(bo.placeId)).length > 0
                                && buildObjects.filter(bo => bo.objectId.startsWith('O') && placesLocation4.includes(bo.placeId)).length === 0;

                            if (bo17) {
                                locationscoreSum += 2;
                            }
                            else {
                                // 1.8	В К1.1 построено S1 & в K4.1 и K4.2 не построено ни одного Sn	2
                                const bo18 = buildObjects.filter(bo => bo.objectId === 'S1' && placesLocation1.includes(bo.placeId)).length > 0
                                    && buildObjects.filter(bo => bo.objectId.startsWith('S') && placesLocation4.includes(bo.placeId)).length === 0;

                                if (bo18) {
                                    locationscoreSum += 2;
                                }
                                else {
                                    // 1.9	В К1.1 построено что-либо кроме H3/G1/O2/S1	1
                                    const bo19 = buildObjects.filter(bo => 'H3/G1/O2/S1'.indexOf(bo.objectId) >= 0 && placesLocation1.includes(bo.placeId)).length === 0
                                        && buildObjects.filter(bo => placesLocation1.includes(bo.placeId)).length > 0;

                                    if (bo19) {
                                        locationscoreSum += 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // console.log('1. locationscoreSum: ', locationscoreSum);

    /**
     * Проверки для 2ой локации
     */

    // 2.1	В каждом из 3 квадрантов построен объект Hn	3
    // 2.2	В каждом из 3 квадрантов построен объект Gn	3
    // 2.3	В каждом из 3 квадрантов построен объект On	3
    // 2.4	В каждом из 3 квадрантов построен объект Sn	3
    const bo21 = buildObjects.filter(bo => bo.objectId.startsWith('H') && placesLocation2.includes(bo.placeId)).length === 3
        || buildObjects.filter(bo => bo.objectId.startsWith('G') && placesLocation2.includes(bo.placeId)).length === 3
        || buildObjects.filter(bo => bo.objectId.startsWith('O') && placesLocation2.includes(bo.placeId)).length === 3
        || buildObjects.filter(bo => bo.objectId.startsWith('S') && placesLocation2.includes(bo.placeId)).length === 3;

    if (bo21) {
        locationscoreSum += 3;
    }
    else {
        // 2.5	В 2 из 3 квадрантов построен объект Hn	1
        // 2.6	В 2 из 3 квадрантов построен объект Gn	1
        // 2.7	В 2 из 3 квадрантов построен объект On	1
        // 2.8	В 2 из 3 квадрантов построен объект Sn	1
        const bo25 = buildObjects.filter(bo => bo.objectId.startsWith('H') && placesLocation2.includes(bo.placeId)).length === 2
            || buildObjects.filter(bo => bo.objectId.startsWith('G') && placesLocation2.includes(bo.placeId)).length === 2
            || buildObjects.filter(bo => bo.objectId.startsWith('O') && placesLocation2.includes(bo.placeId)).length === 2
            || buildObjects.filter(bo => bo.objectId.startsWith('S') && placesLocation2.includes(bo.placeId)).length === 2;
        if (bo25) {
            locationscoreSum += 1;
        }
        else {
            // 2.9	В каждом квадранте построены разные объекты	0
        }
    }

    // console.log('2. locationscoreSum: ', locationscoreSum);

    /**
     * Проверки для 3ой локации
     */

    // 3.1	В каждом из 3 квадрантов построен объект Hn	3
    // 3.2	В каждом из 3 квадрантов построен объект Gn	3
    // 3.3	В каждом из 3 квадрантов построен объект On	3
    // 3.4	В каждом из 3 квадрантов построен объект Sn	3
    const bo31 = buildObjects.filter(bo => bo.objectId.startsWith('H') && placesLocation3.includes(bo.placeId)).length === 3
        || buildObjects.filter(bo => bo.objectId.startsWith('G') && placesLocation3.includes(bo.placeId)).length === 3
        || buildObjects.filter(bo => bo.objectId.startsWith('O') && placesLocation3.includes(bo.placeId)).length === 3
        || buildObjects.filter(bo => bo.objectId.startsWith('S') && placesLocation3.includes(bo.placeId)).length === 3;
    if (bo31) {
        locationscoreSum += 3;
    }
    else {
        // 3.5	В 2 из 3 квадрантов построен объект Hn	1
        // 3.6	В 2 из 3 квадрантов построен объект Gn	1
        // 3.7	В 2 из 3 квадрантов построен объект On	1
        // 3.8	В 2 из 3 квадрантов построен объект Sn	1
        const bo35 = buildObjects.filter(bo => bo.objectId.startsWith('H') && placesLocation3.includes(bo.placeId)).length === 2
            || buildObjects.filter(bo => bo.objectId.startsWith('G') && placesLocation3.includes(bo.placeId)).length === 2
            || buildObjects.filter(bo => bo.objectId.startsWith('O') && placesLocation3.includes(bo.placeId)).length === 2
            || buildObjects.filter(bo => bo.objectId.startsWith('S') && placesLocation3.includes(bo.placeId)).length === 2;

        if (bo35) {
            locationscoreSum += 1;
        }
        else {
            // 3.9	В каждом квадранте построены разные типы объектов	0
        }
    }

    // console.log('3. locationscoreSum: ', locationscoreSum);

    /**
     * Проверки для 4ой локации
     */

    // 4.1	В K1.1 построено H3 & в K4.1 и K4.2 построен объект Hn	3
    const bo41 = buildObjects.filter(bo => bo.objectId === 'H3' && placesLocation1.includes(bo.placeId)).length > 0
        && buildObjects.filter(bo => bo.objectId.startsWith('H') && placesLocation4.includes(bo.placeId)).length > 0;
    if (bo41) {
        locationscoreSum += 3;
    }
    else {
        // 4.2	В K1.1 построено G1 & в K4.1 и K4.2 построен объект Gn	3
        const bo42 = buildObjects.filter(bo => bo.objectId === 'G1' && placesLocation1.includes(bo.placeId)).length > 0
            && buildObjects.filter(bo => bo.objectId.startsWith('G') && placesLocation4.includes(bo.placeId)).length > 0;
        if (bo42) {
            locationscoreSum += 3;
        }
        else {
            // 4.3	В K1.1 построено O2 & в K4.1 и K4.2 построен объект On	3
            const bo43 = buildObjects.filter(bo => bo.objectId === 'O2' && placesLocation1.includes(bo.placeId)).length > 0
                && buildObjects.filter(bo => bo.objectId.startsWith('O') && placesLocation4.includes(bo.placeId)).length > 0;
            if (bo43) {
                locationscoreSum += 3;
            }
            else {
                // 4.4	В K1.1 построено S1 & в K4.1 и K4.2 построен объект Sn	3
                const bo44 = buildObjects.filter(bo => bo.objectId === 'S1' && placesLocation1.includes(bo.placeId)).length > 0
                    && buildObjects.filter(bo => bo.objectId.startsWith('S') && placesLocation4.includes(bo.placeId)).length > 0;
                if (bo44) {
                    locationscoreSum += 3;
                }
                else {
                    // 4.5	В K1.1 построен Hn & в K4.1 и K4.2 построен только один Hn	1
                    const bo45 = buildObjects.filter(bo => bo.objectId.startsWith('H') && placesLocation1.includes(bo.placeId)).length > 0 &&
                        buildObjects.filter(bo => bo.objectId.startsWith('H') && placesLocation4.includes(bo.placeId)).length === 1;
                    // 4.6	В K1.1 построен Gn & в K4.1 и K4.2 построен только один Gn	1
                    const bo46 = buildObjects.filter(bo => bo.objectId.startsWith('G') && placesLocation1.includes(bo.placeId)).length > 0 &&
                        buildObjects.filter(bo => bo.objectId.startsWith('G') && placesLocation4.includes(bo.placeId)).length === 1;
                    // 4.7	В K1.1 построен On & в K4.1 и K4.2 построен только один On	1
                    const bo47 = buildObjects.filter(bo => bo.objectId.startsWith('O') && placesLocation1.includes(bo.placeId)).length > 0 &&
                        buildObjects.filter(bo => bo.objectId.startsWith('O') && placesLocation4.includes(bo.placeId)).length === 1;
                    // 4.8	В K1.1 построен Sn & в K4.1 и K4.2 построен только один Sn	1
                    const bo48 = buildObjects.filter(bo => bo.objectId.startsWith('S') && placesLocation1.includes(bo.placeId)).length > 0 &&
                        buildObjects.filter(bo => bo.objectId.startsWith('S') && placesLocation4.includes(bo.placeId)).length === 1;
                    if (bo45 || bo46 || bo47 || bo48) {
                        locationscoreSum += 1;
                    }
                    else {
                        // 4.9	В K1.1, K4.1 и K4.2 построены разные типы объектов	0
                    }
                }
            }
        }
    }

    // console.log('4. locationscoreSum: ', locationscoreSum);

    /**
     * Проверки для 5ой локации
     */

    // 5.1	В каждом из 3 квадрантов построен объект Hn	3
    // 5.2	В каждом из 3 квадрантов построен объект Gn	3
    // 5.3	В каждом из 3 квадрантов построен объект On	3
    // 5.4	В каждом из 3 квадрантов построен объект Sn	3        
    const bo51 = buildObjects.filter(bo => bo.objectId.startsWith('H') && placesLocation5.includes(bo.placeId)).length === 3
        || buildObjects.filter(bo => bo.objectId.startsWith('G') && placesLocation5.includes(bo.placeId)).length === 3
        || buildObjects.filter(bo => bo.objectId.startsWith('O') && placesLocation5.includes(bo.placeId)).length === 3
        || buildObjects.filter(bo => bo.objectId.startsWith('S') && placesLocation5.includes(bo.placeId)).length === 3;
    if (bo51) {
        locationscoreSum += 3;
    }
    else {
        // 5.5	В 2 из 3 квадрантов построен объект Hn	1
        // 5.6	В 2 из 3 квадрантов построен объект Gn	1
        // 5.7	В 2 из 3 квадрантов построен объект On	1
        // 5.8	В 2 из 3 квадрантов построен объект Sn	1           
        const bo55 = buildObjects.filter(bo => bo.objectId.startsWith('H') && placesLocation5.includes(bo.placeId)).length === 2
            || buildObjects.filter(bo => bo.objectId.startsWith('G') && placesLocation5.includes(bo.placeId)).length === 2
            || buildObjects.filter(bo => bo.objectId.startsWith('O') && placesLocation5.includes(bo.placeId)).length === 2
            || buildObjects.filter(bo => bo.objectId.startsWith('S') && placesLocation5.includes(bo.placeId)).length === 2;

        if (bo55) {
            locationscoreSum += 1;
        }
        else {
            // 5.9	В каждом квадранте построены разные объекты	0
        }
    }

    // console.log('5. locationscoreSum: ', locationscoreSum);

    const location = locationscoreSum * locationscorecoef;
    const identitylocation = location * locationcoef;

    // console.log('location: ', location, 'identitylocation: ', identitylocation);
    return identitylocation;
}

const places = initialData.cityBuildingPointsInfo.map(p => { return { placeId: p.id, locationId: p.locationId } });
// placesLocationX - места для строки локации X, x = [1, 5];
const placesLocation1 = places.filter(_ => _.locationId === 1).map(_ => _.placeId);
const placesLocation2 = places.filter(_ => _.locationId === 2).map(_ => _.placeId);
const placesLocation3 = places.filter(_ => _.locationId === 3).map(_ => _.placeId);
const placesLocation4 = places.filter(_ => _.locationId === 4).map(_ => _.placeId);
const placesLocation5 = places.filter(_ => _.locationId === 5).map(_ => _.placeId);

export const isBuildPred = (so: ICityObjectProgress, curDate: number): boolean => {
    const sphere = initialData.citySpheresInfo.find(_ => _.sphereId === so.sphereId);

    const cityObject = sphere?.objects.find(_ => _.id === so.objectId);

    return !!cityObject
        && dayjs(curDate).diff(so.startBuildTime, 'days') >= cityObject.params.timecostXnYn
        && !so.desctuctCoef;
}
const isInLocation5 = (e: ICityObjectProgress): boolean => placesLocation5.includes(e.placeId!)
const isInLocation2 = (e: ICityObjectProgress): boolean => placesLocation2.includes(e.placeId!)
export const calcBuildingInfluence = (so: ICityObjectProgress, curDate: number, isForBuildingInfoModal?: boolean): boolean => {
    const { game: gameState } = store.getState();
    const { selectedObjects } = gameState;
    const buildObjectsWithSameShpere = isForBuildingInfoModal ?
        selectedObjects
            .filter(e => e.sphereId === so.sphereId)
        : selectedObjects
            .filter(e => e.sphereId === so.sphereId)
            .filter(e => isBuildPred(e, curDate));

    // для 5 локации - Если в 2 или 3 квадрантах построены здания из одинаковой сферы, 
    // то их позитивное влияние обнуляется:
    if (isInLocation5(so) && buildObjectsWithSameShpere.filter(isInLocation5).length > (isForBuildingInfoModal ? 0 : 1))
        return true;

    // для 2 локации - Если в 2 квадрантах в левой части локации построены здания из одинаковой сферы,
    const loc2BuildOb = buildObjectsWithSameShpere.filter(isInLocation2);
    if (so.placeId === 9 || so.placeId === 10)
        return loc2BuildOb.filter(e => e.placeId === 9 || e.placeId === 10).length > (isForBuildingInfoModal ? 0 : 1);

    // для 3 локации - Если в 2 квадрантах рядом построены здания из одинаковой сферы, 
    if (so.placeId === 6 || so.placeId === 8) //6 и 8 это те что по краям
        return buildObjectsWithSameShpere.filter(e => e.placeId === 7).length > 0;
    else if (so.placeId === 7) // 7 в центре

        return buildObjectsWithSameShpere.filter(e => e.placeId === 6 || e.placeId === 8).length > 0;

    return false;
}
