import { useDispatch, useSelector } from 'react-redux';
import { CloseBlueIcon, GeomarkIcon } from '../../icons';
import { getPhotoObject } from '../../images/buildingObjects';
import { getPhotoManager } from '../../images/managers';
import { getSphereById } from '../../initialData';
import { showMenuAction } from '../../redux/actions/menu';
import { getHiredManagers, getSelectedObject } from '../../redux/selectors';
import { fireManager, modalHide, objectDeconstrunctModalShow, objectDoneUpgradeLevelsModalShow } from '../../redux/actions';
import BuildingPhoto from '../BuildingPhoto';
import { MenuLevelEmum } from '../GameMenu';
import ModalComponent from '../ModalComponent';
import { Button, Rating } from '../UiKit';
import { IState } from "../../redux/reducers";
import NameValueComponent from './NameValueComponent';
import style from './style.module.scss';
import { getUpgradeLevel } from '../../helpers';
import { useCalcObjectParams } from '../../hooks';

interface IBuildingInfoProps {
    sphereId: number;
    placeId: number;
    objectId: string;
    previousModalId: number;
}

const BuildingInfo: React.FunctionComponent<IBuildingInfoProps> = ({ sphereId, objectId, placeId, previousModalId }) => {

    const dispatch = useDispatch();

    const sphere = getSphereById(sphereId);
    const objectInfo = sphere.objects.find(_ => _.id === objectId);
    const buildingParamsWithUpgrades = useCalcObjectParams(placeId, objectInfo!.params)
    const objectSelected = useSelector(s => getSelectedObject(s as IState, placeId, objectId));
    const buildingUpgradeLevel = getUpgradeLevel(objectSelected);

    const photo = getPhotoObject(objectId);

    const onUpgrade = (e: any) => {
        dispatch(objectDoneUpgradeLevelsModalShow(objectId, placeId, sphereId));
        dispatch(modalHide(previousModalId));
        e.stopPropagation();
    }
    const onDestruct = (e: any) => {
        dispatch(objectDeconstrunctModalShow(objectId, placeId, sphereId));
        dispatch(modalHide(previousModalId));
        e.stopPropagation();
    }

    const hiredManagers = useSelector(getHiredManagers);
    const managers = hiredManagers.filter(m => m.objectIds.find(e => e.objectId === objectId && e.placeId === placeId));

    return !objectInfo ? <></> :
        <ModalComponent showBackIcon={false} closeAction={() => {
            dispatch(modalHide(previousModalId));
        }} height={'50rem'}>
            <div className={style.buildingPlace}>
                <div className={style.header}>
                    <div className={style.icon}><GeomarkIcon /></div>
                    <div className={style.buildingDetails}>
                        <div className={style.name}>{objectInfo?.name}</div>
                        <div className={style.addName}>{sphere.sphereName}</div>
                    </div>
                </div>
                <div className={style.row}>
                    <div className={style.buildinInfo}>
                        <label className={style.info}>{objectInfo?.descAdd}</label>
                        <div className={style.buttons}>
                            <Button isBlue={true} isStretched={true} onClick={onUpgrade}>Улучшить</Button>
                            {(objectId !== 'R' && objectId !== 'C') && <Button isBlue={false} isStretched={true} onClick={onDestruct}>Снести</Button>}
                        </div>
                    </div>
                    <div className={style.photo}>
                        <div className={(objectId === 'R' || objectId === 'C') ? style.rating2 : style.rating}>
                            <Rating value={buildingUpgradeLevel} />
                        </div>
                        <BuildingPhoto photo={photo.img} isBordered={false} />
                    </div>
                </div>
                <div className={style.buildingInfluenceLabel}>Влияние на качество городской среды:</div>
                <div className={style.details}>
                    <div className={style.row}>
                        <div className={style.incomeInfo}>
                            <NameValueComponent name={"Выручка в месяц"} value={objectInfo.params.dailyrevenueXnYnbase * 30} isValCapitalized={true} />
                            <NameValueComponent name={"Безопасность"} value={buildingParamsWithUpgrades.safetyXnYn} />
                            <NameValueComponent name={"Экологичность и здоровье"} value={buildingParamsWithUpgrades.environmentXnYn} />
                            <NameValueComponent name={"Современность и актуальность"} value={buildingParamsWithUpgrades.modernityXnYn} />
                        </div>
                        <div className={style.outcomeInfo}>
                            <NameValueComponent name={"Издержки в месяц:"} value={objectInfo.params.dailycostXnYnbase * 30} isValCapitalized={true} />
                            <NameValueComponent name={"Комфортность"} value={buildingParamsWithUpgrades.comfortXnYn} />
                            <NameValueComponent name={"Идентичность и разнообразие"} value={buildingParamsWithUpgrades.identityXnYn} />
                            <NameValueComponent name={"Эффективность управления"} value={buildingParamsWithUpgrades.controlXnYn} />
                        </div>
                    </div>
                </div>
                <div className={style.manager}>
                    {managers && managers.map((manager, i) => <div key={i} className={style.managerInfo}>
                        <img src={getPhotoManager(manager.managerId)} alt="" />
                        <div className={style.info}>
                            <div className={style.name}>{manager.manager.title}</div>
                            <div className={style.position}>{manager.manager.desc}</div>
                        </div>
                        <div className={style.fireBtn}>
                            <Button widthPercents={99} isBlue={false} isStretched={false}
                                onClick={() => dispatch(fireManager({ objectId, placeId }, manager.managerId))}

                            > <div className={style.fireBtnContent}><CloseBlueIcon /> Уволить</div> </Button>
                        </div>
                    </div>)}
                    <div className={style.managerBtn}>
                        <Button widthPercents={120} isBlue={false} isStretched={true} onClick={() => dispatch(showMenuAction(MenuLevelEmum.Managers, sphereId, objectId))}>Руководители</Button>
                    </div>
                </div>
            </div>
        </ModalComponent>
}

export default BuildingInfo;

