import { useSelector } from 'react-redux';
import { MenuLevelEmum } from '..';
import { getLearningCompleted, getlearningNotifications, getNotifications } from '../../../redux/selectors';
import NotificationCardNews from '../../NotificationCardNews';
import CustomScrollbar from '../../UiKit/Scrollbar';
import BackMenuHeader from '../MenuHeader/backHeader';
import style from './style.module.scss';

interface INotificationMenuProps {
    menuLevelSet: (level: MenuLevelEmum) => void;
}

const NotificationMenu: React.FunctionComponent<INotificationMenuProps> = (props) => {
    const notifications = useSelector(getNotifications);

    const { menuLevelSet } = props;
    const isLearningCompleted = useSelector(getLearningCompleted);
    const learnNotif = useSelector(getlearningNotifications)

    return (<div className={style.notificationMenu}>
        <BackMenuHeader title={'Уведомления'} onClick={() => menuLevelSet(MenuLevelEmum.Level0)} />

        <CustomScrollbar styleCustom={{ height: '93%', width: '356px' }}>
            <div className={style.notifications}>
                {!isLearningCompleted ? learnNotif.map((n, i) => <NotificationCardNews key={i} news={n} />) :
                    notifications.map((n, i) => <NotificationCardNews key={i} news={n} />)}
            </div>
        </CustomScrollbar>
    </div>);
}
export default NotificationMenu;
