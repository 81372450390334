import { Action } from 'redux';
import { INotification } from '../../../models';

export const GAME_NEW_NOTIFICATION = 'GAME_NEW_NOTIFICATION';
export const GAME_READ_NOTIFICATION = 'GAME_READ_NOTIFICATION';
export const GAME_CLOSE_PUSH_NOTIFICATION = 'GAME_CLOSE_PUSH_NOTIFICATION';

export interface INewNotificationAction extends Action {
    notification: INotification;
}

export interface INotificationAction extends Action {
    id: number;
}

export function newNotification(notification: INotification) {
    return { type: GAME_NEW_NOTIFICATION, notification } as INewNotificationAction;
}

export function readNotification(id: number) {
    return { type: GAME_READ_NOTIFICATION, id } as INotificationAction;
}

export function closePushNotification(id: number) {
    return { type: GAME_CLOSE_PUSH_NOTIFICATION, id } as INotificationAction;
}
