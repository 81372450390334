import { Action } from 'redux';
import { GameStage, IGame } from '../../../models';


export const GAME_INIT = 'GAME_INIT';
export const GAME_FINISHED = 'GAME_FINISHED';
export const GAME_STAGE = 'GAME_STAGE';

export interface IGameInitAction extends Action {
    game: IGame;
}

export interface IGameStageAction extends Action {
    stage: GameStage;
}

export function gameInit(game: IGame) {
    return { type: GAME_INIT, game };
}

export function gameStage(stage: GameStage) {
    return { type: GAME_STAGE, stage };
}

export function gameFinished() {
    return { type: GAME_FINISHED };
}

